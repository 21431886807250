import React, { useState } from "react";

import supabase from "../../../Supabase";
import HeaderGeral from "../../../Components/headerGeral";

import {
  Container,
  DivInputs,
  FormContainer,
  FormHeader,
  FormInput,
  FormSelect,
} from "../../../Components/styles-all";

import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { SuccessToast, ErrorToast } from "../../../Components/toastyfy";

import ButtonReturn from "../../../Components/button-return";
import ButtonSend from "../../../Components/button-send";
import FormGroup from "../../../Components/form-group";
import FormLabel from "../../../Components/form-label";

const Form18 = () => {
  const [sexo, setSexo] = useState("");
  const [idade, setIdade] = useState("");
  const [peso, setPeso] = useState("");
  const [dobras, setDobras] = useState([]);
  const [densidadeCorporal, setDensidadeCorporal] = useState(null);
  const [percentualGordura, setPercentualGordura] = useState(null);
  const [gorduraAbsoluta, setGorduraAbsoluta] = useState(null);
  const [massaMagra, setMassaMagra] = useState(null);
  const [pesoIdeal, setPesoIdeal] = useState(null);
  const [pesoExcesso, setPesoExcesso] = useState(null);
  const [numDobras, setNumDobras] = useState(null);

  const [id, setId] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleCPFChange = (e) => {
    const cpf = formatCPF(e.target.value);
    setId(cpf);

    const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    setIsValid(regex.test(cpf));
  };

  const formatCPF = (cpf) => {
    let value = cpf.replace(/\D/g, ""); // Mantém apenas números
    if (value.length > 3) value = `${value.slice(0, 3)}.${value.slice(3)}`;
    if (value.length > 7) value = `${value.slice(0, 7)}.${value.slice(7)}`;
    if (value.length > 11)
      value = `${value.slice(0, 11)}-${value.slice(11, 13)}`;
    return value;
  };

  const classificarGordura = (sexo, idade, percentual) => {
    if (sexo === "feminino") {
      if (idade >= 20 && idade <= 29) {
        if (percentual < 16) return "Excelente";
        else if (percentual <= 19) return "Bom";
        else if (percentual <= 28) return "Normal";
        else if (percentual <= 31) return "Elevado";
        else return "Muito elevado";
      } else if (idade >= 30 && idade <= 39) {
        if (percentual < 17) return "Excelente";
        else if (percentual <= 20) return "Bom";
        else if (percentual <= 29) return "Normal";
        else if (percentual <= 32) return "Elevado";
        else return "Muito elevado";
      } else if (idade >= 40 && idade <= 49) {
        if (percentual < 18) return "Excelente";
        else if (percentual <= 21) return "Bom";
        else if (percentual <= 30) return "Normal";
        else if (percentual <= 33) return "Elevado";
        else return "Muito elevado";
      } else if (idade >= 50 && idade <= 59) {
        if (percentual < 19) return "Excelente";
        else if (percentual <= 22) return "Bom";
        else if (percentual <= 31) return "Normal";
        else if (percentual <= 34) return "Elevado";
        else return "Muito elevado";
      }
    } else if (sexo === "masculino") {
      if (idade >= 20 && idade <= 29) {
        if (percentual < 11) return "Excelente";
        else if (percentual <= 13) return "Bom";
        else if (percentual <= 20) return "Normal";
        else if (percentual <= 23) return "Elevado";
        else return "Muito elevado";
      } else if (idade >= 30 && idade <= 39) {
        if (percentual < 12) return "Excelente";
        else if (percentual <= 14) return "Bom";
        else if (percentual <= 21) return "Normal";
        else if (percentual <= 24) return "Elevado";
        else return "Muito elevado";
      } else if (idade >= 40 && idade <= 49) {
        if (percentual < 14) return "Excelente";
        else if (percentual <= 16) return "Bom";
        else if (percentual <= 23) return "Normal";
        else if (percentual <= 26) return "Elevado";
        else return "Muito elevado";
      } else if (idade >= 50 && idade <= 59) {
        if (percentual < 15) return "Excelente";
        else if (percentual <= 17) return "Bom";
        else if (percentual <= 24) return "Normal";
        else if (percentual <= 27) return "Elevado";
        else return "Muito elevado";
      }
    }
    return "Fora do escopo";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDobras((prevState) => ({ ...prevState, [name]: parseFloat(value) }));
  };

  const calcularComposicao = () => {
    let densidade = 0;
    const ST = Object.values(dobras).reduce((a, b) => a + b, 0);

    const contagemDobras = Object.keys(dobras).length;
    if (contagemDobras !== 3 && contagemDobras !== 7) {
      alert("Por favor, forneça 3 ou 7 dobras para cálculo.");
      return;
    }

    if (contagemDobras === 3) {
      if (
        sexo === "masculino" &&
        dobras.triceps &&
        dobras.supraIliaca &&
        dobras.abdomen
      ) {
        const somaDobras = dobras.triceps + dobras.supraIliaca + dobras.abdomen;
        densidade = 1.17136 - 0.06706 * Math.log10(somaDobras);
      } else if (
        sexo === "feminino" &&
        dobras.coxa &&
        dobras.supraIliaca &&
        dobras.subescapular
      ) {
        const somaDobras =
          dobras.coxa + dobras.supraIliaca + dobras.subescapular;
        densidade = 1.1665 - 0.07063 * Math.log10(somaDobras);
      } else {
        alert(
          "Por favor, forneça as 3 dobras corretas de acordo com o sexo selecionado."
        );
        return;
      }
    } else if (contagemDobras === 7) {
      if (sexo === "masculino") {
        densidade =
          1.112 -
          (0.00043499 * ST + 0.00000055 * ST * ST) -
          0.0002882 * parseFloat(idade);
      } else {
        densidade =
          1.097 -
          (0.00046971 * ST + 0.00000056 * ST * ST) -
          0.00012828 * parseFloat(idade);
      }
    }

    if (densidade <= 0) {
      alert("Valor inválido para Densidade Corporal.");
      return;
    }

    setDensidadeCorporal(densidade);

    const gorduraPercentual = (4.95 / densidade - 4.5) * 100;
    setPercentualGordura(gorduraPercentual);

    const gorduraKg = (parseFloat(peso) * gorduraPercentual) / 100;
    setGorduraAbsoluta(gorduraKg);

    const magraKg = parseFloat(peso) - gorduraKg;
    setMassaMagra(magraKg);

    const ideal = sexo === "masculino" ? magraKg / 0.85 : magraKg / 0.75;
    setPesoIdeal(ideal);

    const excesso = parseFloat(peso) - ideal;
    setPesoExcesso(excesso);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      id,
      classificacao: classificarGordura("feminino", 25, percentualGordura),
    };

    const { error } = await supabase.from("testePercGordu").insert([data]);
    if (error) {
      toast.error(
        <ErrorToast>
          <strong>Erro!</strong> Erro ao inserir os dados.
        </ErrorToast>
      );
    } else {
      toast.success(
        <SuccessToast>
          <strong>Sucesso!</strong> Dados inseridos com êxito.
        </SuccessToast>
      );
    }
  };

  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate("/plataforma/Avaliação-antropométrica");
  };

  return (
    <>
      <HeaderGeral />
      <Container>
        <FormContainer>
          <FormHeader>
            <h2>TESTE DE PERCENTUAL DE GORDURA</h2>
          </FormHeader>

          <form onSubmit={handleSubmit}>
            <FormGroup>
              <FormLabel>Nº de CPF :</FormLabel>
              <FormInput
                type="text"
                value={id}
                maxLength="14"
                onChange={handleCPFChange}
                style={isValid ? {} : { borderColor: "red" }}
                customWidth="15vw"
              />
              {!isValid && <p style={{ color: "red" }}>CPF inválido!</p>}
            </FormGroup>

            <DivInputs>
              <FormSelect
                onChange={(e) => setSexo(e.target.value)}
                customWidth="15vw"
              >
                <option value="">Selecione o Sexo</option>
                <option value="masculino">Masculino</option>
                <option value="feminino">Feminino</option>
              </FormSelect>

              <FormInput
                placeholder="Idade"
                type="number"
                onChange={(e) => setIdade(e.target.value)}
                customWidth="15vw"
              />

              <FormInput
                placeholder="Peso (kg)"
                type="number"
                onChange={(e) => setPeso(e.target.value)}
                customWidth="15vw"
              />

              <FormSelect
                onChange={(e) => setNumDobras(e.target.value)}
                customWidth="15vw"
              >
                <option value="">Selecione o número de dobras</option>
                <option value="3">3 Dobras</option>
                <option value="7">7 Dobras</option>
              </FormSelect>
            </DivInputs>
            {sexo === "masculino" && numDobras === "3" && (
              <DivInputs>
                <FormInput
                  name="triceps"
                  placeholder="Dobra do Tríceps (mm)"
                  type="number"
                  onChange={handleChange}
                  customWidth="15vw"
                />
                <FormInput
                  name="abdomen"
                  placeholder="Dobra do Abdômen (mm)"
                  type="number"
                  onChange={handleChange}
                  customWidth="15vw"
                />
                <FormInput
                  name="supraIliaca"
                  placeholder="Dobra Supra-ilíaca (mm)"
                  type="number"
                  onChange={handleChange}
                  customWidth="15vw"
                />
              </DivInputs>
            )}

            {sexo === "masculino" && numDobras === "7" && (
              <DivInputs>
                <FormInput
                  name="subescapular"
                  placeholder="Dobra Subescapular (mm)"
                  type="number"
                  onChange={handleChange}
                />
                <FormInput
                  name="axilarMedia"
                  placeholder="Dobra Axilar Média (mm)"
                  type="number"
                  onChange={handleChange}
                />
                <FormInput
                  name="triceps"
                  placeholder="Dobra do Tríceps (mm)"
                  type="number"
                  onChange={handleChange}
                />
                <FormInput
                  name="coxa"
                  placeholder="Dobra da Coxa (mm)"
                  type="number"
                  onChange={handleChange}
                />
                <FormInput
                  name="supraIliaca"
                  placeholder="Dobra Supra-ilíaca (mm)"
                  type="number"
                  onChange={handleChange}
                />
                <FormInput
                  name="abdomen"
                  placeholder="Dobra do Abdômen (mm)"
                  type="number"
                  onChange={handleChange}
                />
                <FormInput
                  name="peitoral"
                  placeholder="Dobra Peitoral (mm)"
                  type="number"
                  onChange={handleChange}
                />
              </DivInputs>
            )}

            {sexo === "feminino" && numDobras === "3" && (
              <DivInputs>
                <FormInput
                  name="subescapular"
                  placeholder="Dobra Subescapular (mm)"
                  type="number"
                  onChange={handleChange}
                  customWidth="15vw"
                />
                <FormInput
                  name="supraIliaca"
                  placeholder="Dobra Supra-ilíaca (mm)"
                  type="number"
                  onChange={handleChange}
                  customWidth="15vw"
                />
                <FormInput
                  name="coxa"
                  placeholder="Dobra da Coxa (mm)"
                  type="number"
                  onChange={handleChange}
                  customWidth="15vw"
                />
              </DivInputs>
            )}

            {sexo === "feminino" && numDobras === "7" && (
              <DivInputs>
                <FormInput
                  name="subescapular"
                  placeholder="Dobra Subescapular (mm)"
                  type="number"
                  onChange={handleChange}
                />
                <FormInput
                  name="axilarMedia"
                  placeholder="Dobra Axilar Média (mm)"
                  type="number"
                  onChange={handleChange}
                />
                <FormInput
                  name="triceps"
                  placeholder="Dobra do Tríceps (mm)"
                  type="number"
                  onChange={handleChange}
                />
                <FormInput
                  name="coxa"
                  placeholder="Dobra da Coxa (mm)"
                  type="number"
                  onChange={handleChange}
                />
                <FormInput
                  name="supraIliaca"
                  placeholder="Dobra Supra-ilíaca (mm)"
                  type="number"
                  onChange={handleChange}
                />
                <FormInput
                  name="abdomen"
                  placeholder="Dobra do Abdômen (mm)"
                  type="number"
                  onChange={handleChange}
                />
                <FormInput
                  name="peitoral"
                  placeholder="Dobra Peitoral (mm)"
                  type="number"
                  onChange={handleChange}
                />
              </DivInputs>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 10,
                marginRight: 20,
                marginTop: 30,
              }}
            >
              <ButtonReturn onClick={handleProfileClick} />

              <ButtonSend onClick={calcularComposicao} />
            </div>
          </form>
        </FormContainer>
        <div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      </Container>
    </>
  );
};

export default Form18;
