import React from "react";
import {
  MainContent,
  PageWrapper,
  CardContainer,
  Card,
  CardImage,
  CardText,
  ContentTextCard,
  TextAcess,
} from "../../Components/styles-all";
import { useNavigate } from "react-router-dom";

import image55 from "../../Images/image55.png";
const images = [image55];

const cardTitles = ["BIOIPEDÂNCIA"];

const MenuAvaliador3 = () => {
  const navigate = useNavigate();

  const handleCardClick = (cardNumber) => {
    if (cardNumber === 1) {
      navigate(`/plataforma/Bioimpedância`);
    }
  };

  const renderCards = () => {
    return images.map((image, index) => (
      <Card key={index} onClick={() => handleCardClick(index + 1)}>
        <ContentTextCard>
          <CardImage src={image} alt={`Card ${index + 1}`} />
          <CardText>{cardTitles[index]}</CardText>
          <TextAcess>Acesse aqui</TextAcess>
        </ContentTextCard>
      </Card>
    ));
  };
  return (
    <PageWrapper>
      <MainContent>
        <CardContainer>{renderCards()}</CardContainer>
      </MainContent>
    </PageWrapper>
  );
};

export default MenuAvaliador3;
