import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../../../Supabase";
import HeaderGeral from "../../../Components/headerGeral";

import {
  Container,
  FormContainer,
  FormGroup,
  FormLabel,
  FormHeader,
  FormInput,
} from "../../../Components/styles-all";
import { ToastContainer, toast } from "react-toastify";
import { SuccessToast, ErrorToast } from "../../../Components/toastyfy";

import ButtonReturn from "../../../Components/button-return";
import ButtonSend from "../../../Components/button-send";

const Form16 = () => {
  const [pesoCorporal, setPesoCorporal] = useState("");
  const [estatura, setEstatura] = useState("");
  const [bmi, setBMI] = useState(null);
  const [id, setId] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleCPFChange = (e) => {
    const cpf = formatCPF(e.target.value);
    setId(cpf);

    const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    setIsValid(regex.test(cpf));
  };

  const formatCPF = (cpf) => {
    let value = cpf.replace(/\D/g, "");
    if (value.length > 3) value = `${value.slice(0, 3)}.${value.slice(3)}`;
    if (value.length > 7) value = `${value.slice(0, 7)}.${value.slice(7)}`;
    if (value.length > 11)
      value = `${value.slice(0, 11)}-${value.slice(11, 13)}`;
    return value;
  };

  const calculateBMI = () => {
    if (estatura && pesoCorporal) {
      const heightMeters = parseFloat(estatura) / 100;
      const weight = parseFloat(pesoCorporal);
      const bmiValue = weight / (heightMeters * heightMeters);
      setBMI(bmiValue.toFixed(2));
    } else {
      setBMI(null);
    }
  };

  const getBMIClassification = (bmi) => {
    if (bmi < 18.5) {
      return "Abaixo do peso";
    } else if (bmi >= 18.5 && bmi <= 24.99) {
      return "Saudável";
    } else if (bmi >= 25 && bmi <= 29.99) {
      return "Sobrepeso";
    } else if (bmi >= 30 && bmi <= 34.99) {
      return "Obesidade grau 1";
    } else if (bmi >= 35 && bmi <= 39.99) {
      return "Obesidade grau 2";
    } else if (bmi >= 40) {
      return "Grau 3 (obesidade mórbida)";
    }
    return "";
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const bmiClassification = getBMIClassification(bmi);

    const data = {
      id,
      bmi,
      bmiClassification,
    };

    const { error } = await supabase.from("imc").insert([data]);
    if (error) {
      toast.error(
        <ErrorToast>
          <strong>Erro!</strong> Erro ao inserir os dados.
        </ErrorToast>
      );
    } else {
      toast.success(
        <SuccessToast>
          <strong>Sucesso!</strong> Dados inseridos com êxito.
        </SuccessToast>
      );
    }
  };

  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate("/plataforma/Avaliação-antropométrica");
  };

  return (
    <>
      <HeaderGeral />
      <Container>
        <FormContainer>
          <FormHeader>
            <h2>ÍNDICE DE MASSA CORPORAL – IMC</h2>
          </FormHeader>

          <form onSubmit={handleSubmit}>
            <FormGroup>
              <FormLabel>Nº de CPF :</FormLabel>
              <FormInput
                type="text"
                value={id}
                maxLength="14"
                onChange={handleCPFChange}
                style={isValid ? {} : { borderColor: "red" }}
                customWidth="25vw"
              />
              {!isValid && <p style={{ color: "red" }}>CPF inválido!</p>}
            </FormGroup>

            <FormGroup>
              <FormLabel>Estatura em cm (Ex: 170cm)</FormLabel>
              <FormInput
                type="text"
                value={estatura}
                onChange={(e) => setEstatura(e.target.value)}
                customWidth="25vw"
              />
              <FormLabel>Peso Corporal em kg (Ex: 70kg):</FormLabel>
              <FormInput
                type="text"
                value={pesoCorporal}
                onChange={(e) => setPesoCorporal(e.target.value)}
                customWidth="25vw"
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 10,
                  marginTop: 20,
                }}
              >
                <ButtonReturn onClick={handleProfileClick} />

                <ButtonSend onClick={calculateBMI} />
              </div>
            </FormGroup>
          </form>
        </FormContainer>
        <div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      </Container>
    </>
  );
};

export default Form16;
