import React, { useState } from "react";
import supabase from "../../../Supabase";
import { useNavigate } from "react-router-dom";

import HeaderGeral from "../../../Components/headerGeral";

import {
  Container,
  FormContainer,
  FormHeader,
  FormInput,
  Table,
  TableTD,
  TableTH,
} from "../../../Components/styles-all";

import { FormRadio } from "./styles";

import { ToastContainer, toast } from "react-toastify";
import { SuccessToast, ErrorToast } from "../../../Components/toastyfy";

import ButtonReturn from "../../../Components/button-return";
import ButtonSend from "../../../Components/button-send";
import FormGroup from "../../../Components/form-group";
import FormLabel from "../../../Components/form-label";

const Form24 = () => {
  const [q1, setQ1] = useState("");
  const [q2, setQ2] = useState("");
  const [q3, setQ3] = useState("");
  const [q4, setQ4] = useState("");
  const [q5, setQ5] = useState("");
  const [q6, setQ6] = useState("");
  const [q7, setQ7] = useState("");
  const [q8, setQ8] = useState("");
  const [q9, setQ9] = useState("");
  const [q10, setQ10] = useState("");
  const [q11, setQ11] = useState("");
  const [q12, setQ12] = useState("");
  const [q13, setQ13] = useState("");
  const [q14, setQ14] = useState("");
  const [q15, setQ15] = useState("");
  const [q16, setQ16] = useState("");
  const [q17, setQ17] = useState("");
  const [q18, setQ18] = useState("");
  const [q19, setQ19] = useState("");
  const [q20, setQ20] = useState("");
  const [q21, setQ21] = useState("");

  const [id, setId] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleCPFChange = (e) => {
    const cpf = formatCPF(e.target.value);
    setId(cpf);

    const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    setIsValid(regex.test(cpf));
  };

  const formatCPF = (cpf) => {
    let value = cpf.replace(/\D/g, "");
    if (value.length > 3) value = `${value.slice(0, 3)}.${value.slice(3)}`;
    if (value.length > 7) value = `${value.slice(0, 7)}.${value.slice(7)}`;
    if (value.length > 11)
      value = `${value.slice(0, 11)}-${value.slice(11, 13)}`;
    return value;
  };

  const calculateScores = () => {
    const depression = [q3, q5, q10, q13, q16, q17, q21];
    const anxiety = [q2, q4, q7, q9, q15, q19, q20];
    const stress = [q1, q6, q8, q11, q12, q14, q18];

    const depressionScore =
      2 * depression.reduce((acc, curr) => acc + Number(curr), 0);
    const anxietyScore =
      2 * anxiety.reduce((acc, curr) => acc + Number(curr), 0);
    const stressScore = 2 * stress.reduce((acc, curr) => acc + Number(curr), 0);

    const depressionClassification = classifyDepression(depressionScore);
    const anxietyClassification = classifyAnxiety(anxietyScore);
    const stressClassification = classifyStress(stressScore);

    return {
      depression: {
        score: depressionScore,
        classification: depressionClassification,
      },
      anxiety: {
        score: anxietyScore,
        classification: anxietyClassification,
      },
      stress: {
        score: stressScore,
        classification: stressClassification,
      },
    };
  };

  const classifyDepression = (score) => {
    if (score <= 9) return "normal";
    if (score >= 10 && score <= 12) return "leve";
    if (score >= 13 && score <= 20) return "moderada";
    if (score >= 21 && score <= 27) return "severo";
    return "extremamente severo";
  };

  const classifyAnxiety = (score) => {
    if (score <= 6) return "normal";
    if (score >= 7 && score <= 9) return "leve";
    if (score >= 10 && score <= 14) return "moderado";
    if (score >= 15 && score <= 19) return "severo";
    return "extremamente severo";
  };

  const classifyStress = (score) => {
    if (score <= 10) return "normal";
    if (score >= 11 && score <= 18) return "leve";
    if (score >= 19 && score <= 26) return "moderado";
    if (score >= 27 && score <= 34) return "severo";
    return "extremamente severo";
  };

  const handleButtonClick = () => {
    const scores = calculateScores();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const scores = calculateScores();

    const data = {
      id,
      depressionClassification: scores.depression.classification,
      anxietyClassification: scores.anxiety.classification,
      stressClassification: scores.stress.classification,
    };
    const { error } = await supabase
      .from("escalaDepreAnsiEstresse")
      .insert([data]);
    if (error) {
      toast.error(
        <ErrorToast>
          <strong>Erro!</strong> Erro ao inserir os dados.
        </ErrorToast>
      );
    } else {
      toast.success(
        <SuccessToast>
          <strong>Sucesso!</strong> Dados inseridos com êxito.
        </SuccessToast>
      );
    }
  };

  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate("/plataforma/Ansiedade-depressão");
  };

  return (
    <>
      <HeaderGeral />
      <Container>
        <FormContainer>
          <FormHeader>
            <h2>ESCALA DE DEPRESSÃO, ANSIEDADE E ESTRESSE</h2>
          </FormHeader>

          <form onSubmit={handleSubmit}>
            <FormGroup>
              <FormLabel>
                Por favor, leia cuidadosamente cada uma das afirmações abaixo e
                circule o número apropriado 0,1,2 ou 3 que indique o quanto ela
                se aplicou a você durante a última semana, conforme a indicação
                a seguir:
              </FormLabel>
            </FormGroup>

            <FormGroup>
              <FormLabel>Nº de CPF :</FormLabel>
              <FormInput
                type="text"
                value={id}
                maxLength="14"
                onChange={handleCPFChange}
                style={isValid ? {} : { borderColor: "red" }}
                customWidth="25vw"
              />
              {!isValid && <p style={{ color: "red" }}>CPF inválido!</p>}
            </FormGroup>

            <Table>
              <tr>
                <TableTH></TableTH>
                <TableTH>Não se aplicou de maneira alguma</TableTH>
                <TableTH>
                  Aplicou-se em algum grau, ou por pouco de tempo
                </TableTH>
                <TableTH>
                  Aplicou-se em um grau considerável, ou por uma boa parte do
                  tempo
                </TableTH>
                <TableTH>Aplicou-se muito, ou na maioria do tempo</TableTH>
              </tr>
              <tr>
                <TableTD>
                  <FormLabel>Achei difícil me acalmar</FormLabel>
                </TableTD>
                {[0, 1, 2, 3].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q1"
                      value={value.toString()}
                      onChange={(e) => setQ1(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>Senti minha boca seca</FormLabel>
                </TableTD>
                {[0, 1, 2, 3].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q2"
                      value={value.toString()}
                      onChange={(e) => setQ2(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>
                    Não consegui vivenciar nenhum sentimento positivo
                  </FormLabel>
                </TableTD>
                {[0, 1, 2, 3].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q3"
                      value={value.toString()}
                      onChange={(e) => setQ3(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>
                    Tive dificuldade em respirar em alguns momentos (ex.
                    respiração ofegante, falta de ar, sem ter feito nenhum
                    esforço físico)
                  </FormLabel>
                </TableTD>
                {[0, 1, 2, 3].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q4"
                      value={value.toString()}
                      onChange={(e) => setQ4(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>
                    Achei difícil ter iniciativa para fazer as coisas
                  </FormLabel>
                </TableTD>
                {[0, 1, 2, 3].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q5"
                      value={value.toString()}
                      onChange={(e) => setQ5(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>
                    Tive a tendência de reagir de forma exagerada às situações
                  </FormLabel>
                </TableTD>
                {[0, 1, 2, 3].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q6"
                      value={value.toString()}
                      onChange={(e) => setQ6(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>Senti tremores (ex. nas mãos)</FormLabel>
                </TableTD>
                {[0, 1, 2, 3].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q7"
                      value={value.toString()}
                      onChange={(e) => setQ7(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>Senti que estava sempre nervoso</FormLabel>
                </TableTD>
                {[0, 1, 2, 3].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q8"
                      value={value.toString()}
                      onChange={(e) => setQ8(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>
              <tr>
                <TableTD>
                  <FormLabel>
                    Preocupei-me com situações em que eu pudesse entrar em
                    pânico e parecesse ridículo (a)
                  </FormLabel>
                </TableTD>
                {[0, 1, 2, 3].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q9"
                      value={value.toString()}
                      onChange={(e) => setQ9(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>Senti que não tinha nada a desejar</FormLabel>
                </TableTD>
                {[0, 1, 2, 3].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q10"
                      value={value.toString()}
                      onChange={(e) => setQ10(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>Senti-me agitado</FormLabel>
                </TableTD>
                {[0, 1, 2, 3].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q11"
                      value={value.toString()}
                      onChange={(e) => setQ11(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>Achei difícil relaxar</FormLabel>
                </TableTD>
                {[0, 1, 2, 3].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q12"
                      value={value.toString()}
                      onChange={(e) => setQ12(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>Senti-me depressivo (a) e sem ânimo.</FormLabel>
                </TableTD>
                {[0, 1, 2, 3].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q13"
                      value={value.toString()}
                      onChange={(e) => setQ13(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>
                    Fui intolerante com as coisas que me impediam de continuar o
                    que eu estava fazendo.
                  </FormLabel>
                </TableTD>
                {[0, 1, 2, 3].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q14"
                      value={value.toString()}
                      onChange={(e) => setQ14(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>Senti que ia entrar em pânico</FormLabel>
                </TableTD>
                {[0, 1, 2, 3].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q15"
                      value={value.toString()}
                      onChange={(e) => setQ15(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>Não consegui me entusiasmar com nada</FormLabel>
                </TableTD>
                {[0, 1, 2, 3].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q16"
                      value={value.toString()}
                      onChange={(e) => setQ16(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>Senti que não tinha valor como pessoa</FormLabel>
                </TableTD>
                {[0, 1, 2, 3].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q17"
                      value={value.toString()}
                      onChange={(e) => setQ17(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>
                    Senti que estava um pouco emotivo/sensível demais
                  </FormLabel>
                </TableTD>
                {[0, 1, 2, 3].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q18"
                      value={value.toString()}
                      onChange={(e) => setQ18(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>
                    Sabia que meu coração estava alterado mesmo não tendo feito
                    nenhum esforço físico (ex. aumento da frequência cardíaca,
                    disritmia cardíaca)
                  </FormLabel>
                </TableTD>
                {[0, 1, 2, 3].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q19"
                      value={value.toString()}
                      onChange={(e) => setQ19(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>Senti medo sem motivo</FormLabel>
                </TableTD>
                {[0, 1, 2, 3].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q20"
                      value={value.toString()}
                      onChange={(e) => setQ20(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>Senti que a vida não tinha sentido</FormLabel>
                </TableTD>
                {[0, 1, 2, 3].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q21"
                      value={value.toString()}
                      onChange={(e) => setQ21(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>
            </Table>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 10,
                marginRight: 20,
                marginBottom: 30,
                marginTop: 30,
              }}
            >
              <ButtonReturn onClick={handleProfileClick} />

              <ButtonSend onClick={handleButtonClick} />
            </div>
          </form>
        </FormContainer>
        <div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      </Container>
    </>
  );
};

export default Form24;
