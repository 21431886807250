import React, { useState, useEffect } from "react";
import HeaderGeral from "../../Components/headerGeral";
import {
  Container,
  DivInputs,
  FormContainer,
  FormHeader,
  FormInput,
} from "../../Components/styles-all";
import FormGroup from "../../Components/form-group";
import FormLabel from "../../Components/form-label";
import styled from "styled-components";
import ButtonSend from "../../Components/button-send";
import FormSelect from "../../Components/form-select";
import InputMask from "react-input-mask";
import supabase from "../../Supabase";
import { ToastContainer, toast } from "react-toastify";
import { Form } from "react-router-dom";

const StyledInputMask = styled(InputMask)`
  width: ${(props) => props.customWidth || "100%"};
  height: 2.97vh;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  border: 1px solid #b8b8b8;

  @media (max-width: 768px) {
    height: 5vh;
  }
`;

const ConfirmarCadastroForm: React.FC = () => {
  const [nomeCompleto, setNomeCompleto] = useState<string>("");
  const [dataNascimento, setDataNascimento] = useState<string>("");
  const [cpf, setCpf] = useState<string>("");
  const [endereco, setEndereco] = useState<string>("");
  const [cidade, setCidade] = useState<string>("");
  const [estado, setEstado] = useState<string>("");
  const [cep, setCep] = useState<string>("");
  const [telefone, setTelefone] = useState<string>("");
  const [documento, setDocumento] = useState<File | null>(null);
  const [formErrors, setFormErrors] = useState<string[]>([]);

  const estados = [
    { value: "SP", label: "São Paulo" },
    { value: "RJ", label: "Rio de Janeiro" },
    { value: "MG", label: "Minas Gerais" },
    { value: "ES", label: "Espírito Santo" },
    { value: "RS", label: "Rio Grande do Sul" },
    { value: "SC", label: "Santa Catarina" },
    { value: "PR", label: "Paraná" },
    { value: "BA", label: "Bahia" },
    { value: "PE", label: "Pernambuco" },
    { value: "CE", label: "Ceará" },
    { value: "GO", label: "Goiás" },
    { value: "DF", label: "Distrito Federal" },
    { value: "MT", label: "Mato Grosso" },
    { value: "MS", label: "Mato Grosso do Sul" },
    { value: "AM", label: "Amazonas" },
    { value: "PA", label: "Pará" },
    { value: "MA", label: "Maranhão" },
    { value: "PI", label: "Piauí" },
    { value: "AL", label: "Alagoas" },
    { value: "SE", label: "Sergipe" },
    { value: "RN", label: "Rio Grande do Norte" },
    { value: "PB", label: "Paraíba" },
    { value: "PE", label: "Pernambuco" },
    { value: "AC", label: "Acre" },
    { value: "RO", label: "Rondônia" },
    { value: "RR", label: "Roraima" },
    { value: "AP", label: "Amapá" },
    { value: "TO", label: "Tocantins" },
  ];

  const handleChangeEstado = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setEstado(e.target.value);
  };

  const handleChangeDocumento = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    setDocumento(file);
  };

  const nomeCompletoRegex = /^[A-Za-zÀ-ú\s]+$/;
  const dataNascimentoRegex = /^\d{4}-\d{2}-\d{2}$/;
  const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
  const enderecoRegex = /^[A-Za-z0-9\s,.-]+$/;
  const cidadeRegex = /^[A-Za-zÀ-ú\s]+$/;
  const cepRegex = /^\d{5}-\d{3}$/;
  const telefoneRegex = /^\(\d{2}\) \d{4,5}-\d{4}$/;

  const validateForm = () => {
    const errors: string[] = [];
    if (!nomeCompletoRegex.test(nomeCompleto))
      errors.push("Nome completo inválido");
    if (!dataNascimentoRegex.test(dataNascimento))
      errors.push("Data de nascimento inválida");
    if (!cpfRegex.test(cpf)) errors.push("CPF inválido");
    if (!enderecoRegex.test(endereco)) errors.push("Endereço inválido");
    if (!cidadeRegex.test(cidade)) errors.push("Cidade inválida");
    if (!cepRegex.test(cep)) errors.push("CEP inválido");
    if (!telefoneRegex.test(telefone)) errors.push("Telefone inválido");

    setFormErrors(errors);
    return errors.length === 0;
  };

  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      setUser(user);
    };

    fetchUser();
  }, []);

  console.log(user);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        let documentUrl = "";
        if (documento) {
          const documentoNome = `${
            documento.name.split(".")[0]
          }_${cpf}.${documento.name.split(".").pop()}`;

          const { data, error } = await supabase.storage
            .from("cadastros")
            .upload(`documents/${documentoNome}`, documento);

          if (error) throw error;

          documentUrl = data?.path ?? "";
        }

        const { data, error } = await supabase.from("cadastros").insert([
          {
            nomeCompleto: nomeCompleto,
            dataNascimento: dataNascimento,
            cpf: cpf,
            endereço: endereco,
            cidade: cidade,
            estado: estado,
            cep: cep,
            telefone: telefone,
            documento_url: documentUrl,
            finalizado: false,
            id: user.id,
          },
        ]);

        if (error) {
          toast.error("Erro ao enviar dados");
          console.error("Erro ao enviar dados:", error);
        } else {
          toast.success("Cadastro realizado com sucesso");

          console.log("Cadastro realizado com sucesso!", data);
        }
      } catch (error) {
        console.error("Erro ao realizar o cadastro:", error);
      }
    } else {
      console.log("Formulário contém erros.");
    }
  };

  return (
    <>
      <HeaderGeral />
      <Container>
        <FormHeader>
          <h2>Finalize seu cadastro para acesso ao SIAS</h2>
        </FormHeader>
        <FormContainer>
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <FormLabel>Nome Completo</FormLabel>
              <FormInput
                type="text"
                value={nomeCompleto}
                onChange={(e) => setNomeCompleto(e.target.value)}
                placeholder="Digite seu nome completo"
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Data de Nascimento</FormLabel>
              <FormInput
                type="date"
                value={dataNascimento}
                onChange={(e) => setDataNascimento(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>CPF</FormLabel>
              <StyledInputMask
                mask="999.999.999-99"
                value={cpf}
                onChange={(e) => setCpf(e.target.value)}
                placeholder="Digite seu CPF"
                alwaysShowMask
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Endereço</FormLabel>
              <FormInput
                type="text"
                value={endereco}
                onChange={(e) => setEndereco(e.target.value)}
                placeholder="Digite seu endereço"
              />
            </FormGroup>

            <DivInputs>
              <FormGroup>
                <FormLabel>Cidade</FormLabel>
                <FormInput
                  type="text"
                  value={cidade}
                  onChange={(e) => setCidade(e.target.value)}
                  placeholder="Digite sua cidade"
                />
              </FormGroup>

              <FormGroup>
                <FormLabel>Estado</FormLabel>
                <FormSelect
                  value={estado}
                  onChange={handleChangeEstado}
                  options={estados}
                />
              </FormGroup>
            </DivInputs>

            <FormGroup>
              <FormLabel>CEP</FormLabel>
              <StyledInputMask
                mask="99999-999"
                value={cep}
                onChange={(e) => setCep(e.target.value)}
                placeholder="Digite seu CEP"
                alwaysShowMask
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Telefone</FormLabel>
              <StyledInputMask
                mask="(99) 99999-9999"
                value={telefone}
                onChange={(e) => setTelefone(e.target.value)}
                placeholder="Digite seu telefone"
                alwaysShowMask
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>Foto do Documento (RG ou CNH)</FormLabel>
              <input
                type="file"
                accept="image/*"
                id="documentPhoto"
                name="documentPhoto"
                onChange={handleChangeDocumento}
              />
            </FormGroup>

            {formErrors.length > 0 && (
              <div style={{ color: "red" }}>
                <ul>
                  {formErrors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            )}

            <ButtonSend />
          </form>
        </FormContainer>

        <div>
          <ToastContainer />
        </div>
      </Container>
    </>
  );
};

export default ConfirmarCadastroForm;
