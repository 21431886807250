import React from "react";
import { useNavigate } from "react-router-dom";
import {
  PageWrapper,
  MainContent,
  CardContainer,
  Card,
  CardImage,
  ContentTextCard,
  CardText,
  TextAcess,
  WelcomeText,
} from "./styles";
import image20 from "../../../Images/image20.png";
import image21 from "../../../Images/image21.png";

import HeaderQuestionarios from "../../../Components/headerQuestionarios";

const images = [image20, image21];
const cardTitles = ["EXAME SÍNDROME METABÓLICA", "EXAMES LABORATORIAIS"];

const Form8 = () => {
  const navigate = useNavigate();

  const handleCardClick = (cardNumber) => {
    if (cardNumber === 1) {
      navigate(`/plataforma/Exame-sindrome-metabólica`);
    } else if (cardNumber === 2) {
      navigate(`/plataforma/Exames-laboratoriais`);
    }
  };

  const renderCards = () => {
    return images.map((image, index) => (
      <Card key={index} onClick={() => handleCardClick(index + 1)}>
        <ContentTextCard>
          <CardImage src={image} alt={`Card ${index + 1}`} />
          <CardText>{cardTitles[index]}</CardText>
          <TextAcess>Acesse aqui</TextAcess>
        </ContentTextCard>
      </Card>
    ));
  };

  return (
    <PageWrapper>
      <HeaderQuestionarios />
      <MainContent>
        <WelcomeText>Exames bioquímicos</WelcomeText>
        <CardContainer>{renderCards()}</CardContainer>
      </MainContent>
    </PageWrapper>
  );
};

export default Form8;
