import React, { useEffect, useState } from "react";
import supabase from "../../../../Supabase";
import { Bar, PolarArea } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useNavigate } from "react-router-dom";
import {
  FormContainer,
  ChartContainer,
  ResultTitle,
  ToggleButton,
  DivButton,
} from "./styles";
import HeaderGeral from "../../../../Components/headerGeral";

import ButtonReturn from "../../../../Components/button-return";
import { Container } from "../../../../Components/styles-all";

import {
  questionsAndResponses,
  questionsAndResponsesForm2,
  categoryTitles,
  questionsAndResponsesForm21,
  questionsAndResponsesForm21Titles,
  questionsAndResponsesForm13,
  questionsAndResponsesForm13Titles,
} from "./titles";
Chart.register(...registerables);
Chart.register(ChartDataLabels);

const chartOptions = {
  responsive: true,
  plugins: {
    datalabels: {
      color: "#fff",
      display: function (context) {
        return context.dataset.data[context.dataIndex] !== 0;
      },
      font: {
        weight: "bold",
      },
      formatter: function (value, context) {
        return `${value.toFixed(0)}%`;
      },
      title: {
        display: true,
        text: "Custom Chart Title",
      },
    },
  },
  scales: {
    r: {
      min: 0,
      max: 100,
      beginAtZero: true,
      ticks: {
        stepSize: 10,
        callback: function (value) {
          return `${value}%`;
        },
      },
    },
  },
};

const ResultadoPILOTO = () => {
  const [showAll, setShowAll] = useState(false);

  const [showAll3, setShowAll3] = useState(false);

  const [resultsForm3, setResultsForm3] = useState([]);
  const [resultsForm2, setResultsForm2] = useState([]);
  const [resultsForm21, setResultsForm21] = useState([]);
  const [resultsForm12, setResultsForm12] = useState([]);
  const [resultsForm13, setResultsForm13] = useState([]);

  const [activeChart, setActiveChart] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const { data: form2Data, error: form2Error } = await supabase
        .from("sociodemograficoEscolar")
        .select("*");
      const { data: form3Data, error: form3Error } = await supabase
        .from("caracHabVida")
        .select("*")
        .in(
          "id",
          form2Data.map((item) => item.id)
        );
      const { data: form21Data, error: form21Error } = await supabase
        .from("habitosVida")
        .select("*")
        .in(
          "id",
          form2Data.map((item) => item.id)
        );
      const { data: form12Data, error: form12Error } = await supabase
        .from("estresseTrabalho")
        .select("*")
        .in(
          "id",
          form2Data.map((item) => item.id)
        );
      const { data: form13Data, error: form13Error } = await supabase
        .from("avaliEsgoPro")
        .select("*")
        .in(
          "id",
          form2Data.map((item) => item.id)
        );

      if (form3Error) {
      } else {
        setResultsForm3(form3Data);
      }
      if (form2Error) {
      } else {
        setResultsForm2(form2Data);
      }
      if (form21Error) {
      } else {
        setResultsForm21(form21Data);
      }
      if (form12Error) {
      } else {
        setResultsForm12(form12Data);
      }
      if (form13Error) {
      } else {
        setResultsForm13(form13Data);
      }
    }

    fetchData();
  }, []);

  const colors = [
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(75, 192, 192, 1)",
    "rgba(153, 102, 255, 1)",
    "rgba(70,130,180,1)",
    "rgba(255, 159, 64, 1)",
    "rgba(102, 205, 170, 1)",
    "rgba(255, 99, 71, 1)",
    "rgba(138, 43, 226, 1)",
    "rgba(147, 112, 219, 1)",
    "rgba(152, 251, 152, 1)",
    "rgba(0, 128, 128, 1)",
    "rgba(0, 255, 127, 1)",
    "rgba(32, 178, 170, 1)",
    "rgba(210, 105, 30, 1)",
    "rgba(107, 142, 35, 1)",
    "rgba(25, 25, 112, 1)",
    "rgba(255, 20, 147, 1)",
    "rgba(189, 183, 107, 1)",
  ];

  const chartLabels = Object.keys(questionsAndResponses);
  const responseCounts = {};

  for (let question in questionsAndResponses) {
    responseCounts[question] = Array(
      questionsAndResponses[question].length
    ).fill(0);
  }

  resultsForm3.forEach((entry) => {
    for (let question in questionsAndResponses) {
      const responseIndex = questionsAndResponses[question].indexOf(
        entry[question]
      );
      if (responseIndex !== -1) {
        responseCounts[question][responseIndex]++;
      }
    }
  });

  const responsePercentages = {};
  for (let key in responseCounts) {
    const totalResponses = resultsForm3.length;
    responsePercentages[key] = responseCounts[key].map((count) => {
      const percentage = (count / totalResponses) * 100;
      return Math.min(100, Math.max(0, percentage));
    });
  }

  resultsForm12.forEach((entry) => {
    for (let question in questionsAndResponses) {
      const responseIndex = questionsAndResponses[question].indexOf(
        entry[question]
      );
      if (responseIndex !== -1) {
        responseCounts[question][responseIndex]++;
      }
    }
  });

  const calculateResponsePercentages = (data, questionsAndResponses) => {
    const responseCounts = {};

    for (let question in questionsAndResponses) {
      responseCounts[question] = Array(
        questionsAndResponses[question].length
      ).fill(0);
    }

    data.forEach((entry) => {
      for (let question in questionsAndResponses) {
        if (
          question === "afastamentoCausas" &&
          typeof entry[question] === "string"
        ) {
          const parsedAfastamentoCausas = JSON.parse(entry[question]);
          parsedAfastamentoCausas.forEach((response) => {
            const responseIndex =
              questionsAndResponses[question].indexOf(response);
            if (responseIndex !== -1) {
              responseCounts[question][responseIndex]++;
            }
          });
        } else {
          const responseIndex = questionsAndResponses[question].indexOf(
            entry[question]
          );
          if (responseIndex !== -1) {
            responseCounts[question][responseIndex]++;
          }
        }
      }
    });

    const responsePercentages = {};
    for (let key in responseCounts) {
      const totalResponses = data.length;
      responsePercentages[key] = responseCounts[key].map(
        (count) => (count / totalResponses) * 100
      );
    }

    return responsePercentages;
  };

  const form2ResponsePercentages = calculateResponsePercentages(
    resultsForm2,
    questionsAndResponsesForm2
  );

  const form21ResponsePercentages = calculateResponsePercentages(
    resultsForm21,
    questionsAndResponsesForm21
  );

  const form13ResponsePercentages = calculateResponsePercentages(
    resultsForm13,
    questionsAndResponsesForm13
  );

  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate("/plataforma/");
  };

  return (
    <>
      <HeaderGeral />
      <Container>
        <DivButton>
          <ButtonReturn onClick={handleProfileClick} />
        </DivButton>
        <ResultTitle>sociodemográfico</ResultTitle>

        <FormContainer>
          {Object.keys(questionsAndResponsesForm2).map((question, index) => (
            <div key={index}>
              <ToggleButton
                onClick={() => {
                  if (activeChart === question) {
                    setActiveChart(null);
                  } else {
                    setActiveChart(question);
                  }
                }}
              >
                {activeChart === question
                  ? `Esconder ${categoryTitles[question]}`
                  : `Mostrar ${categoryTitles[question]}`}
              </ToggleButton>

              {activeChart === question && (
                <ChartContainer>
                  <ResultTitle>{categoryTitles[question]}</ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm2[question],
                      datasets: [
                        {
                          label:"",
                          data: form2ResponsePercentages[question],
                          backgroundColor: colors,
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              )}
            </div>
          ))}
        </FormContainer>

        <ToggleButton onClick={() => setShowAll(!showAll)}>
          {showAll ? "Esconder todos" : "Mostrar todos"}
        </ToggleButton>

        <FormContainer>
          {showAll &&
            Object.keys(questionsAndResponsesForm2).map((question, index) => (
              <div key={index}>
                <ChartContainer>
                  <ResultTitle>{categoryTitles[question]}</ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm2[question],
                      datasets: [
                        {
                          label:"",
                          data: form2ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              </div>
            ))}
        </FormContainer>

        <ResultTitle>
          AVALIAÇÃO DA QUALIDADE DE VIDA RELACIONADA A SAÚDE
        </ResultTitle>

        <FormContainer>
          {Object.keys(questionsAndResponsesForm21).map((question, index) => (
            <div key={index}>
              <ToggleButton
                onClick={() => {
                  if (activeChart === question) {
                    setActiveChart(null);
                  } else {
                    setActiveChart(question);
                  }
                }}
              >
                {activeChart === question
                  ? `Esconder ${questionsAndResponsesForm21Titles[question]}`
                  : `Mostrar ${questionsAndResponsesForm21Titles[question]}`}
              </ToggleButton>

              {activeChart === question && (
                <ChartContainer>
                  <ResultTitle>
                    {questionsAndResponsesForm21Titles[question]}
                  </ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm21[question],
                      datasets: [
                        {
                          label:"",
                          data: form21ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              )}
            </div>
          ))}
        </FormContainer>

        <ToggleButton onClick={() => setShowAll3(!showAll3)}>
          {showAll3 ? "Esconder todos" : "Mostrar todos"}
        </ToggleButton>

        <FormContainer>
          {showAll3 &&
            Object.keys(questionsAndResponsesForm21).map((question, index) => (
              <div key={index}>
                <ChartContainer>
                  <ResultTitle>
                    {questionsAndResponsesForm21Titles[question]}
                  </ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm21[question],
                      datasets: [
                        {
                          label:"",
                          data: form21ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              </div>
            ))}
        </FormContainer>

        <ResultTitle>AVALIAÇÃO DO ESGOTAMENTO PROFISSIONAL</ResultTitle>
        <FormContainer>
          {Object.keys(questionsAndResponsesForm13).map((question, index) => (
            <div key={index}>
              <ToggleButton
                onClick={() => {
                  if (activeChart === question) {
                    setActiveChart(null);
                  } else {
                    setActiveChart(question);
                  }
                }}
              >
                {activeChart === question
                  ? `Esconder ${questionsAndResponsesForm13Titles[question]}`
                  : `Mostrar ${questionsAndResponsesForm13Titles[question]}`}
              </ToggleButton>

              {activeChart === question && (
                <ChartContainer>
                  <ResultTitle>
                    {questionsAndResponsesForm13Titles[question]}
                  </ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm13[question],
                      datasets: [
                        {
                          label:"",
                          data: form13ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              )}
            </div>
          ))}
        </FormContainer>
      </Container>
    </>
  );
};

export default ResultadoPILOTO;
