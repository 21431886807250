import React, { useState, useEffect } from "react";
import supabase from "../../../Supabase";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { SuccessToast, ErrorToast } from "../../../Components/toastyfy";

import HeaderGeral from "../../../Components/headerGeral";

import {
  Container,
  FormContainer,
  FormHeader,
  FormInput,
} from "../../../Components/styles-all";

import {
  FormGroup,
  FormLabel,
  FormRadioGroup,
  FormRadio,
  FormGroupIntro,
  FormLabelIntro,
} from "./styles";

import ButtonReturn from "../../../Components/button-return";
import ButtonSend from "../../../Components/button-send";

const getLabelForValue = (value) => {
  switch (value) {
    case 0:
      return "Não me sinto triste ";
    case 1:
      return "Eu me sinto triste";
    case 2:
      return "Estou sempre triste e não consigo sair disto ";
    case 3:
      return "Estou tão triste ou infeliz que não consigo suportar ";
    default:
      return "";
  }
};

const getLabelForValue2 = (value) => {
  switch (value) {
    case 0:
      return "Não estou especialmente desanimado quanto ao futuro";
    case 1:
      return "Eu me sinto desanimado quanto ao futuro ";
    case 2:
      return "Acho que nada tenho a esperar ";
    case 3:
      return "Acho o futuro sem esperanças e tenho a impressão de que as coisas não podem melhorar ";
    default:
      return "";
  }
};

const getLabelForValue3 = (value) => {
  switch (value) {
    case 0:
      return "Não me sinto um fracasso ";
    case 1:
      return "Acho que fracassei mais do que uma pessoa comum ";
    case 2:
      return "Quando olho pra trás, na minha vida, tudo o que posso ver é um monte de fracassos ";
    case 3:
      return "Acho que, como pessoa, sou um completo fracasso ";
    default:
      return "";
  }
};

const getLabelForValue4 = (value) => {
  switch (value) {
    case 0:
      return "Tenho tanto prazer em tudo como antes  ";
    case 1:
      return "Não sinto mais prazer nas coisas como antes ";
    case 2:
      return "2 -Não encontro um prazer real em mais nada  ";
    case 3:
      return "Estou insatisfeito ou aborrecido com tudo  ";
    default:
      return "";
  }
};

const getLabelForValue5 = (value) => {
  switch (value) {
    case 0:
      return "Não me sinto especialmente culpado  ";
    case 1:
      return "Eu me sinto culpado grande parte do tempo  ";
    case 2:
      return "Eu me sinto culpado na maior parte do tempo   ";
    case 3:
      return "Eu me sinto sempre culpado  ";
    default:
      return "";
  }
};

const getLabelForValue6 = (value) => {
  switch (value) {
    case 0:
      return "Não acho que esteja sendo punido  ";
    case 1:
      return "Acho que posso ser punido  ";
    case 2:
      return "Creio que vou ser punido   ";
    case 3:
      return "Acho que estou sendo punido  ";
    default:
      return "";
  }
};

const getLabelForValue7 = (value) => {
  switch (value) {
    case 0:
      return "Não me sinto decepcionado comigo mesmo  ";
    case 1:
      return "Estou decepcionado comigo mesmo  ";
    case 2:
      return "Estou enojado de mim ";
    case 3:
      return "Eu me odeio  ";
    default:
      return "";
  }
};

const getLabelForValue8 = (value) => {
  switch (value) {
    case 0:
      return "Não me sinto de qualquer modo pior que os outros  ";
    case 1:
      return "Sou crítico em relação a mim por minhas fraquezas ou erros   ";
    case 2:
      return "Eu me culpo sempre por minhas falhas ";
    case 3:
      return "Eu me culpo por tudo de mal que acontece  ";
    default:
      return "";
  }
};

const getLabelForValue9 = (value) => {
  switch (value) {
    case 0:
      return "Não tenho quaisquer idéias de me matar  ";
    case 1:
      return "Tenho idéias de me matar, mas não as executaria    ";
    case 2:
      return "Gostaria de me matar  ";
    case 3:
      return "Eu me mataria se tivesse oportunidade   ";
    default:
      return "";
  }
};

const getLabelForValue10 = (value) => {
  switch (value) {
    case 0:
      return "Não choro mais que o habitual  ";
    case 1:
      return "Choro mais agora do que costumava  ";
    case 2:
      return "Agora, choro o tempo todo  ";
    case 3:
      return "Costumava ser capaz de chorar, mas agora não consigo, mesmo que o queria ";
    default:
      return "";
  }
};

const getLabelForValue11 = (value) => {
  switch (value) {
    case 0:
      return "Não sou mais irritado agora do que já fui  ";
    case 1:
      return "Fico aborrecido ou irritado mais facilmente do que costumava ";
    case 2:
      return "Agora, eu me sinto irritado o tempo todo  ";
    case 3:
      return "Não me irrito mais com coisas que costumavam me irritar  ";
    default:
      return "";
  }
};

const getLabelForValue12 = (value) => {
  switch (value) {
    case 0:
      return "Não perdi o interesse pelas outras pessoas   ";
    case 1:
      return "Estou menos interessado pelas outras pessoas do que costumava estar  ";
    case 2:
      return "Perdi a maior parte do meu interesse pelas outras pessoas  ";
    case 3:
      return "Perdi todo o interesse pelas outras pessoas  ";
    default:
      return "";
  }
};

const getLabelForValue13 = (value) => {
  switch (value) {
    case 0:
      return "Tomo decisões tão bem quanto antes   ";
    case 1:
      return "Adio as tomadas de decisões mais do que costumava  ";
    case 2:
      return "Tenho mais dificuldades de tomar decisões do que antes   ";
    case 3:
      return "Absolutamente não consigo mais tomar decisões   ";
    default:
      return "";
  }
};

const getLabelForValue14 = (value) => {
  switch (value) {
    case 0:
      return "Não acho que de qualquer modo pareço pior do que antes   ";
    case 1:
      return "Estou preocupado em estar parecendo velho ou sem atrativo  ";
    case 2:
      return "Acho que há mudanças permanentes na minha aparência, que me fazem parecer sem atrativo   ";
    case 3:
      return "Acredito que pareço feio  ";
    default:
      return "";
  }
};

const getLabelForValue15 = (value) => {
  switch (value) {
    case 0:
      return "Posso trabalhar tão bem quanto antes  ";
    case 1:
      return "É preciso algum esforço extra para fazer alguma coisa  ";
    case 2:
      return "Tenho que me esforçar muito para fazer alguma coisa    ";
    case 3:
      return "Não consigo mais fazer qualquer trabalho  ";
    default:
      return "";
  }
};

const getLabelForValue16 = (value) => {
  switch (value) {
    case 0:
      return "Consigo dormir tão bem como o habitual   ";
    case 1:
      return "Não durmo tão bem como costumava  ";
    case 2:
      return "Acordo 1 a 2 horas mais cedo do que habitualmente e acho difícil voltar a dormir    ";
    case 3:
      return "Acordo várias horas mais cedo do que costumava e não consigo voltar a dormir   ";
    default:
      return "";
  }
};

const getLabelForValue17 = (value) => {
  switch (value) {
    case 0:
      return "Não fico mais cansado do que o habitual   ";
    case 1:
      return "Fico cansado mais facilmente do que costumava  ";
    case 2:
      return "Fico cansado em fazer qualquer coisa    ";
    case 3:
      return "Estou cansado demais para fazer qualquer coisa   ";
    default:
      return "";
  }
};

const getLabelForValue18 = (value) => {
  switch (value) {
    case 0:
      return "O meu apetite não está pior do que o habitual   ";
    case 1:
      return "Meu apetite não é tão bom como costumava ser  ";
    case 2:
      return "Meu apetite é muito pior agora    ";
    case 3:
      return "Absolutamente não tenho mais apetite   ";
    default:
      return "";
  }
};

const getLabelForValue19 = (value) => {
  switch (value) {
    case 0:
      return "Não tenho perdido muito peso se é que perdi algum recentemente    ";
    case 1:
      return "Perdi mais do que 2 quilos e meio  ";
    case 2:
      return "Perdi mais do que 5 quilos  ";
    case 3:
      return "Perdi mais do que 7 quilos   ";
    default:
      return "";
  }
};

const getLabelForValue20 = (value) => {
  switch (value) {
    case 0:
      return "Não estou mais preocupado com a minha saúde do que o habitual    ";
    case 1:
      return "Estou preocupado com problemas físicos, tais como dores, indisposição do estômago ou constipação  ";
    case 2:
      return "Estou muito preocupado com problemas físicos e é difícil pensar em outra coisa  ";
    case 3:
      return "Estou tão preocupado com meus problemas físicos que não consigo pensar em qualquer outra coisa    ";
    default:
      return "";
  }
};

const getLabelForValue21 = (value) => {
  switch (value) {
    case 0:
      return "Não notei qualquer mudança recente no meu interesse por sexo    ";
    case 1:
      return "Estou menos interessado por sexo do que costumava  ";
    case 2:
      return "Estou muito menos interessado por sexo agora  ";
    case 3:
      return "Perdi completamente o interesse por sexo   ";
    default:
      return "";
  }
};

const Form100002 = () => {
  const [showResult, setShowResult] = useState(false);
  const [q1a, setQ1a] = useState("0");
  const [q2a, setQ2a] = useState("0");
  const [q3a, setQ3a] = useState("0");
  const [q4a, setQ4a] = useState("0");
  const [q5a, setQ5a] = useState("0");
  const [q6a, setQ6a] = useState("0");
  const [q7a, setQ7a] = useState("0");
  const [q8a, setQ8a] = useState("0");
  const [q9a, setQ9a] = useState("0");
  const [q10a, setQ10a] = useState("0");
  const [q11a, setQ11a] = useState("0");
  const [q12a, setQ12a] = useState("0");
  const [q13a, setQ13a] = useState("0");
  const [q14a, setQ14a] = useState("0");
  const [q15a, setQ15a] = useState("0");
  const [q16a, setQ16a] = useState("0");
  const [q17a, setQ17a] = useState("0");
  const [q18a, setQ18a] = useState("0");
  const [q19a, setQ19a] = useState("0");
  const [q20a, setQ20a] = useState("0");
  const [q21a, setQ21a] = useState("0");
  const [q22a, setQ22a] = useState("0");
  const [burnoutTotal, setBurnoutTotal] = useState(null);
  const [burnoutClassification, setBurnoutClassification] = useState(" ");
  const [calculated, setCalculated] = useState(false);
  const [id, setId] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleCPFChange = (e) => {
    const cpf = formatCPF(e.target.value);
    setId(cpf);

    const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    setIsValid(regex.test(cpf));
  };

  const formatCPF = (cpf) => {
    let value = cpf.replace(/\D/g, "");
    if (value.length > 3) value = `${value.slice(0, 3)}.${value.slice(3)}`;
    if (value.length > 7) value = `${value.slice(0, 7)}.${value.slice(7)}`;
    if (value.length > 11)
      value = `${value.slice(0, 11)}-${value.slice(11, 13)}`;
    return value;
  };

  useEffect(() => {
    const answersTo22Questions = [
      q1a,
      q2a,
      q3a,
      q4a,
      q5a,
      q6a,
      q7a,
      q8a,
      q9a,
      q10a,
      q11a,
      q12a,
      q13a,
      q14a,
      q15a,
      q16a,
      q17a,
      q18a,
      q19a,
      q20a,
      q21a,
      q22a,
    ];
    const total = calcularBurnoutTotal(answersTo22Questions);
    const classification = classifyBurnoutLevel(total);
    setBurnoutTotal(total);
    setBurnoutClassification(classification);
    setCalculated(true);
  }, [
    q1a,
    q2a,
    q3a,
    q4a,
    q5a,
    q6a,
    q7a,
    q8a,
    q9a,
    q10a,
    q11a,
    q12a,
    q13a,
    q14a,
    q15a,
    q16a,
    q17a,
    q18a,
    q19a,
    q20a,
    q21a,
    q22a,
  ]);

  const calcularBurnoutTotal = (answers) => {
    const valoresNumericos = answers.map((answer) => parseFloat(answer));

    if (valoresNumericos.some(isNaN)) {
      return null;
    }

    const total = valoresNumericos.reduce((sum, value) => sum + value, 0);
    return total;
  };

  const classifyBurnoutLevel = (total) => {
    if (total >= 0 && total <= 9) {
      return "o indivíduo não está deprimido";
    } else if (total >= 10 && total <= 18) {
      return "depressão leve a moderada";
    } else if (total >= 10 && total <= 29) {
      return "depressão moderada a severa ";
    } else if (total >= 30 && total <= 63) {
      return "depressão severa";
    } else {
      return "severidade dos sintomas depressivos";
    }
  };

  const handleBurnoutCalculation = () => {
    const answersTo22Questions = [
      q1a,
      q2a,
      q3a,
      q4a,
      q5a,
      q6a,
      q7a,
      q8a,
      q9a,
      q10a,
      q11a,
      q12a,
      q13a,
      q14a,
      q15a,
      q16a,
      q17a,
      q18a,
      q19a,
      q20a,
      q21a,
      q22a,
    ];
    const total = calcularBurnoutTotal(answersTo22Questions);

    const classification = classifyBurnoutLevel(total);

    setBurnoutTotal(total);
    setBurnoutClassification(classification);

    setShowResult(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      e.preventDefault();

      const formData = {
        id,
        burnoutClassification,
      };

      const { data, error } = await supabase
        .from("depressaoBeck")
        .insert([formData]);

      if (error) {
        toast.error(
          <ErrorToast>
            <strong>Erro!</strong> Erro ao inserir os dados.
          </ErrorToast>
        );
      } else {
        toast.success(
          <SuccessToast>
            <strong>Sucesso!</strong> Dados inseridos com êxito.
          </SuccessToast>
        );
      }
    } catch (e) {
      toast.error(
        <ErrorToast>
          <strong>Erro!</strong> Erro ao inserir os dados.
        </ErrorToast>
      );
    }
  };

  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate("/plataforma/Sofrimento-mental");
  };

  return (
    <>
      <HeaderGeral />
      <Container>
        <FormContainer>
          <FormHeader>
            <h2>INVENTÁRIO DE DEPRESSÃO DE BECK - BDI </h2>
          </FormHeader>

          <form onSubmit={handleSubmit}>
            <FormGroupIntro>
              <FormLabelIntro>
                Este questionário consiste em 21 grupos de afirmações. Depois de
                ler cuidadosamente cada grupo, selecione número (0, 1, 2 ou 3)
                próximo à afirmação, em cada grupo, que descreve melhor a
                maneira que você tem se sentido na{" "}
                <strong>última semana, incluindo hoje.</strong> Se várias
                afirmações num grupo parecerem se aplicar igualmente bem, faça
                um círculo em cada uma.{" "}
                <strong>
                  Tome cuidado de ler todas as afirmações, em cada grupo, antes
                  de fazer sua escolha.{" "}
                </strong>
              </FormLabelIntro>
            </FormGroupIntro>

            <FormGroupIntro>
              <FormLabelIntro>Nº de CPF :</FormLabelIntro>
              <FormInput
                type="text"
                value={id}
                maxLength="14"
                onChange={handleCPFChange}
                customWidth="20vw"
                style={isValid ? {} : { borderColor: "red" }}
              />
              {!isValid && <p style={{ color: "red" }}>CPF inválido!</p>}
            </FormGroupIntro>

            <FormGroup>
              <FormLabel>Bloco 1</FormLabel>
              <FormRadioGroup>
                {[0, 1, 2, 3].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q1a"
                      value={value.toString()}
                      onChange={(e) => setQ1a(e.target.value)}
                    />
                    <FormLabel>{getLabelForValue(value)}</FormLabel>
                  </div>
                ))}
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Bloco 2</FormLabel>
              <FormRadioGroup>
                {[0, 1, 2, 3].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q2a"
                      value={value.toString()}
                      onChange={(e) => setQ2a(e.target.value)}
                    />
                    <FormLabel>{getLabelForValue2(value)}</FormLabel>
                  </div>
                ))}
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Bloco 3</FormLabel>
              <FormRadioGroup>
                {[0, 1, 2, 3].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q3a"
                      value={value.toString()}
                      onChange={(e) => setQ3a(e.target.value)}
                    />
                    <FormLabel>{getLabelForValue3(value)}</FormLabel>
                  </div>
                ))}
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Bloco 4</FormLabel>
              <FormRadioGroup>
                {[0, 1, 2, 3].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q4a"
                      value={value.toString()}
                      onChange={(e) => setQ4a(e.target.value)}
                    />
                    <FormLabel>{getLabelForValue4(value)}</FormLabel>
                  </div>
                ))}
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Bloco 5</FormLabel>
              <FormRadioGroup>
                {[0, 1, 2, 3].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q5a"
                      value={value.toString()}
                      onChange={(e) => setQ5a(e.target.value)}
                    />
                    <FormLabel>{getLabelForValue5(value)}</FormLabel>
                  </div>
                ))}
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Bloco 6</FormLabel>
              <FormRadioGroup>
                {[0, 1, 2, 3].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q6a"
                      value={value.toString()}
                      onChange={(e) => setQ6a(e.target.value)}
                    />
                    <FormLabel>{getLabelForValue6(value)}</FormLabel>
                  </div>
                ))}
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Bloco 7</FormLabel>
              <FormRadioGroup>
                {[0, 1, 2, 3].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q7a"
                      value={value.toString()}
                      onChange={(e) => setQ7a(e.target.value)}
                    />
                    <FormLabel>{getLabelForValue7(value)}</FormLabel>
                  </div>
                ))}
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Bloco 8</FormLabel>
              <FormRadioGroup>
                {[0, 1, 2, 3].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q8a"
                      value={value.toString()}
                      onChange={(e) => setQ8a(e.target.value)}
                    />
                    <FormLabel>{getLabelForValue8(value)}</FormLabel>
                  </div>
                ))}
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Bloco 9</FormLabel>
              <FormRadioGroup>
                {[0, 1, 2, 3].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q9a"
                      value={value.toString()}
                      onChange={(e) => setQ9a(e.target.value)}
                    />
                    <FormLabel>{getLabelForValue9(value)}</FormLabel>
                  </div>
                ))}
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Bloco 10</FormLabel>
              <FormRadioGroup>
                {[0, 1, 2, 3].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q10a"
                      value={value.toString()}
                      onChange={(e) => setQ10a(e.target.value)}
                    />
                    <FormLabel>{getLabelForValue10(value)}</FormLabel>
                  </div>
                ))}
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Bloco 11</FormLabel>
              <FormRadioGroup>
                {[0, 1, 2, 3].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q11a"
                      value={value.toString()}
                      onChange={(e) => setQ11a(e.target.value)}
                    />
                    <FormLabel>{getLabelForValue11(value)}</FormLabel>
                  </div>
                ))}
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Bloco 12</FormLabel>
              <FormRadioGroup>
                {[0, 1, 2, 3].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q12a"
                      value={value.toString()}
                      onChange={(e) => setQ12a(e.target.value)}
                    />
                    <FormLabel>{getLabelForValue12(value)}</FormLabel>
                  </div>
                ))}
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Bloco 13</FormLabel>
              <FormRadioGroup>
                {[0, 1, 2, 3].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q13a"
                      value={value.toString()}
                      onChange={(e) => setQ13a(e.target.value)}
                    />
                    <FormLabel>{getLabelForValue13(value)}</FormLabel>
                  </div>
                ))}
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Bloco 14</FormLabel>
              <FormRadioGroup>
                {[0, 1, 2, 3].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q14a"
                      value={value.toString()}
                      onChange={(e) => setQ14a(e.target.value)}
                    />
                    <FormLabel>{getLabelForValue14(value)}</FormLabel>
                  </div>
                ))}
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Bloco 15</FormLabel>
              <FormRadioGroup>
                {[0, 1, 2, 3].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q15a"
                      value={value.toString()}
                      onChange={(e) => setQ15a(e.target.value)}
                    />
                    <FormLabel>{getLabelForValue15(value)}</FormLabel>
                  </div>
                ))}
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Bloco 16</FormLabel>
              <FormRadioGroup>
                {[0, 1, 2, 3].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q16a"
                      value={value.toString()}
                      onChange={(e) => setQ16a(e.target.value)}
                    />
                    <FormLabel>{getLabelForValue16(value)}</FormLabel>
                  </div>
                ))}
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Bloco 17</FormLabel>
              <FormRadioGroup>
                {[0, 1, 2, 3].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q17a"
                      value={value.toString()}
                      onChange={(e) => setQ17a(e.target.value)}
                    />
                    <FormLabel>{getLabelForValue17(value)}</FormLabel>
                  </div>
                ))}
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Bloco 18</FormLabel>
              <FormRadioGroup>
                {[0, 1, 2, 3].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q18a"
                      value={value.toString()}
                      onChange={(e) => setQ18a(e.target.value)}
                    />
                    <FormLabel>{getLabelForValue18(value)}</FormLabel>
                  </div>
                ))}
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Bloco 19</FormLabel>
              <FormRadioGroup>
                {[0, 1, 2, 3].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q19a"
                      value={value.toString()}
                      onChange={(e) => setQ19a(e.target.value)}
                    />
                    <FormLabel>{getLabelForValue19(value)}</FormLabel>
                  </div>
                ))}
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Bloco 20</FormLabel>
              <FormRadioGroup>
                {[0, 1, 2, 3].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q20a"
                      value={value.toString()}
                      onChange={(e) => setQ20a(e.target.value)}
                    />
                    <FormLabel>{getLabelForValue20(value)}</FormLabel>
                  </div>
                ))}
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Bloco 21</FormLabel>
              <FormRadioGroup>
                {[0, 1, 2, 3].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q21a"
                      value={value.toString()}
                      onChange={(e) => setQ21a(e.target.value)}
                    />
                    <FormLabel>{getLabelForValue21(value)}</FormLabel>
                  </div>
                ))}
              </FormRadioGroup>
            </FormGroup>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 10,
                marginRight: 20,
                marginTop: 30,
                marginBottom: 40,
              }}
            >
              <ButtonReturn onClick={handleProfileClick} />
              <ButtonSend onClick={handleBurnoutCalculation} />
            </div>
          </form>
        </FormContainer>
        <div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      </Container>
    </>
  );
};

export default Form100002;
