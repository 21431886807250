import styled from "styled-components";

export const FormGroupIntro = styled.div`
  margin-bottom: 20px;
`;

export const FormLabelIntro = styled.label`
  font-size: 1rem;
  margin-bottom: 10px;
  display: block;
  text-align: justify;
`;

export const FormGroup = styled.div`
  border-bottom: 1px solid;
  
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 15vh;

`;

export const FormLabel = styled.label`
  font-size: 14px;
  margin-bottom: 10px;
  display: block;
  text-align: justify;
  margin-left: 20px;
  min-width: 12vw;
  max-width: 12vw;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FormRadio = styled.input`
  font-size: 1.7rem;
  display: flex;
  margin: 0 10px;
`;

export const FormRadioGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8vw;
  font-size: 1rem;
  text-align: center;
`;
