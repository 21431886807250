import styled from "styled-components";

export const MainContent = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 90vh;
  padding-left: 50px;
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  width: 60.91vw;
`;

export const Card = styled.div`
  background-color: #f4f4f5;
  border-radius: 5px;
  border: 1px solid #d4d4d8;
  cursor: pointer;
  width: 16vw;
  height: 17vh;
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  gap: 10px;

  &:hover {
    background-color: #ffffff;
    border: 1px solid #1f1087;
    transform: scale(1.05);
    color: #1f1087;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const ContentTextCard = styled.div`
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const CardText = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #263238;
`;

export const TextAcess = styled.p`
  font-size: 14px;
  font-weight: 400;
  border-bottom: 1px solid #a1a1aa;
  width: 90px;
`;

export const Avatar = styled.div`
  padding: 1px;
  background-color: #d9d9d9;
  width: 51px;
  height: 51px;
  border-radius: 50px;
`;

export const WelcomeText = styled.p`
  font-weight: 700;
  font-size: 42px;
  color: #2614a0;
  margin-bottom: 40px;
`;

export const CardImage = styled.img`
  height: auto;
  border-radius: 50px;
  width: 51px;
  height: 51px;
  background-color: white;
`;
