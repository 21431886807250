import React, { useState } from "react";
import styled from "styled-components";
import supabase from "../../../Supabase";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import {
  Container,
  FormContainer,
  FormHeader,
  FormInput,
  DivInputs,
} from "../../../Components/styles-all";
import FormLabel from "../../../Components/form-label";
import FormGroup from "../../../Components/form-group";

import HeaderGeral from "../../../Components/headerGeral";
import ButtonReturn from "../../../Components/button-return";
import ButtonSend from "../../../Components/button-send";

const SummaryInput = styled.textarea`
  font-size: 1.2rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "auto"};
  text-align: justify;
  resize: vertical;
`;

const EscreverPaper = () => {
  const [title, setTitle] = useState("");
  const [authors, setAuthors] = useState("");
  const [summary, setSummary] = useState("");
  const [keywords, setKeywords] = useState("");
  const [file, setFile] = useState(null);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (file) {
        const fileData = new FormData();
        fileData.append("file", file);

        const { data: fileUploadResponse, error: fileUploadError } =
          await supabase.storage
            .from("paper")
            .upload(`papers/${file.name}`, fileData, {
              cacheControl: "3600",
              upsert: false,
            });

        if (fileUploadError) {
          if (fileUploadError.response?.status === 400()) {
            toast.error("Esse arquivo ja foi inserido");
          } else {
            toast.error("Não foi possível inserir o arquivo.");
          }
          return;
        }

        const fileUrl = supabase.storage
          .from("paper")
          .getPublicUrl(`papers/${file.name}`);

        const { data: insertedData, error: insertError } = await supabase
          .from("paper")
          .upsert(
            [
              {
                title,
                authors,
                summary,
                keywords,
                file_url: fileUrl,
              },
            ],
            { returning: "full" }
          );

        if (insertError) {
          return;
        }

        toast.success("Dados inseridos com sucesso");
      } else {
        toast.error("Nenhum arquivo foi enviado");
      }
    } catch (error) {
      if (error.response?.status === 400) {
        toast.error("Esse arquivo ja foi inserido");
      } else {
        toast.error("Não foi possível inserir o arquivo.");
      }
      toast.error("Erro desconhecido");
    }
  };

  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate("/plataforma/");
  };

  return (
    <>
      <HeaderGeral />
      <Container>
        <FormContainer>
          <FormHeader>
            <h2>Escreva seu artigo aqui.</h2>
          </FormHeader>
          <form onSubmit={handleSubmit}>
            <DivInputs>
              <FormGroup>
                <FormLabel>Título</FormLabel>
                <FormInput
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  customWidth="32vw"
                />
              </FormGroup>

              <FormGroup>
                <FormLabel>Autores</FormLabel>
                <FormInput
                  type="text"
                  value={authors}
                  onChange={(e) => setAuthors(e.target.value)}
                  customWidth="42vw"
                />
              </FormGroup>
            </DivInputs>

            <FormGroup>
              <FormLabel>Resumo</FormLabel>
              <SummaryInput
                value={summary}
                onChange={(e) => setSummary(e.target.value)}
                width="100%"
                height="27.04vh"
              />
            </FormGroup>

            <DivInputs>
              <FormGroup>
                <FormLabel>Palavras-chave</FormLabel>
                <FormInput
                  type="text"
                  value={keywords}
                  onChange={(e) => setKeywords(e.target.value)}
                  customWidth="22vw"
                />
              </FormGroup>

              <FormGroup>
                <FormLabel>Upload do Arquivo</FormLabel>
                <FormInput
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  customWidth="22vw"
                />
              </FormGroup>
            </DivInputs>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 10,
                marginRight: 20,
              }}
            >
              <ButtonReturn onClick={handleProfileClick} />

              <ButtonSend onClick={handleSubmit} />
            </div>
          </form>
        </FormContainer>

        <div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      </Container>
    </>
  );
};

export default EscreverPaper;
