import React, { useState } from "react";
import { Page, Text, Document, Image } from "@react-pdf/renderer";
import inlearning from "../../../Images/inlearning.jpg";
import sias3 from "../../../Images/sias3.png";

export const MyDocument = ({ data }) => {
  const [searchResults, setSearchResults] = useState(data);

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    fontSize: "10px",
    border: "1px solid #000",
  };

  const tableHeaderStyle = {
    backgroundColor: "#808080",
    padding: "10px",
    textAlign: "left",
    fontSize: "10px",
  };

  const tableCellStyle = {
    padding: "6px",
    border: "1px solid #000",
    fontSize: "10px",
    backgroundColor: "#fff",
  };

  return (
    <Document>
      <Page size="A4" style={{ padding: 30 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image source={inlearning} style={{ width: 150, height: 100 }} />
          <Image source={sias3} style={{ width: 150, height: 95 }} />
        </div>

        {searchResults.Form2 && searchResults.Form2.length > 0 && (
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={tableHeaderStyle}>
                  <Text>DADOS BÁSICOS</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>Nome:</Text>
                    <Text>{searchResults.Form2[0].name}</Text>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>Email:</Text>
                    <Text>{searchResults.Form2[0].email}</Text>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        {searchResults.Form2000 && searchResults.Form2000.length > 0 && (
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={tableHeaderStyle}>
                  <Text>DADOS BÁSICOS</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>Nome:</Text>
                    <Text>{searchResults.Form20000[0].name}</Text>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>Email:</Text>
                    <Text>{searchResults.Form2000[0].email}</Text>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        {searchResults.Form3 &&
          searchResults.Form3.map((form3Data, index) => (
            <table key={index} style={tableStyle}>
              <thead>
                <tr>
                  <th style={tableHeaderStyle}>
                    <Text>CARACTERIZAÇÃO DOS HÁBITOS DE VIDA</Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={tableCellStyle}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ marginRight: "8px" }}>Fumante:</Text>
                      <Text>{form3Data.q1}</Text>
                    </div>
                  </td>
                  <td style={tableCellStyle}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ marginRight: "8px" }}>
                        Consumo de álcool:
                      </Text>
                      <Text>{form3Data.q2}</Text>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={tableCellStyle}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ marginRight: "8px" }}>
                        Alimentação saudável:
                      </Text>
                      <Text>{form3Data.q3}</Text>
                    </div>
                  </td>
                  <td style={tableCellStyle}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ marginRight: "8px" }}>
                        Dificuldades para dormir:
                      </Text>
                      <Text>{form3Data.q4}</Text>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={tableCellStyle}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ marginRight: "8px" }}>
                        Meio de transporte utilizado para ir ao trabalho:
                      </Text>
                      <Text>{form3Data.q5}</Text>
                    </div>
                  </td>
                  <td style={tableCellStyle}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ marginRight: "8px" }}>
                        Possui moradia própria:
                      </Text>
                      <Text>{form3Data.q6}</Text>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={tableCellStyle}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ marginRight: "8px" }}>
                        Sua residência fica em qual zona da cidade:
                      </Text>
                      <Text>{form3Data.q7}</Text>
                    </div>
                  </td>
                  <td style={tableCellStyle}></td>
                </tr>
              </tbody>
            </table>
          ))}

        {searchResults.Form21 && searchResults.Form21.length > 0 && (
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={tableHeaderStyle} colSpan="2">
                  <Text>
                    AVALIAÇÃO DA QUALIDADE DE VIDA RELACIONADA À SAÚDE
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>Domínio Físico:</Text>
                    <Text>
                      {searchResults.Form21[0].physicalDomainClassification}
                    </Text>
                  </div>
                </td>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>
                      Domínio Psicológico:
                    </Text>
                    <Text>
                      {
                        searchResults.Form21[0]
                          .psychologicalDomainClassification
                      }
                    </Text>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>
                      Relações Sociais:
                    </Text>
                    <Text>
                      {
                        searchResults.Form21[0]
                          .socialRelationsDomainClassification
                      }
                    </Text>
                  </div>
                </td>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>Meio Ambiente:</Text>
                    <Text>
                      {searchResults.Form21[0].environmentDomainClassification}
                    </Text>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        {searchResults.Form12 && searchResults.Form12.length > 0 && (
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={tableHeaderStyle} colSpan="2">
                  <Text>ESCALA DE ESTRESSE NO TRABALHO</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>Média Geral:</Text>
                    <Text>
                      {searchResults.Form12[0].nivelDeEstresseMediaGeral}
                    </Text>
                  </div>
                </td>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>
                      Papéis e Ambiente de Trabalho:
                    </Text>
                    <Text>
                      {
                        searchResults.Form12[0]
                          .nivelDeEstresseRoleWorkEnvironment
                      }
                    </Text>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>
                      Autonomia e Controle:
                    </Text>
                    <Text>
                      {searchResults.Form12[0].nivelDeEstresseAutonomyControl}
                    </Text>
                  </div>
                </td>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>
                      Relacionamento com o Chefe:
                    </Text>
                    <Text>
                      {
                        searchResults.Form12[0]
                          .nivelDeEstresseRelationshipWithSupervisor
                      }
                    </Text>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>
                      Relacionamentos Interpessoais:
                    </Text>
                    <Text>
                      {
                        searchResults.Form12[0]
                          .nivelDeEstresseRelacionamentosInterpessoais
                      }
                    </Text>
                  </div>
                </td>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>
                      Crescimento e Valorização:
                    </Text>
                    <Text>
                      {
                        searchResults.Form12[0]
                          .nivelDeEstresseCrescimentoValoração
                      }
                    </Text>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        {searchResults.Form13 && searchResults.Form13.length > 0 && (
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={tableHeaderStyle} colSpan="2">
                  <Text>AVALIAÇÃO DO ESGOTAMENTO PROFISSIONAL</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>
                      Classificação Burnout:
                    </Text>
                    <Text>{searchResults.Form13[0].burnoutClassification}</Text>
                  </div>
                </td>
                <td style={tableCellStyle}></td>
              </tr>
            </tbody>
          </table>
        )}

        {searchResults.Form14 && searchResults.Form14.length > 0 && (
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={tableHeaderStyle} colSpan="2">
                  <Text>VARIAVEIS POR FATOR DE BURNOUT</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>
                      Exaustão Emocional:
                    </Text>
                    <Text>{searchResults.Form14[0].resultado1}</Text>
                  </div>
                </td>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>
                      Despersonalização:
                    </Text>
                    <Text>{searchResults.Form14[0].resultado2}</Text>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>
                      Realização Pessoal:
                    </Text>
                    <Text>{searchResults.Form14[0].resultado3}</Text>
                  </div>
                </td>
                <td style={tableCellStyle}></td>
              </tr>
            </tbody>
          </table>
        )}

        {searchResults.Form6 && searchResults.Form6.length > 0 && (
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={tableHeaderStyle} colSpan="2">
                  <Text>NÍVEL DE ATIVIDADE FÍSICA</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>Classificação:</Text>
                    <Text>{searchResults.Form6[0].classification}</Text>
                  </div>
                </td>
                <td style={tableCellStyle}></td>
              </tr>
            </tbody>
          </table>
        )}

        {searchResults.Form25 && searchResults.Form25.length > 0 && (
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={tableHeaderStyle} colSpan="2">
                  <Text>ESCALA DE DEPRESSÃO</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>Resultado:</Text>
                    <Text>{searchResults.Form25[0].result}</Text>
                  </div>
                </td>
                <td style={tableCellStyle}></td>
              </tr>
            </tbody>
          </table>
        )}

        {searchResults.Form24 && searchResults.Form24.length > 0 && (
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={tableHeaderStyle} colSpan="2">
                  <Text>ESCALA DE DEPRESSÃO, ANSIEDADE E ESTRESSE</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>
                      Classificação Depressão:
                    </Text>
                    <Text>
                      {searchResults.Form24[0].depressionClassification}
                    </Text>
                  </div>
                </td>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>
                      Classificação Ansiedade:
                    </Text>
                    <Text>{searchResults.Form24[0].anxietyClassification}</Text>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>
                      Classificação Estresse:
                    </Text>
                    <Text>{searchResults.Form24[0].stressClassification}</Text>
                  </div>
                </td>
                <td style={tableCellStyle}></td>
              </tr>
            </tbody>
          </table>
        )}

        {searchResults.Form16 && searchResults.Form16.length > 0 && (
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={tableHeaderStyle} colSpan="2">
                  <Text>ÍNDICE DE MASSA CORPORAL</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>IMC:</Text>
                    <Text>{searchResults.Form16[0].bmiClassification}</Text>
                  </div>
                </td>
                <td style={tableCellStyle}></td>
              </tr>
            </tbody>
          </table>
        )}

        {searchResults.Form17 && searchResults.Form17.length > 0 && (
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={tableHeaderStyle} colSpan="2">
                  <Text>ÍNDICE RELAÇÃO CINTURA/QUADRIL</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>IRCQ:</Text>
                    <Text>
                      {searchResults.Form17[0].riscoCircunferenciaCintura}
                    </Text>
                  </div>
                </td>
                <td style={tableCellStyle}></td>
              </tr>
            </tbody>
          </table>
        )}

        {searchResults.Form18 && searchResults.Form18.length > 0 && (
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={tableHeaderStyle} colSpan="2">
                  <Text>ÍNDICE DE MASSA CORPORAL</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>
                      Percentual de Gordura:
                    </Text>
                    <Text>{searchResults.Form18[0].classificacao}</Text>
                  </div>
                </td>
                <td style={tableCellStyle}></td>
              </tr>
            </tbody>
          </table>
        )}

        {searchResults.Form19 && searchResults.Form19.length > 0 && (
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={tableHeaderStyle} colSpan="2">
                  <Text>EXAME SÍNDROME METABÓLICA</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>
                      Síndrome Metabólica:
                    </Text>
                    <Text>{searchResults.Form19[0].message}</Text>
                  </div>
                </td>
                <td style={tableCellStyle}></td>
              </tr>
            </tbody>
          </table>
        )}

        {searchResults.Form22 && searchResults.Form22.length > 0 && (
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={tableHeaderStyle} colSpan="2">
                  <Text>AUTOMEDICAÇÃO</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>
                      Ingeriu medicamentos no último ano?
                    </Text>
                    <Text>{searchResults.Form22[0].ingeriuMedicamentos}</Text>
                  </div>
                </td>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>
                      Todos prescritos pelo médico?
                    </Text>
                    <Text>{searchResults.Form22[0].todosPrescritos}</Text>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        {searchResults.Form20 && searchResults.Form20.length > 0 && (
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={tableHeaderStyle} colSpan="2">
                  <Text>EXAMES LABORATORIAIS</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>Glicemia:</Text>
                    <Text>{searchResults.Form20[0].diagnósticoGlicemia}</Text>
                  </div>
                </td>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>LDL:</Text>
                    <Text>{searchResults.Form20[0].diagnósticoLDL}</Text>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>VLDL:</Text>
                    <Text>{searchResults.Form20[0].diagnósticoVLDL}</Text>
                  </div>
                </td>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>Ferritina:</Text>
                    <Text>{searchResults.Form20[0].diagnósticoFerritina}</Text>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>Creatinina:</Text>
                    <Text>{searchResults.Form20[0].diagnósticoCreatinina}</Text>
                  </div>
                </td>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>Ureia:</Text>
                    <Text>{searchResults.Form20[0].diagnósticoUreia}</Text>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>ALT:</Text>
                    <Text>{searchResults.Form20[0].diagnósticoALT}</Text>
                  </div>
                </td>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>AST:</Text>
                    <Text>{searchResults.Form20[0].diagnósticoAST}</Text>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>
                      Colesterol Total:
                    </Text>
                    <Text>
                      {searchResults.Form20[0].diagnósticoColesterolTotal}
                    </Text>
                  </div>
                </td>
                <td style={tableCellStyle}></td>
              </tr>
            </tbody>
          </table>
        )}

        {searchResults.FormHemograma &&
          searchResults.FormHemograma.length > 0 && (
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th style={tableHeaderStyle} colSpan="2">
                    <Text>HEMOGRAMA</Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={tableCellStyle}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ marginRight: "8px" }}>Hemoglobina:</Text>
                      <Text>
                        {searchResults.FormHemograma[0].diagnósticoHemoglobina}
                      </Text>
                    </div>
                  </td>
                  <td style={tableCellStyle}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ marginRight: "8px" }}>Hematócrito:</Text>
                      <Text>
                        {searchResults.FormHemograma[0].diagnósticoHematócrito}
                      </Text>
                    </div>
                  </td>
                  <td style={tableCellStyle}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ marginRight: "8px" }}>
                        Volume celular (ou corpuscular) médico (VCM):
                      </Text>
                      <Text>
                        {searchResults.FormHemograma[0].diagnósticoVCM}
                      </Text>
                    </div>
                  </td>
                  <td style={tableCellStyle}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ marginRight: "8px" }}>
                        Hemoglobina celular (ou corpuscular) média (HCM):
                      </Text>
                      <Text>
                        {searchResults.FormHemograma[0].diagnósticoHCM}
                      </Text>
                    </div>
                  </td>
                  <td style={tableCellStyle}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ marginRight: "8px" }}>
                        Concentração de hemoglobina celular (ou corpuscular)
                        média (CHCM):
                      </Text>
                      <Text>
                        {searchResults.FormHemograma[0].diagnósticoCHCM}
                      </Text>
                    </div>
                  </td>
                  <td style={tableCellStyle}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ marginRight: "8px" }}>
                        Contagem de glóbulos vermelhos (eritrócitos):
                      </Text>
                      <Text>
                        {searchResults.FormHemograma[0].diagnósticoEritrócitos}
                      </Text>
                    </div>
                  </td>
                  <td style={tableCellStyle}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ marginRight: "8px" }}>
                        Amplitude de distribuição de eritrócitos:
                      </Text>
                      <Text>
                        {searchResults.FormHemograma[0].diagnósticoAmplitude}
                      </Text>
                    </div>
                  </td>
                  <td style={tableCellStyle}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ marginRight: "8px" }}>
                        Contagem de glóbulos brancos:
                      </Text>
                      <Text>
                        {searchResults.FormHemograma[0].diagnósticoContagem}
                      </Text>
                    </div>
                  </td>
                  <td style={tableCellStyle}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ marginRight: "8px" }}>
                        Contagem de Neutrófilos segmentados:
                      </Text>
                      <Text>
                        {searchResults.FormHemograma[0].diagnósticoNeutrófilos}
                      </Text>
                    </div>
                  </td>
                  <td style={tableCellStyle}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ marginRight: "8px" }}>
                        Contagem de Linfócitos:
                      </Text>
                      <Text>
                        {searchResults.FormHemograma[0].diagnósticoLinfócitos}
                      </Text>
                    </div>
                  </td>
                  <td style={tableCellStyle}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ marginRight: "8px" }}>
                        Contagem de Monócitos:
                      </Text>
                      <Text>
                        {searchResults.FormHemograma[0].diagnósticoMonócitos}
                      </Text>
                    </div>
                  </td>
                  <td style={tableCellStyle}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ marginRight: "8px" }}>
                        Contagem de Eosinófilos:
                      </Text>
                      <Text>
                        {searchResults.FormHemograma[0].diagnósticoEosinófilos}
                      </Text>
                    </div>
                  </td>
                  <td style={tableCellStyle}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ marginRight: "8px" }}>
                        Contagem de Basófilos:
                      </Text>
                      <Text>
                        {searchResults.FormHemograma[0].diagnósticoBasófilos}
                      </Text>
                    </div>
                  </td>
                  <td style={tableCellStyle}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ marginRight: "8px" }}>
                        Contagem de Plaquetas:
                      </Text>
                      <Text>
                        {searchResults.FormHemograma[0].diagnósticoPlaquetas}
                      </Text>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          )}

        {searchResults.Form1000 && searchResults.Form1000.length > 0 && (
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={tableHeaderStyle} colSpan="2">
                  <Text>DIAGNÓSTICO MÉDICO</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>Diagnóstico:</Text>
                    <Text>{searchResults.Form1000[0].diagnosis}</Text>
                  </div>
                </td>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>Intervenção:</Text>
                    <Text>{searchResults.Form1000[0].summary}</Text>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        {searchResults.Form1001 && searchResults.Form1001.length > 0 && (
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={tableHeaderStyle} colSpan="2">
                  <Text>DIAGNÓSTICO NUTRICIONISTA</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>Diagnóstico:</Text>
                    <Text>{searchResults.Form1001[0].diagnosis}</Text>
                  </div>
                </td>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>Intervenção:</Text>
                    <Text>{searchResults.Form1001[0].summary}</Text>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        {searchResults.Form1002 && searchResults.Form1002.length > 0 && (
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={tableHeaderStyle} colSpan="2">
                  <Text>DIAGNÓSTICO PSICOLOGO</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>Diagnóstico:</Text>
                    <Text>{searchResults.Form1002[0].diagnosis}</Text>
                  </div>
                </td>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>Intervenção:</Text>
                    <Text>{searchResults.Form1002[0].summary}</Text>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        {searchResults.Form1003 && searchResults.Form1003.length > 0 && (
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={tableHeaderStyle} colSpan="2">
                  <Text>DIAGNONÓSTICO PROFISSIONAL EDUCAÇÃO FÍSICA</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>Diagnóstico:</Text>
                    <Text>{searchResults.Form1003[0].diagnosis}</Text>
                  </div>
                </td>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>Intervenção:</Text>
                    <Text>{searchResults.Form1003[0].summary}</Text>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        {searchResults.Form11 && searchResults.Form11.length > 0 && (
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={tableHeaderStyle} colSpan="2">
                  <Text>DIAGNÓSTICO GERAL SAUDE</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>Diagnóstico:</Text>
                    <Text>{searchResults.Form11[0].diagnosis}</Text>
                  </div>
                </td>
                <td style={tableCellStyle}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ marginRight: "8px" }}>Intervenção:</Text>
                    <Text>{searchResults.Form11[0].summary}</Text>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </Page>
    </Document>
  );
};
