import styled from "styled-components";

export const StyledButton = styled.button`
  background-color: #4caf50;
  margin-bottom: 10px;
  color: white;
  border-radius: 5px;
  border: none;
  width: 9.12vw;
  height: 3.89vh;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    transform: scale(1.09);
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 1rem;
  border: 1px solid #000;
`;

export const TableHeader = styled.th`
  background-color: #808080;
  padding: 10px;
  text-align: left;
  font-size: 1rem;
  text-align: justify;
`;

export const TableCell = styled.td`
  padding: 6px;
  border: 1px solid #000;
  font-size: 1rem;
  background-color: #fff;
`;
