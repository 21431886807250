import React, { useState } from "react";
import supabase from "../../../Supabase";
import { useNavigate } from "react-router-dom";
import HeaderGeral from "../../../Components/headerGeral";

import { ToastContainer, toast } from "react-toastify";
import { SuccessToast, ErrorToast } from "../../../Components/toastyfy";
import {
  Container,
  FormContainer,
  FormHeader,
  FormInput,
} from "../../../Components/styles-all";

import FormGroup from "../../../Components/form-group";
import FormLabel from "../../../Components/form-label";
import ButtonReturn from "../../../Components/button-return";
import ButtonSend from "../../../Components/button-send";
import FormRadio from "../../../Components/form-radio";

const Form22 = () => {
  const [ingeriuMedicamentos, setIngeriuMedicamentos] = useState(null);
  const [todosPrescritos, setTodosPrescritos] = useState(null);
  const [recorreuAutomedicacao, setRecorreuAutomedicacao] = useState(null);
  const [motivosAutomedicacao, setMotivosAutomedicacao] = useState([]);
  const [duracaoTomaMedicamento, setDuracaoTomaMedicamento] = useState(null);
  const [procurouInformacoes, setProcurouInformacoes] = useState([]);
  const [localInformacoes, setLocalInformacoes] = useState([]);
  const [infoCompreendida, setInfoCompreendida] = useState(null);
  const [cumpriuInformacoes, setCumpriuInformacoes] = useState(null);
  const [medicamentosMedico, setMedicamentosMedico] = useState(null);
  const [quantosMedicamentos, setQuantosMedicamentos] = useState(null);
  const [verificouIncompatibilidades, setVerificouIncompatibilidades] =
    useState(null);
  const [pedeInfoFarmaceutico, setPedeInfoFarmaceutico] = useState(null);
  const [conhecimentoRiscos, setConhecimentoRiscos] = useState(null);
  const [problemaMedicacao, setProblemaMedicacao] = useState(null);
  const [id, setId] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleCPFChange = (e) => {
    const cpf = formatCPF(e.target.value);
    setId(cpf);

    const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    setIsValid(regex.test(cpf));
  };

  const formatCPF = (cpf) => {
    let value = cpf.replace(/\D/g, "");
    if (value.length > 3) value = `${value.slice(0, 3)}.${value.slice(3)}`;
    if (value.length > 7) value = `${value.slice(0, 7)}.${value.slice(7)}`;
    if (value.length > 11)
      value = `${value.slice(0, 11)}-${value.slice(11, 13)}`;
    return value;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      id,
      ingeriuMedicamentos,
      todosPrescritos,
      recorreuAutomedicacao,
      motivosAutomedicacao,
      duracaoTomaMedicamento,
      procurouInformacoes,
      localInformacoes,
      infoCompreendida,
      cumpriuInformacoes,
      medicamentosMedico,
      quantosMedicamentos,
      verificouIncompatibilidades,
      pedeInfoFarmaceutico,
      conhecimentoRiscos,
      problemaMedicacao,
    };

    const { error } = await supabase.from("automedicação").insert([data]);
    if (error) {
      toast.error(
        <ErrorToast>
          <strong>Erro!</strong> Erro ao inserir os dados.
        </ErrorToast>
      );
    } else {
      toast.success(
        <SuccessToast>
          <strong>Sucesso!</strong> Dados inseridos com êxito.
        </SuccessToast>
      );
    }
  };

  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate("/plataforma/Saude-e-qualidade-de-vida");
  };

  return (
    <>
      <HeaderGeral />
      <Container>
        <FormContainer>
          <FormHeader>
            <h2>AUTOMEDICAÇÃO</h2>
          </FormHeader>

          <form onSubmit={handleSubmit}>
            <FormGroup>
              <FormLabel>Nº de CPF :</FormLabel>
              <FormInput
                type="text"
                value={id}
                maxLength="14"
                onChange={handleCPFChange}
                style={isValid ? {} : { borderColor: "red" }}
                customWidth="21.87vw"
              />
              {!isValid && <p style={{ color: "red" }}>CPF inválido!</p>}
            </FormGroup>

            <FormGroup>
              <FormLabel>Ingeriu medicamentos no último ano?</FormLabel>
              <FormRadio
                name="ingeriuMedicamentos"
                value="sim"
                checked={ingeriuMedicamentos === "sim"}
                onChange={() => setIngeriuMedicamentos("sim")}
                label="Sim"
              />

              <FormRadio
                name="ingeriuMedicamentos"
                value="não"
                checked={ingeriuMedicamentos === "não"}
                onChange={() => setIngeriuMedicamentos("não")}
                label="Não"
              />
            </FormGroup>

            {ingeriuMedicamentos === "sim" && (
              <FormGroup>
                <FormLabel>
                  Os medicamentos usados foram todos prescritos pelo médico?
                </FormLabel>
                <FormRadio
                  name="todosPrescritos"
                  value="sim"
                  checked={todosPrescritos === "sim"}
                  onChange={() => setTodosPrescritos("sim")}
                  label="Sim"
                />

                <FormRadio
                  name="todosPrescritos"
                  value="não"
                  checked={todosPrescritos === "não"}
                  onChange={() => setTodosPrescritos("não")}
                  label="Não"
                />
              </FormGroup>
            )}

            {todosPrescritos === "não" && (
              <FormGroup>
                <FormLabel>No último ano recorreu à automedicação?</FormLabel>
                <FormRadio
                  name="recorreuAutomedicacao"
                  value="raramente"
                  checked={recorreuAutomedicacao === "raramente"}
                  onChange={() => setRecorreuAutomedicacao("raramente")}
                  label="Raramente"
                />
                <FormRadio
                  name="recorreuAutomedicacao"
                  value="alguma"
                  checked={recorreuAutomedicacao === "alguma"}
                  onChange={() => setRecorreuAutomedicacao("alguma")}
                  label="Alguma Frequência"
                />
                <FormRadio
                  name="recorreuAutomedicacao"
                  value="muita"
                  checked={recorreuAutomedicacao === "muita"}
                  onChange={() => setRecorreuAutomedicacao("muita")}
                  label="Muita Frequência"
                />

                <FormGroup>
                  <FormLabel>
                    Quando se automedicou qual a duração da toma do medicamento?
                  </FormLabel>
                  <FormRadio
                    name="duracaoTomaMedicamento"
                    value="1-2"
                    checked={duracaoTomaMedicamento === "1-2"}
                    onChange={() => setDuracaoTomaMedicamento("1-2")}
                    label="1 a 2 Dias"
                  />
                  <FormRadio
                    name="duracaoTomaMedicamento"
                    value="3-4"
                    checked={duracaoTomaMedicamento === "3-4"}
                    onChange={() => setDuracaoTomaMedicamento("3-4")}
                    label="3 a 4 Dias"
                  />
                  <FormRadio
                    name="duracaoTomaMedicamento"
                    value="5+"
                    checked={duracaoTomaMedicamento === "5+"}
                    onChange={() => setDuracaoTomaMedicamento("5+")}
                    label="5 dias ou mais"
                  />
                </FormGroup>

                <FormGroup>
                  <FormLabel>A automedicação foi influenciada por:</FormLabel>
                  <FormRadio
                    name="motivosAutomedicacao"
                    value="familiar"
                    checked={motivosAutomedicacao === "familiar"}
                    onChange={() => setMotivosAutomedicacao("familiar")}
                    label="Familiar ou Amigo"
                  />
                  <FormRadio
                    name="motivosAutomedicacao"
                    value="profissional"
                    checked={motivosAutomedicacao === "profissional"}
                    onChange={() => setMotivosAutomedicacao("profissional")}
                    label="Profissional de saúde (não médico)"
                  />
                  <FormRadio
                    name="motivosAutomedicacao"
                    value="prescricoes"
                    checked={motivosAutomedicacao === "prescricoes"}
                    onChange={() => setMotivosAutomedicacao("prescricoes")}
                    label="Prescrições anteriores"
                  />
                  <FormRadio
                    name="motivosAutomedicacao"
                    value="publicidade"
                    checked={motivosAutomedicacao === "publicidade"}
                    onChange={() => setMotivosAutomedicacao("publicidade")}
                    label="Publicidade (TV, revistas, Internet)"
                  />
                  <FormRadio
                    name="motivosAutomedicacao"
                    value="casa"
                    checked={motivosAutomedicacao === "casa"}
                    onChange={() => setMotivosAutomedicacao("casa")}
                    label="Tinha em casa sem prescrição médica"
                  />
                </FormGroup>

                <FormGroup>
                  <FormLabel>
                    Antes de se automedicar, procurou informações, ou
                    esclarecimentos adicionais do medicamento?
                  </FormLabel>
                  <FormRadio
                    name="procurouInformacoes"
                    value="sim"
                    checked={procurouInformacoes === "sim"}
                    onChange={() => setProcurouInformacoes("sim")}
                    label="Sim"
                  />
                  <FormRadio
                    name="procurouInformacoes"
                    value="não"
                    checked={procurouInformacoes === "não"}
                    onChange={() => setProcurouInformacoes("não")}
                    label="Não"
                  />
                </FormGroup>

                {procurouInformacoes === "sim" && (
                  <>
                    <FormGroup>
                      <FormLabel>Onde procurou essas informações?</FormLabel>
                      <FormRadio
                        name="localInformacoes"
                        value="bula"
                        checked={localInformacoes === "bula"}
                        onChange={() => setLocalInformacoes("bula")}
                        label="Bula (informação que vem dentro da caixa do medicamento)"
                      />
                      <FormRadio
                        name="localInformacoes"
                        value="enfermeiro"
                        checked={localInformacoes === "enfermeiro"}
                        onChange={() => setLocalInformacoes("enfermeiro")}
                        label="Enfermeiro"
                      />
                      <FormRadio
                        name="localInformacoes"
                        value="farmaceutico"
                        checked={localInformacoes === "farmaceutico"}
                        onChange={() => setLocalInformacoes("farmaceutico")}
                        label="Farmacêutico"
                      />
                      <FormRadio
                        name="localInformacoes"
                        value="parenteOuAmigo"
                        checked={localInformacoes === "parenteOuAmigo"}
                        onChange={() => setLocalInformacoes("parenteOuAmigo")}
                        label="Parente ou Amigo"
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>
                        A informação recebida foi compreendida?
                      </FormLabel>

                      <FormRadio
                        name="infoCompreendida"
                        value="sim"
                        checked={infoCompreendida === "sim"}
                        onChange={() => setInfoCompreendida("sim")}
                        label="Sim"
                      />
                      <FormRadio
                        name="infoCompreendida"
                        value="não"
                        checked={infoCompreendida === "não"}
                        onChange={() => setInfoCompreendida("não")}
                        label="Não"
                      />
                    </FormGroup>

                    <FormGroup>
                      <FormLabel>
                        Cumpriu rigorosamente as informações recebidas?
                      </FormLabel>
                      <FormRadio
                        name="cumpriuInformacoes"
                        value="sim"
                        checked={cumpriuInformacoes === "sim"}
                        onChange={() => setCumpriuInformacoes("sim")}
                        label="Sim"
                      />
                      <FormRadio
                        name="cumpriuInformacoes"
                        value="não"
                        checked={cumpriuInformacoes === "não"}
                        onChange={() => setCumpriuInformacoes("não")}
                        label="Não"
                      />
                    </FormGroup>
                  </>
                )}

                <FormGroup>
                  <FormLabel>
                    Quando se automedicou encontrava-se a tomar medicamentos
                    receitados pelo seu médico?
                  </FormLabel>
                  <FormRadio
                    name="medicamentosMedico"
                    value="sim"
                    checked={medicamentosMedico === "sim"}
                    onChange={() => setMedicamentosMedico("sim")}
                    label="Sim"
                  />
                  <FormRadio
                    name="medicamentosMedico"
                    value="não"
                    checked={medicamentosMedico === "não"}
                    onChange={() => setMedicamentosMedico("não")}
                    label="Não"
                  />
                </FormGroup>

                <FormGroup>
                  <FormLabel>
                    Verificou se existiam algumas incompatibilidades entre os
                    medicamentos que tomou?
                  </FormLabel>
                  <FormRadio
                    name="verificouIncompatibilidades"
                    value="sim"
                    checked={verificouIncompatibilidades === "sim"}
                    onChange={() => setVerificouIncompatibilidades("sim")}
                    label="Sim"
                  />
                  <FormRadio
                    name="verificouIncompatibilidades"
                    value="não"
                    checked={verificouIncompatibilidades === "não"}
                    onChange={() => setVerificouIncompatibilidades("não")}
                    label="Não"
                  />
                </FormGroup>

                <FormGroup>
                  <FormLabel>
                    Quando compra medicamentos pede sempre informações ao
                    farmacêutico acerca do medicamento?
                  </FormLabel>
                  <FormRadio
                    name="pedeInfoFarmaceutico"
                    value="sim"
                    checked={pedeInfoFarmaceutico === "sim"}
                    onChange={() => setPedeInfoFarmaceutico("sim")}
                    label="Sim"
                  />
                  <FormRadio
                    name="pedeInfoFarmaceutico"
                    value="não"
                    checked={pedeInfoFarmaceutico === "não"}
                    onChange={() => setPedeInfoFarmaceutico("não")}
                    label="Não"
                  />
                </FormGroup>

                <FormGroup>
                  <FormLabel>
                    Tem conhecimento dos riscos que o medicamento com que se
                    automedicou poderiam causar?
                  </FormLabel>
                  <FormRadio
                    name="conhecimentoRiscos"
                    value="sim"
                    checked={conhecimentoRiscos === "sim"}
                    onChange={() => setConhecimentoRiscos("sim")}
                    label="Sim"
                  />
                  <FormRadio
                    name="conhecimentoRiscos"
                    value="não"
                    checked={conhecimentoRiscos === "não"}
                    onChange={() => setConhecimentoRiscos("não")}
                    label="Não"
                  />
                </FormGroup>

                <FormGroup>
                  <FormLabel>
                    Surgiu algum problema relacionado com a medicação com que se
                    automedicou?
                  </FormLabel>
                  <FormRadio
                    name="problemaMedicacao"
                    value="sim"
                    checked={problemaMedicacao === "sim"}
                    onChange={() => setProblemaMedicacao("sim")}
                    label="Sim"
                  />
                  <FormRadio
                    name="problemaMedicacao"
                    value="não"
                    checked={problemaMedicacao === "não"}
                    onChange={() => setProblemaMedicacao("não")}
                    label="Não"
                  />
                </FormGroup>
              </FormGroup>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 10,
                marginRight: 20,
              }}
            >
              <ButtonReturn onClick={handleProfileClick} />

              <ButtonSend onClick={handleSubmit} />
            </div>
          </form>
        </FormContainer>

        <div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      </Container>
    </>
  );
};

export default Form22;
