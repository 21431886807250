import React, { useState } from "react";
import styled from "styled-components";
import { PaperPlaneRight } from "@phosphor-icons/react";

const Button = styled.button<{ clicked: boolean }>`
  display: flex;
  flex-direction: row;
  background-color: ${({ clicked }) => (clicked ? "black" : "#2e19bd")};
  color: ${({ clicked }) => (clicked ? "white" : "white")};
  border-radius: 5px;
  border: none;
  width: 9.12vw;
  height: 30px;
  font-size: 15px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  &:hover {
    transform: scale(1.09);
  }

  @media (max-width: 768px) {
    width: 90vw;
    margin-left: 2vw;
    margin-bottom: 50px;
    height: 5vh;
    &:hover {
      transform: scale(1);
    }
  }
`;

interface ButtonSendProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ButtonSend: React.FC<ButtonSendProps> = ({ onClick }) => {
  const [clicked, setClicked] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setClicked(true);
    if (onClick) {
      event.preventDefault(); // Previne o comportamento padrão, se necessário
      onClick(event); // Passa o evento para o callback
    }
  };

  return (
    <Button onClick={handleClick} clicked={clicked}>
      <>Enviar</>
      <PaperPlaneRight size={14} />
    </Button>
  );
};

export default ButtonSend;
