import React, { useEffect, useState } from "react";
import supabase from "../../../../Supabase";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useNavigate } from "react-router-dom";
import {
  FormContainer,
  ChartContainer,
  ResultTitle,
  ToggleButton,
  DivButton,
} from "./styles";
import HeaderGeral from "../../../../Components/headerGeral";

import ButtonReturn from "../../../../Components/button-return";
import { Container } from "../../../../Components/styles-all";

import {
  originalQuestions,
  questionsAndResponses,
  questionsAndResponsesForm2,
  categoryTitles,
  questionsAndResponsesForm21,
  questionsAndResponsesForm21Titles,
  questionsAndResponsesForm12,
  questionsAndResponsesForm12Titles,
  questionsAndResponsesForm13,
  questionsAndResponsesForm13Titles,
  questionsAndResponsesForm14,
  questionsAndResponsesForm14Titles,
  questionsAndResponsesForm6,
  questionsAndResponsesForm6Titles,
  questionsAndResponsesForm25,
  questionsAndResponsesForm25Titles,
  questionsAndResponsesForm24,
  questionsAndResponsesForm24Titles,
  questionsAndResponsesForm16,
  questionsAndResponsesForm16Titles,
  questionsAndResponsesForm17,
  questionsAndResponsesForm17Titles,
  questionsAndResponsesForm18,
  questionsAndResponsesForm18Titles,
  questionsAndResponsesForm19,
  questionsAndResponsesForm19Titles,
  questionsAndResponsesForm20,
  questionsAndResponsesForm20Titles,
  questionsAndResponsesForm202,
  questionsAndResponsesForm202Titles,
  questionsAndResponsesForm203,
  questionsAndResponsesForm203Titles,
  questionsAndResponsesForm100001,
  questionsAndResponsesForm100001Titles,
  questionsAndResponsesForm100002,
  questionsAndResponsesForm100002Titles,
  questionsAndResponsesForm100003,
  questionsAndResponsesForm100003Titles,
} from "./titles";
Chart.register(...registerables);
Chart.register(ChartDataLabels);

const chartOptions = {
  plugins: {
    datalabels: {
      color: "#fff",
      display: function (context) {
        return context.dataset.data[context.dataIndex] !== 0;
      },
      font: {
        weight: "bold",
      },
      formatter: function (value, context) {
        return `${value.toFixed(0)}%`;
      },
    },
  },
  scales: {
    r: {
      min: 0,
      max: 100,
      beginAtZero: true,
      ticks: {
        stepSize: 10,
        callback: function (value) {
          return `${value}%`;
        },
      },
    },
  },
};

const Form36 = () => {
  const [showAll, setShowAll] = useState(false);

  const [resultsForm3, setResultsForm3] = useState([]);
  const [resultsForm2, setResultsForm2] = useState([]);
  const [resultsForm21, setResultsForm21] = useState([]);
  const [resultsForm12, setResultsForm12] = useState([]);
  const [resultsForm13, setResultsForm13] = useState([]);
  const [resultsForm14, setResultsForm14] = useState([]);
  const [resultsForm6, setResultsForm6] = useState([]);
  const [resultsForm25, setResultsForm25] = useState([]);
  const [resultsForm24, setResultsForm24] = useState([]);
  const [resultsForm16, setResultsForm16] = useState([]);
  const [resultsForm17, setResultsForm17] = useState([]);
  const [resultsForm18, setResultsForm18] = useState([]);
  const [resultsForm19, setResultsForm19] = useState([]);
  const [resultsForm20, setResultsForm20] = useState([]);
  const [resultsForm202, setResultsForm202] = useState([]);
  const [resultsForm203, setResultsForm203] = useState([]);
  const [afastamentoCausasData, setAfastamentoCausasData] = useState([]);

  const [resultsForm100001, setResultsForm100001] = useState([]);
  const [resultsForm100002, setResultsForm100002] = useState([]);
  const [resultsForm100003, setResultsForm100003] = useState([]);

  const [activeChart, setActiveChart] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const { data: form2Data, error: form2Error } = await supabase
        .from("sociodemograficoMilitar")
        .select("id, *")
        .eq("corporationType", "Polícia Civil");

      const { data: form3Data, error: form3Error } = await supabase
        .from("caracHabVida")
        .select("id, *")
        .in(
          "id",
          form2Data.map((item) => item.id)
        );
      const { data: form21Data, error: form21Error } = await supabase
        .from("habitosVida")
        .select("id, *")
        .in(
          "id",
          form2Data.map((item) => item.id)
        );
      const { data: form12Data, error: form12Error } = await supabase
        .from("estresseTrabalho")
        .select("id, *")
        .in(
          "id",
          form2Data.map((item) => item.id)
        );
      const { data: form13Data, error: form13Error } = await supabase
        .from("avaliEsgoPro")
        .select("id, *")
        .in(
          "id",
          form2Data.map((item) => item.id)
        );
      const { data: form14Data, error: form14Error } = await supabase
        .from("avaliFatorBurn")
        .select("id, *")
        .in(
          "id",
          form2Data.map((item) => item.id)
        );
      const { data: form6Data, error: form6Error } = await supabase
        .from("nivelAtiviFisica")
        .select("id, *")
        .in(
          "id",
          form2Data.map((item) => item.id)
        );
      const { data: form25Data, error: form25Error } = await supabase
        .from("saudePacientePHQ9")
        .select("id, *")
        .in(
          "id",
          form2Data.map((item) => item.id)
        );
      const { data: form24Data, error: form24Error } = await supabase
        .from("escalaDepreAnsiEstresse")
        .select("id, *")
        .in(
          "id",
          form2Data.map((item) => item.id)
        );
      const { data: form16Data, error: form16Error } = await supabase
        .from("imc")
        .select("id, *")
        .in(
          "id",
          form2Data.map((item) => item.id)
        );
      const { data: form17Data, error: form17Error } = await supabase
        .from("irqc")
        .select("id, *")
        .in(
          "id",
          form2Data.map((item) => item.id)
        );
      const { data: form18Data, error: form18Error } = await supabase
        .from("testePercGordu")
        .select("id, *")
        .in(
          "id",
          form2Data.map((item) => item.id)
        );
      const { data: form19Data, error: form19Error } = await supabase
        .from("exameSindroMeta")
        .select("id, *")
        .in(
          "id",
          form2Data.map((item) => item.id)
        );
      const { data: form20Data, error: form20Error } = await supabase
        .from("examesBioComp")
        .select("id, *")
        .in(
          "id",
          form2Data.map((item) => item.id)
        );
      const { data: form202Data, error: form202Error } = await supabase
        .from("examesBioParc")
        .select("id, *")
        .in(
          "id",
          form2Data.map((item) => item.id)
        );
      const { data: form203Data, error: form203Error } = await supabase
        .from("hemograma")
        .select("id, *")
        .in(
          "id",
          form2Data.map((item) => item.id)
        );

      const { data: form100001Data, error: form10001Error } = await supabase
        .from("ansiedadeBeck")
        .select("*")
        .in(
          "id",
          form2Data.map((item) => item.id)
        );
      const { data: form100002Data, error: form10002Error } = await supabase
        .from("depressaoBeck")
        .select("*")
        .in(
          "id",
          form2Data.map((item) => item.id)
        );
      const { data: form100003Data, error: form10003Error } = await supabase
        .from("sofrimentoMental")
        .select("*")
        .in(
          "id",
          form2Data.map((item) => item.id)
        );

      if (form3Error) {
      } else {
        setResultsForm3(form3Data);
      }
      if (form2Error) {
      } else {
        setResultsForm2(form2Data);
        const afastamentoCausasArray = form2Data
          .map((entry) => entry.afastamentoCausas)
          .flat();
        setAfastamentoCausasData(afastamentoCausasArray);
      }
      if (form21Error) {
      } else {
        setResultsForm21(form21Data);
      }
      if (form12Error) {
      } else {
        setResultsForm12(form12Data);
      }
      if (form13Error) {
      } else {
        setResultsForm13(form13Data);
      }
      if (form14Error) {
      } else {
        setResultsForm14(form14Data);
      }
      if (form6Error) {
      } else {
        setResultsForm6(form6Data);
      }
      if (form25Error) {
      } else {
        setResultsForm25(form25Data);
      }
      if (form24Error) {
      } else {
        setResultsForm24(form24Data);
      }
      if (form16Error) {
      } else {
        setResultsForm16(form16Data);
      }
      if (form17Error) {
      } else {
        setResultsForm17(form17Data);
      }
      if (form18Error) {
      } else {
        setResultsForm18(form18Data);
      }
      if (form19Error) {
      } else {
        setResultsForm19(form19Data);
      }
      if (form20Error) {
      } else {
        setResultsForm20(form20Data);
      }
      if (form202Error) {
      } else {
        setResultsForm202(form202Data);
      }
      if (form203Error) {
      } else {
        setResultsForm203(form203Data);
      }

      if (form10001Error) {
      } else {
        setResultsForm100001(form100001Data);
      }
      if (form10002Error) {
      } else {
        setResultsForm100002(form100002Data);
      }
      if (form10003Error) {
      } else {
        setResultsForm100003(form100003Data);
      }
    }

    fetchData();
  }, []);

  const colors = [
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(75, 192, 192, 1)",
    "rgba(153, 102, 255, 1)",
    "rgba(70,130,180,1)",
    "rgba(255, 159, 64, 1)",
    "rgba(102, 205, 170, 1)",
    "rgba(255, 99, 71, 1)",
    "rgba(138, 43, 226, 1)",
    "rgba(147, 112, 219, 1)",
    "rgba(152, 251, 152, 1)",
    "rgba(0, 128, 128, 1)",
    "rgba(0, 255, 127, 1)",
    "rgba(32, 178, 170, 1)",
    "rgba(210, 105, 30, 1)",
    "rgba(107, 142, 35, 1)",
    "rgba(25, 25, 112, 1)",
    "rgba(255, 20, 147, 1)",
    "rgba(189, 183, 107, 1)",
  ];

  const chartLabels = Object.keys(questionsAndResponses);
  const responseCounts = {};

  for (let question in questionsAndResponses) {
    responseCounts[question] = Array(
      questionsAndResponses[question].length
    ).fill(0);
  }

  resultsForm3.forEach((entry) => {
    for (let question in questionsAndResponses) {
      const responseIndex = questionsAndResponses[question].indexOf(
        entry[question]
      );
      if (responseIndex !== -1) {
        responseCounts[question][responseIndex]++;
      }
    }
  });

  const responsePercentages = {};
  for (let key in responseCounts) {
    const totalResponses = resultsForm3.length;
    responsePercentages[key] = responseCounts[key].map((count) => {
      const percentage = (count / totalResponses) * 100;
      return Math.min(100, Math.max(0, percentage));
    });
  }

  resultsForm12.forEach((entry) => {
    for (let question in questionsAndResponses) {
      const responseIndex = questionsAndResponses[question].indexOf(
        entry[question]
      );
      if (responseIndex !== -1) {
        responseCounts[question][responseIndex]++;
      }
    }
  });

  const calculateResponsePercentages = (data, questionsAndResponses) => {
    const responseCounts = {};

    for (let question in questionsAndResponses) {
      responseCounts[question] = Array(
        questionsAndResponses[question].length
      ).fill(0);
    }

    data.forEach((entry) => {
      for (let question in questionsAndResponses) {
        if (
          question === "afastamentoCausas" &&
          typeof entry[question] === "string"
        ) {
          const parsedAfastamentoCausas = JSON.parse(entry[question]);
          parsedAfastamentoCausas.forEach((response) => {
            const responseIndex =
              questionsAndResponses[question].indexOf(response);
            if (responseIndex !== -1) {
              responseCounts[question][responseIndex]++;
            }
          });
        } else {
          const responseIndex = questionsAndResponses[question].indexOf(
            entry[question]
          );
          if (responseIndex !== -1) {
            responseCounts[question][responseIndex]++;
          }
        }
      }
    });

    const responsePercentages = {};
    for (let key in responseCounts) {
      const totalResponses = data.length;
      responsePercentages[key] = responseCounts[key].map(
        (count) => (count / totalResponses) * 100
      );
    }

    return responsePercentages;
  };

  const form2ResponsePercentages = calculateResponsePercentages(
    resultsForm2,
    questionsAndResponsesForm2
  );

  const form21ResponsePercentages = calculateResponsePercentages(
    resultsForm21,
    questionsAndResponsesForm21
  );

  const form12ResponsePercentages = calculateResponsePercentages(
    resultsForm12,
    questionsAndResponsesForm12
  );

  const form13ResponsePercentages = calculateResponsePercentages(
    resultsForm13,
    questionsAndResponsesForm13
  );

  const form14ResponsePercentages = calculateResponsePercentages(
    resultsForm14,
    questionsAndResponsesForm14
  );

  const form6ResponsePercentages = calculateResponsePercentages(
    resultsForm6,
    questionsAndResponsesForm6
  );

  const form25ResponsePercentages = calculateResponsePercentages(
    resultsForm25,
    questionsAndResponsesForm25
  );

  const form24ResponsePercentages = calculateResponsePercentages(
    resultsForm24,
    questionsAndResponsesForm24
  );

  const form16ResponsePercentages = calculateResponsePercentages(
    resultsForm16,
    questionsAndResponsesForm16
  );

  const form17ResponsePercentages = calculateResponsePercentages(
    resultsForm17,
    questionsAndResponsesForm17
  );

  const form18ResponsePercentages = calculateResponsePercentages(
    resultsForm18,
    questionsAndResponsesForm18
  );

  const form19ResponsePercentages = calculateResponsePercentages(
    resultsForm19,
    questionsAndResponsesForm19
  );

  const form20ResponsePercentages = calculateResponsePercentages(
    resultsForm20,
    questionsAndResponsesForm20
  );

  const form202ResponsePercentages = calculateResponsePercentages(
    resultsForm202,
    questionsAndResponsesForm202
  );

  const form203ResponsePercentages = calculateResponsePercentages(
    resultsForm203,
    questionsAndResponsesForm203
  );

  const form100001ResponsePercentages = calculateResponsePercentages(
    resultsForm100001,
    questionsAndResponsesForm100001
  );
  const form100002ResponsePercentages = calculateResponsePercentages(
    resultsForm100002,
    questionsAndResponsesForm100002
  );
  const form100003ResponsePercentages = calculateResponsePercentages(
    resultsForm100003,
    questionsAndResponsesForm100003
  );

  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate("/plataforma/Escolher-Resultado-Militar");
  };

  return (
    <>
      <HeaderGeral />
      <Container>
        <DivButton>
          <ButtonReturn onClick={handleProfileClick} />
        </DivButton>
        <ResultTitle>sociodemográfico</ResultTitle>
        <FormContainer>
          {Object.keys(questionsAndResponsesForm2).map((question, index) => (
            <div key={index}>
              <ToggleButton
                onClick={() => {
                  if (activeChart === question) {
                    setActiveChart(null);
                  } else {
                    setActiveChart(question);
                  }
                }}
              >
                {activeChart === question
                  ? `Esconder ${categoryTitles[question]}`
                  : `Mostrar ${categoryTitles[question]}`}
              </ToggleButton>

              {activeChart === question && (
                <ChartContainer>
                  <ResultTitle>{categoryTitles[question]}</ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm2[question],
                      datasets: [
                        {
                          data: form2ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              )}
            </div>
          ))}
        </FormContainer>

        <ToggleButton onClick={() => setShowAll(!showAll)}>
          {showAll ? "Esconder todos" : "Mostrar todos"}
        </ToggleButton>

        <FormContainer>
          {showAll &&
            Object.keys(questionsAndResponsesForm2).map((question, index) => (
              <div key={index}>
                <ChartContainer>
                  <ResultTitle>{categoryTitles[question]}</ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm2[question],
                      datasets: [
                        {
                          data: form2ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              </div>
            ))}
        </FormContainer>

        <ResultTitle>caracterização dos hábitos de vida</ResultTitle>
        <FormContainer>
          {chartLabels.map((question, index) => (
            <div key={index}>
              <ToggleButton
                onClick={() => {
                  if (activeChart === question) {
                    setActiveChart(null);
                  } else {
                    setActiveChart(question);
                  }
                }}
              >
                {activeChart === question
                  ? `Esconder ${originalQuestions[index]}`
                  : `Mostrar ${originalQuestions[index]}`}
              </ToggleButton>

              {activeChart === question && (
                <ChartContainer>
                  <ResultTitle>{originalQuestions[index]}</ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponses[question],
                      datasets: [
                        {
                          data: responsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              )}
            </div>
          ))}
        </FormContainer>

        <ToggleButton onClick={() => setShowAll(!showAll)}>
          {showAll ? "Esconder todos" : "Mostrar todos"}
        </ToggleButton>

        <FormContainer>
          {showAll &&
            chartLabels.map((question, index) => (
              <div key={index}>
                <ChartContainer>
                  <ResultTitle>{originalQuestions[index]}</ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponses[question],
                      datasets: [
                        {
                          data: responsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              </div>
            ))}
        </FormContainer>

        <ResultTitle>
          AVALIAÇÃO DA QUALIDADE DE VIDA RELACIONADA A SAÚDE
        </ResultTitle>

        <FormContainer>
          {Object.keys(questionsAndResponsesForm21).map((question, index) => (
            <div key={index}>
              <ToggleButton
                onClick={() => {
                  if (activeChart === question) {
                    setActiveChart(null);
                  } else {
                    setActiveChart(question);
                  }
                }}
              >
                {activeChart === question
                  ? `Esconder ${questionsAndResponsesForm21Titles[question]}`
                  : `Mostrar ${questionsAndResponsesForm21Titles[question]}`}
              </ToggleButton>

              {activeChart === question && (
                <ChartContainer>
                  <ResultTitle>
                    {questionsAndResponsesForm21Titles[question]}
                  </ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm21[question],
                      datasets: [
                        {
                          data: form21ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              )}
            </div>
          ))}
        </FormContainer>

        <ToggleButton onClick={() => setShowAll(!showAll)}>
          {showAll ? "Esconder todos" : "Mostrar todos"}
        </ToggleButton>

        <FormContainer>
          {showAll &&
            Object.keys(questionsAndResponsesForm21).map((question, index) => (
              <div key={index}>
                <ChartContainer>
                  <ResultTitle>
                    {questionsAndResponsesForm21Titles[question]}
                  </ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm21[question],
                      datasets: [
                        {
                          data: form21ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              </div>
            ))}
        </FormContainer>

        <ResultTitle>ESCALA DE ESTRESSE NO TRABALHO</ResultTitle>

        <FormContainer>
          {Object.keys(questionsAndResponsesForm12).map((question, index) => (
            <div key={index}>
              <ToggleButton
                onClick={() => {
                  if (activeChart === question) {
                    setActiveChart(null);
                  } else {
                    setActiveChart(question);
                  }
                }}
              >
                {activeChart === question
                  ? `Esconder ${questionsAndResponsesForm12Titles[question]}`
                  : `Mostrar ${questionsAndResponsesForm12Titles[question]}`}
              </ToggleButton>

              {activeChart === question && (
                <ChartContainer>
                  <ResultTitle>
                    {questionsAndResponsesForm12Titles[question]}
                  </ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm12[question],
                      datasets: [
                        {
                          data: form12ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              )}
            </div>
          ))}
        </FormContainer>

        <ToggleButton onClick={() => setShowAll(!showAll)}>
          {showAll ? "Esconder todos" : "Mostrar todos"}
        </ToggleButton>

        <FormContainer>
          {showAll &&
            Object.keys(questionsAndResponsesForm12).map((question, index) => (
              <div key={index}>
                <ChartContainer>
                  <ResultTitle>
                    {questionsAndResponsesForm12Titles[question]}
                  </ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm12[question],
                      datasets: [
                        {
                          data: form12ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              </div>
            ))}
        </FormContainer>

        <ResultTitle>AVALIAÇÃO DO ESGOTAMENTO PROFISSIONAL</ResultTitle>
        <FormContainer>
          {Object.keys(questionsAndResponsesForm13).map((question, index) => (
            <div key={index}>
              <ToggleButton
                onClick={() => {
                  if (activeChart === question) {
                    setActiveChart(null);
                  } else {
                    setActiveChart(question);
                  }
                }}
              >
                {activeChart === question
                  ? `Esconder ${questionsAndResponsesForm13Titles[question]}`
                  : `Mostrar ${questionsAndResponsesForm13Titles[question]}`}
              </ToggleButton>

              {activeChart === question && (
                <ChartContainer>
                  <ResultTitle>
                    {questionsAndResponsesForm13Titles[question]}
                  </ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm13[question],
                      datasets: [
                        {
                          data: form13ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              )}
            </div>
          ))}
        </FormContainer>

        <ResultTitle>VARIAVEIS POR FATOR DE BURNOUT</ResultTitle>
        <FormContainer>
          {Object.keys(questionsAndResponsesForm14).map((question, index) => (
            <div key={index}>
              <ToggleButton
                onClick={() => {
                  if (activeChart === question) {
                    setActiveChart(null);
                  } else {
                    setActiveChart(question);
                  }
                }}
              >
                {activeChart === question
                  ? `Esconder ${questionsAndResponsesForm14Titles[question]}`
                  : `Mostrar ${questionsAndResponsesForm14Titles[question]}`}
              </ToggleButton>

              {activeChart === question && (
                <ChartContainer>
                  <ResultTitle>
                    {questionsAndResponsesForm14Titles[question]}
                  </ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm14[question],
                      datasets: [
                        {
                          data: form14ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              )}
            </div>
          ))}
        </FormContainer>

        <ToggleButton onClick={() => setShowAll(!showAll)}>
          {showAll ? "Esconder todos" : "Mostrar todos"}
        </ToggleButton>

        <FormContainer>
          {showAll &&
            Object.keys(questionsAndResponsesForm14).map((question, index) => (
              <div key={index}>
                <ChartContainer>
                  <ResultTitle>
                    {questionsAndResponsesForm14Titles[question]}
                  </ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm14[question],
                      datasets: [
                        {
                          data: form14ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              </div>
            ))}
        </FormContainer>

        <ResultTitle>NÍVEL DE ATIVIDADE FÍSICA</ResultTitle>
        <FormContainer>
          {Object.keys(questionsAndResponsesForm6).map((question, index) => (
            <div key={index}>
              <ToggleButton
                onClick={() => {
                  if (activeChart === question) {
                    setActiveChart(null);
                  } else {
                    setActiveChart(question);
                  }
                }}
              >
                {activeChart === question
                  ? `Esconder ${questionsAndResponsesForm6Titles[question]}`
                  : `Mostrar ${questionsAndResponsesForm6Titles[question]}`}
              </ToggleButton>

              {activeChart === question && (
                <ChartContainer>
                  <ResultTitle>
                    {questionsAndResponsesForm6Titles[question]}
                  </ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm6[question],
                      datasets: [
                        {
                          data: form6ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              )}
            </div>
          ))}
        </FormContainer>

        <ResultTitle>SAÚDE do paciente - phq9</ResultTitle>

        <FormContainer>
          {Object.keys(questionsAndResponsesForm25).map((question, index) => (
            <div key={index}>
              <ToggleButton
                onClick={() => {
                  if (activeChart === question) {
                    setActiveChart(null);
                  } else {
                    setActiveChart(question);
                  }
                }}
              >
                {activeChart === question
                  ? `Esconder ${questionsAndResponsesForm25Titles[question]}`
                  : `Mostrar ${questionsAndResponsesForm25Titles[question]}`}
              </ToggleButton>

              {activeChart === question && (
                <ChartContainer>
                  <ResultTitle>
                    {questionsAndResponsesForm25Titles[question]}
                  </ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm25[question],
                      datasets: [
                        {
                          data: form25ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              )}
            </div>
          ))}
        </FormContainer>

        <ResultTitle>ESCALA DE DEPRESSÃO, ANSIEDADE E ESTRESSE</ResultTitle>
        <FormContainer>
          {Object.keys(questionsAndResponsesForm24).map((question, index) => (
            <div key={index}>
              <ToggleButton
                onClick={() => {
                  if (activeChart === question) {
                    setActiveChart(null);
                  } else {
                    setActiveChart(question);
                  }
                }}
              >
                {activeChart === question
                  ? `Esconder ${questionsAndResponsesForm24Titles[question]}`
                  : `Mostrar ${questionsAndResponsesForm24Titles[question]}`}
              </ToggleButton>

              {activeChart === question && (
                <ChartContainer>
                  <ResultTitle>
                    {questionsAndResponsesForm24Titles[question]}
                  </ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm24[question],
                      datasets: [
                        {
                          data: form24ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              )}
            </div>
          ))}
        </FormContainer>

        <ToggleButton onClick={() => setShowAll(!showAll)}>
          {showAll ? "Esconder todos" : "Mostrar todos"}
        </ToggleButton>

        <FormContainer>
          {showAll &&
            Object.keys(questionsAndResponsesForm24).map((question, index) => (
              <div key={index}>
                <ChartContainer>
                  <ResultTitle>
                    {questionsAndResponsesForm24Titles[question]}
                  </ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm24[question],
                      datasets: [
                        {
                          data: form24ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              </div>
            ))}
        </FormContainer>

        <ResultTitle>ÍNDICE DE MASSA CORPORAL – IMC</ResultTitle>
        <FormContainer>
          {Object.keys(questionsAndResponsesForm16).map((question, index) => (
            <div key={index}>
              <ToggleButton
                onClick={() => {
                  if (activeChart === question) {
                    setActiveChart(null);
                  } else {
                    setActiveChart(question);
                  }
                }}
              >
                {activeChart === question
                  ? `Esconder ${questionsAndResponsesForm16Titles[question]}`
                  : `Mostrar ${questionsAndResponsesForm16Titles[question]}`}
              </ToggleButton>

              {activeChart === question && (
                <ChartContainer>
                  <ResultTitle>
                    {questionsAndResponsesForm16Titles[question]}
                  </ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm16[question],
                      datasets: [
                        {
                          data: form16ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              )}
            </div>
          ))}
        </FormContainer>

        <ResultTitle>ÍNDICE RELAÇÃO CINTURA/QUADRIL - IRCQ</ResultTitle>
        <FormContainer>
          {Object.keys(questionsAndResponsesForm17).map((question, index) => (
            <div key={index}>
              <ToggleButton
                onClick={() => {
                  if (activeChart === question) {
                    setActiveChart(null);
                  } else {
                    setActiveChart(question);
                  }
                }}
              >
                {activeChart === question
                  ? `Esconder ${questionsAndResponsesForm17Titles[question]}`
                  : `Mostrar ${questionsAndResponsesForm17Titles[question]}`}
              </ToggleButton>

              {activeChart === question && (
                <ChartContainer>
                  <ResultTitle>
                    {questionsAndResponsesForm17Titles[question]}
                  </ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm17[question],
                      datasets: [
                        {
                          data: form17ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              )}
            </div>
          ))}
        </FormContainer>

        <ResultTitle>TESTE DE PERCENTUAL DE GORDURA</ResultTitle>
        <FormContainer>
          {Object.keys(questionsAndResponsesForm18).map((question, index) => (
            <div key={index}>
              <ToggleButton
                onClick={() => {
                  if (activeChart === question) {
                    setActiveChart(null);
                  } else {
                    setActiveChart(question);
                  }
                }}
              >
                {activeChart === question
                  ? `Esconder ${questionsAndResponsesForm18Titles[question]}`
                  : `Mostrar ${questionsAndResponsesForm18Titles[question]}`}
              </ToggleButton>

              {activeChart === question && (
                <ChartContainer>
                  <ResultTitle>
                    {questionsAndResponsesForm18Titles[question]}
                  </ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm18[question],
                      datasets: [
                        {
                          data: form18ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              )}
            </div>
          ))}
        </FormContainer>

        <ResultTitle>EXAME SÍNDROME METABÓLICA</ResultTitle>
        <FormContainer>
          {Object.keys(questionsAndResponsesForm19).map((question, index) => (
            <div key={index}>
              <ToggleButton
                onClick={() => {
                  if (activeChart === question) {
                    setActiveChart(null);
                  } else {
                    setActiveChart(question);
                  }
                }}
              >
                {activeChart === question
                  ? `Esconder ${questionsAndResponsesForm19Titles[question]}`
                  : `Mostrar ${questionsAndResponsesForm19Titles[question]}`}
              </ToggleButton>

              {activeChart === question && (
                <ChartContainer>
                  <ResultTitle>
                    {questionsAndResponsesForm19Titles[question]}
                  </ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm19[question],
                      datasets: [
                        {
                          data: form19ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              )}
            </div>
          ))}
        </FormContainer>

        <ResultTitle>EXAMES LABORATORIAIS</ResultTitle>
        <FormContainer>
          {Object.keys(questionsAndResponsesForm20).map((question, index) => (
            <div key={index}>
              <ToggleButton
                onClick={() => {
                  if (activeChart === question) {
                    setActiveChart(null);
                  } else {
                    setActiveChart(question);
                  }
                }}
              >
                {activeChart === question
                  ? `Esconder ${questionsAndResponsesForm20Titles[question]}`
                  : `Mostrar ${questionsAndResponsesForm20Titles[question]}`}
              </ToggleButton>

              {activeChart === question && (
                <ChartContainer>
                  <ResultTitle>
                    {questionsAndResponsesForm20Titles[question]}
                  </ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm20[question],
                      datasets: [
                        {
                          data: form20ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              )}
            </div>
          ))}
        </FormContainer>

        <ToggleButton onClick={() => setShowAll(!showAll)}>
          {showAll ? "Esconder todos" : "Mostrar todos"}
        </ToggleButton>

        <FormContainer>
          {showAll &&
            Object.keys(questionsAndResponsesForm20).map((question, index) => (
              <div key={index}>
                <ChartContainer>
                  <ResultTitle>
                    {questionsAndResponsesForm20Titles[question]}
                  </ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm20[question],
                      datasets: [
                        {
                          data: form20ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              </div>
            ))}
        </FormContainer>

        <ResultTitle>EXAMES LABORATORIAIS PARCIAIS</ResultTitle>
        <FormContainer>
          {Object.keys(questionsAndResponsesForm202).map((question, index) => (
            <div key={index}>
              <ToggleButton
                onClick={() => {
                  if (activeChart === question) {
                    setActiveChart(null);
                  } else {
                    setActiveChart(question);
                  }
                }}
              >
                {activeChart === question
                  ? `Esconder ${questionsAndResponsesForm202Titles[question]}`
                  : `Mostrar ${questionsAndResponsesForm202Titles[question]}`}
              </ToggleButton>

              {activeChart === question && (
                <ChartContainer>
                  <ResultTitle>
                    {questionsAndResponsesForm202Titles[question]}
                  </ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm202[question],
                      datasets: [
                        {
                          data: form202ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              )}
            </div>
          ))}
        </FormContainer>

        <ToggleButton onClick={() => setShowAll(!showAll)}>
          {showAll ? "Esconder todos" : "Mostrar todos"}
        </ToggleButton>

        <FormContainer>
          {showAll &&
            Object.keys(questionsAndResponsesForm202).map((question, index) => (
              <div key={index}>
                <ChartContainer>
                  <ResultTitle>
                    {questionsAndResponsesForm202Titles[question]}
                  </ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm202[question],
                      datasets: [
                        {
                          data: form202ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              </div>
            ))}
        </FormContainer>

        <ResultTitle>HEMOGRAMA COMPLETO</ResultTitle>

        <FormContainer>
          {Object.keys(questionsAndResponsesForm203).map((question, index) => (
            <div key={index}>
              <ToggleButton
                onClick={() => {
                  if (activeChart === question) {
                    setActiveChart(null);
                  } else {
                    setActiveChart(question);
                  }
                }}
              >
                {activeChart === question
                  ? `Esconder ${questionsAndResponsesForm203Titles[question]}`
                  : `Mostrar ${questionsAndResponsesForm203Titles[question]}`}
              </ToggleButton>

              {activeChart === question && (
                <ChartContainer>
                  <ResultTitle>
                    {questionsAndResponsesForm203Titles[question]}
                  </ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm203[question],
                      datasets: [
                        {
                          data: form203ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              )}
            </div>
          ))}
        </FormContainer>

        <ToggleButton onClick={() => setShowAll(!showAll)}>
          {showAll ? "Esconder todos" : "Mostrar todos"}
        </ToggleButton>

        <FormContainer>
          {showAll &&
            Object.keys(questionsAndResponsesForm203).map((question, index) => (
              <div key={index}>
                <ChartContainer>
                  <ResultTitle>
                    {questionsAndResponsesForm203Titles[question]}
                  </ResultTitle>
                  <Bar
                    data={{
                      labels: questionsAndResponsesForm203[question],
                      datasets: [
                        {
                          data: form203ResponsePercentages[question],
                          backgroundColor: colors,
                          borderColor: colors.map((color) =>
                            color.replace("0.5", "1")
                          ),
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptions}
                  />
                </ChartContainer>
              </div>
            ))}
        </FormContainer>

        <ResultTitle>Ansiedade - Beck</ResultTitle>
        <FormContainer>
          {Object.keys(questionsAndResponsesForm100001).map(
            (question, index) => (
              <div key={index}>
                <ToggleButton
                  onClick={() => {
                    if (activeChart === question) {
                      setActiveChart(null);
                    } else {
                      setActiveChart(question);
                    }
                  }}
                >
                  {activeChart === question
                    ? `Esconder ${questionsAndResponsesForm100001Titles[question]}`
                    : `Mostrar ${questionsAndResponsesForm100001Titles[question]}`}
                </ToggleButton>

                {activeChart === question && (
                  <ChartContainer>
                    <ResultTitle>
                      {questionsAndResponsesForm100001Titles[question]}
                    </ResultTitle>
                    <Bar
                      data={{
                        labels: questionsAndResponsesForm100001[question],
                        datasets: [
                          {
                            data: form100001ResponsePercentages[question],
                            backgroundColor: colors,
                            borderColor: colors.map((color) =>
                              color.replace("0.5", "1")
                            ),
                            borderWidth: 1,
                          },
                        ],
                      }}
                      options={chartOptions}
                    />
                  </ChartContainer>
                )}
              </div>
            )
          )}
        </FormContainer>

        <ResultTitle>Depressão - Beck</ResultTitle>
        <FormContainer>
          {Object.keys(questionsAndResponsesForm100002).map(
            (question, index) => (
              <div key={index}>
                <ToggleButton
                  onClick={() => {
                    if (activeChart === question) {
                      setActiveChart(null);
                    } else {
                      setActiveChart(question);
                    }
                  }}
                >
                  {activeChart === question
                    ? `Esconder ${questionsAndResponsesForm100002Titles[question]}`
                    : `Mostrar ${questionsAndResponsesForm100002Titles[question]}`}
                </ToggleButton>

                {activeChart === question && (
                  <ChartContainer>
                    <ResultTitle>
                      {questionsAndResponsesForm100002Titles[question]}
                    </ResultTitle>
                    <Bar
                      data={{
                        labels: questionsAndResponsesForm100002[question],
                        datasets: [
                          {
                            data: form100002ResponsePercentages[question],
                            backgroundColor: colors,
                            borderColor: colors.map((color) =>
                              color.replace("0.5", "1")
                            ),
                            borderWidth: 1,
                          },
                        ],
                      }}
                      options={chartOptions}
                    />
                  </ChartContainer>
                )}
              </div>
            )
          )}
        </FormContainer>

        <ResultTitle>Sofrimento Mental</ResultTitle>
        <FormContainer>
          {Object.keys(questionsAndResponsesForm100003).map(
            (question, index) => (
              <div key={index}>
                <ToggleButton
                  onClick={() => {
                    if (activeChart === question) {
                      setActiveChart(null);
                    } else {
                      setActiveChart(question);
                    }
                  }}
                >
                  {activeChart === question
                    ? `Esconder ${questionsAndResponsesForm100003Titles[question]}`
                    : `Mostrar ${questionsAndResponsesForm100003Titles[question]}`}
                </ToggleButton>

                {activeChart === question && (
                  <ChartContainer>
                    <ResultTitle>
                      {questionsAndResponsesForm100003Titles[question]}
                    </ResultTitle>
                    <Bar
                      data={{
                        labels: questionsAndResponsesForm100003[question],
                        datasets: [
                          {
                            data: form100003ResponsePercentages[question],
                            backgroundColor: colors,
                            borderColor: colors.map((color) =>
                              color.replace("0.5", "1")
                            ),
                            borderWidth: 1,
                          },
                        ],
                      }}
                      options={chartOptions}
                    />
                  </ChartContainer>
                )}
              </div>
            )
          )}
        </FormContainer>
      </Container>
    </>
  );
};

export default Form36;
