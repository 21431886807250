export const originalQuestions = [
  "Fumante",
  "Consumo de álcool",
  "Alimentação saudável",
  "Dificuldades para dormir",
  "Meio de transporte utilizado para ir ao trabalho",
  "Possui moradia própria",
  "Sua residência fica em qual zona da cidade",
];

export const questionsAndResponses = {
  q1: ["Sim (fumante)", "Sim (ex-fumante)", "Não Fuma"],
  q2: [
    "De uma a duas vezes por semana",
    "De três a quatro vezes por semana",
    "Mais de 4 vezes por semana",
    "Não consumo bebida alcoólica",
  ],
  q3: ["Sim", "Não", "Não sei opinar sobre isso"],
  q4: ["Sim", "Não"],
  q5: ["Carro", "Moto", "Ônibus", "Outros"],
  q6: ["Sim", "Não"],
  q7: ["Centro", "Leste", "Sudeste", "Norte", "Sul", "Outra"],
};

export const questionsAndResponsesForm2 = {
  gender: ["Feminino", "Masculino"],
  race: ["Preto", "Pardo", "Amarelo", "Indígena", "Branco"],
  ageRange: ["20-30", "31-40", "35-44", "41-50", "acima-50"],
  maritalStatus: ["Solteiro", "Casado", "Divorciado", "Viúvo", "União Estável"],
  numberOfChildren: [
    "Nenhum",
    "01 Filho",
    "De 2 a 3 filhos",
    "De 3 a 4 filhos",
    "Mais de 4 filhos",
  ],
  rendaFamiliar: [
    "1 Salário Mínimo",
    "1 a 3 salários",
    "3 a 5 salários",
    "5 a 10 salários",
    "Mais de 10 salários ",
  ],
  educationLevel: [
    "Analfabeto",
    "Até 5º Ano Incompleto",
    "5º Ano Completo",
    "6º ao 9º Ano do Fundamental",
    "Fundamental Completo",
    "Médio Incompleto",
    "Médio Completo",
    "Ensino Superior Incompleto",
    "Ensino Superior Completo",
    "Pós-Graduação – Especialização",
    "Mestrado",
    "Doutorado",
  ],
  workDays: ["1-3", "4-5", "acima-5"],
  otherPaidActivity: ["Sim", "Não"],
  numberOfOtherPaidActivities: ["1", "2", "3-mais"],
  absencesLastYear: [
    "Nenhuma vez",
    "De 1 a 2 vezes",
    "De 3 a 4 vezes",
    "De 4 a 5 vezes",
    "mais-5 vezes",
  ],
  afastamentoCausas: [
    "Doenças Infecciosas, parasitárias e virais",
    "Neoplasia,doenças no sangue e endócrinas",
    "Transtornos mentais, psicológicos e comportamentais",
    "Doenças do sistema Nervoso",
    "Doenças nos olhos e/ou ouvido",
    "Doenças do aparelho circulatório",
    "Doenças do aparelho respiratório",
    "Doenças da cavidade oral",
    "Doenças do aparelho digestório",
    "Doenças de pele",
    "Doenças do aparelho ostemioarticular",
    "Doenças do aparelho genital e urinário",
    "Gravidez, parto ou puerpério ",
    "Lesões, envenenamento e causas externas",
    "Consultas ou exames médicos",
    "Acidentes em serviço",
    "Outros",
  ],
};

export const categoryTitles = {
  gender: "Gênero",
  race: "Raça/Cor",
  ageRange: "Faixa Etária",
  maritalStatus: "Estado Civil",
  numberOfChildren: "Número de Filhos",
  educationLevel: "Escolaridade",
  rendaFamiliar: "Renda Familiar",
  workDays: "Período de trabalho por semana (em dias)",
  otherPaidActivity: "Realiza mais de uma atividade remunerada?",
  numberOfOtherPaidActivities: "Quantidade de atividades remuneradas?",
  absencesLastYear: "Quantas vezes se afastou do trabalho (último ano)",
  afastamentoCausas:
    "Se houve afastamento do trabalho no último ano, qual a causa?",
};

export const questionsAndResponsesForm21 = {
  physicalDomainClassification: [
    "necessita melhorar",
    "regular",
    "boa",
    "muito boa",
  ],
  psychologicalDomainClassification: [
    "necessita melhorar",
    "regular",
    "boa",
    "muito boa",
  ],
  socialRelationsDomainClassification: [
    "necessita melhorar",
    "regular",
    "boa",
    "muito boa",
  ],
  environmentDomainClassification: [
    "necessita melhorar",
    "regular",
    "boa",
    "muito boa",
  ],
};

export const questionsAndResponsesForm21Titles = {
  physicalDomainClassification: "Domínio Físico",
  psychologicalDomainClassification: "Domínio Psicológico",
  socialRelationsDomainClassification: "Relações Sociais",
  environmentDomainClassification: "Meio Ambiente",
};

export const questionsAndResponsesForm12 = {
  nivelDeEstresseMediaGeral: [
    "NÍVEL DE ESTRESSE PERCEBIDO: BAIXO",
    "NÍVEL DE ESTRESSE PERCEBIDO: MÉDIO A ALTO",
    "NÍVEL DE ESTRESSE PERCEBIDO: MUITO ALTO",
  ],
  nivelDeEstresseRoleWorkEnvironment: [
    "NÍVEL DE ESTRESSE PERCEBIDO: BAIXO",
    "NÍVEL DE ESTRESSE PERCEBIDO: MÉDIO A ALTO",
    "NÍVEL DE ESTRESSE PERCEBIDO: MUITO ALTO",
  ],
  nivelDeEstresseAutonomyControl: [
    "NÍVEL DE ESTRESSE PERCEBIDO: BAIXO",
    "NÍVEL DE ESTRESSE PERCEBIDO: MÉDIO A ALTO",
    "NÍVEL DE ESTRESSE PERCEBIDO: MUITO ALTO",
  ],
  nivelDeEstresseRelationshipWithSupervisor: [
    "NÍVEL DE ESTRESSE PERCEBIDO: BAIXO",
    "NÍVEL DE ESTRESSE PERCEBIDO: MÉDIO A ALTO",
    "NÍVEL DE ESTRESSE PERCEBIDO: MUITO ALTO",
  ],
  nivelDeEstresseRelacionamentosInterpessoais: [
    "NÍVEL DE ESTRESSE PERCEBIDO: BAIXO",
    "NÍVEL DE ESTRESSE PERCEBIDO: MÉDIO A ALTO",
    "NÍVEL DE ESTRESSE PERCEBIDO: MUITO ALTO",
  ],
  nivelDeEstresseCrescimentoValoração: [
    "NÍVEL DE ESTRESSE PERCEBIDO: BAIXO",
    "NÍVEL DE ESTRESSE PERCEBIDO: MÉDIO A ALTO",
    "NÍVEL DE ESTRESSE PERCEBIDO: MUITO ALTO",
  ],
};

export const questionsAndResponsesForm12Titles = {
  nivelDeEstresseMediaGeral: "Média Geral",
  nivelDeEstresseRoleWorkEnvironment: "Papéis e Ambiente de Trabalho",
  nivelDeEstresseAutonomyControl: "Autonomia e Controle",
  nivelDeEstresseRelationshipWithSupervisor: "Relacionamento com o Chefe",
  nivelDeEstresseRelacionamentosInterpessoais: "Relacionamentos Interpessoais",
  nivelDeEstresseCrescimentoValoração: "Crescismento e Valorização",
};

export const questionsAndResponsesForm13 = {
  burnoutClassification: [
    "Baixa probabilidade de desenvolvimento",
    "Fase inicial de desenvolvimento",
    "Fase intermediária de desenvolvimento",
    "Fase avançada de desenvolvimento",
    "Classificação não disponível",
  ],
};

export const questionsAndResponsesForm13Titles = {
  burnoutClassification: "Classificação Burnout",
};

export const questionsAndResponsesForm14 = {
  resultado1: ["baixo", "médio", "alto", "valor inválido"],
  resultado2: ["baixo", "médio", "alto", "valor inválido"],
  resultado3: ["baixo", "médio", "alto", "valor inválido"],
};

export const questionsAndResponsesForm14Titles = {
  resultado1: "Exaustão Emocional",
  resultado2: "Despersonalização",
  resultado3: "Realização Profissional",
};

export const questionsAndResponsesForm6 = {
  classification: [
    "INATIVO ",
    "INSUFICIENTEMENTE ATIVO ",
    "ATIVO ",
    "MUITO ATIVO ",
  ],
};

export const questionsAndResponsesForm6Titles = {
  classification: "Classificação",
};

export const questionsAndResponsesForm25 = {
  result: [
    "sem depressão",
    "transtorno depressivo leve",
    "transtorno depressivo moderado",
    "transtorno depressivo moderadamente grave",
    "transtorno depressivo grave",
  ],
};

export const questionsAndResponsesForm25Titles = {
  result: "Resultado",
};

export const questionsAndResponsesForm24 = {
  depressionClassification: [
    "normal",
    "leve",
    "moderada",
    "severo",
    "extremamente severo",
  ],
  anxietyClassification: [
    "normal",
    "leve",
    "moderado",
    "severo",
    "extremamente severo",
  ],
  stressClassification: [
    "normal",
    "leve",
    "moderado",
    "severo",
    "extremamente severo",
  ],
};

export const questionsAndResponsesForm24Titles = {
  depressionClassification: "Classificação Depressão",
  anxietyClassification: "Classificação Ansiedade",
  stressClassification: "Classificação Estresse",
};

export const questionsAndResponsesForm16 = {
  bmiClassification: [
    "Abaixo do peso",
    "Saudável",
    "Sobrepeso",
    "Obesidade grau 1",
    "Obesidade grau 2",
    "Grau 3 (obesidade mórbida)",
  ],
};

export const questionsAndResponsesForm16Titles = {
  bmiClassification: "IMC",
};

export const questionsAndResponsesForm17 = {
  riscoCircunferenciaCintura: [
    "Risco Baixo",
    "Risco Moderado",
    "Sobrepeso",
    "Risco Elevado",
    "Risco Muito Elevado",
  ],
};

export const questionsAndResponsesForm17Titles = {
  riscoCircunferenciaCintura: "IRCQ",
};

export const questionsAndResponsesForm18 = {
  classificacao: ["Excelente", "Bom", "Normal", "Elevado", "Muito elevado"],
};

export const questionsAndResponsesForm18Titles = {
  classificacao: "Percentual de Gordura",
};

export const questionsAndResponsesForm19 = {
  message: [
    "Você possui resistência insulínica.",
    "Você não possui resistência insulínica.",
  ],
};

export const questionsAndResponsesForm19Titles = {
  message: "Síndrome Metabólca",
};

export const questionsAndResponsesForm20 = {
  diagnósticoVHS: ["Dentro do intervalo normal", "Fora do intervalo normal"],
  diagnósticoALT: ["Dentro do intervalo normal", "Fora do intervalo normal"],
  diagnósticoAST: ["Dentro do intervalo normal", "Fora do intervalo normal"],
  diagnósticoLDH: ["Dentro do intervalo normal", "Fora do intervalo normal"],
  diagnósticoCK: ["Dentro do intervalo normal", "Fora do intervalo normal"],
  diagnósticoFerritina: [
    "Dentro do intervalo normal",
    "Fora do intervalo normal",
  ],
  diagnósticoCreatinina: [
    "Dentro do intervalo normal",
    "Fora do intervalo normal",
  ],
  diagnósticoUreia: ["Dentro do intervalo normal", "Fora do intervalo normal"],
  diagnósticoGlicemia: [
    "Glicemia de jejum normal.",
    "Glicemia de jejum alterada. Risco de resistência insulínica.",
    "Diabetes. Risco elevado de resistência insulínica.",
    "Glicemia de jejum baixa ou hipoglicemia.",
  ],
  diagnósticoColesterolTotal: ["Desejável", "Limítrofe", "Alto"],
  diagnósticoLDL: [
    "Risco baixo de doença cardiovascular.",
    "Risco médio de doença cardiovascular.",
    "Risco alto de doença cardiovascular.",
    "Risco muito alto de doença cardiovascular.",
  ],
  diagnósticoHDL: [
    "Nível baixo, risco aumentado",
    "Nível médio, risco médio",
    "Nível alto, risco baixo",
  ],
  diagnósticoVLDL: [
    "Nível elevado de VLDL. Risco de doença cardiovascular.",
    "Nível ideal de VLDL.",
    "Nível baixo de VLDL.",
  ],
  diagnósticoTriglicerídeos: ["Desejável", "Limítrofe", "Alto", "Muito alto"],
};

export const questionsAndResponsesForm20Titles = {
  diagnósticoVHS: "Velocidade de Hemossedimentação (VHS)",
  diagnósticoAST: "Aspartato Aminotransferase (AST)",
  diagnósticoALT: "Alanina Aminotransferase (ALT)",
  diagnósticoLDH: "Lactato Desidrogenase (LDH)",
  diagnósticoCK: "Creatinoquinase (CK)",
  diagnósticoFerritina: "Ferritina",
  diagnósticoCreatinina: "Creatinina",
  diagnósticoUreia: "Ureia",
  diagnósticoGlicemia: "Glicemia",
  diagnósticoColesterolTotal: "Colesterol Total",
  diagnósticoLDL: "LDL",
  diagnósticoHDL: "HDL",
  diagnósticoTriglicerídeos: "Triglicerídeos",
  diagnósticoVLDL: "VLDL",
};

export const questionsAndResponsesForm202 = {
  diagnósticoALT: ["Dentro do intervalo normal", "Fora do intervalo normal"],
  diagnósticoAST: ["Dentro do intervalo normal", "Fora do intervalo normal"],
  diagnósticoCK: ["Dentro do intervalo normal", "Fora do intervalo normal"],
  diagnósticoCreatinina: [
    "Dentro do intervalo normal",
    "Fora do intervalo normal",
  ],
  diagnósticoUreia: ["Dentro do intervalo normal", "Fora do intervalo normal"],
  diagnósticoGlicemia: [
    "Glicemia de jejum normal.",
    "Glicemia de jejum alterada. Risco de resistência insulínica.",
    "Diabetes. Risco elevado de resistência insulínica.",
    "Glicemia de jejum baixa ou hipoglicemia.",
  ],
  diagnósticoTriglicerídeos: ["Desejável", "Limítrofe", "Alto", "Muito alto"],
};

export const questionsAndResponsesForm202Titles = {
  diagnósticoAST: "Aspartato Aminotransferase (AST)",
  diagnósticoALT: "Alanina Aminotransferase (ALT)",
  diagnósticoCK: "Creatinoquinase (CK)",
  diagnósticoCreatinina: "Creatinina",
  diagnósticoUreia: "Ureia",
  diagnósticoGlicemia: "Glicemia",
  diagnósticoTriglicerídeos: "Triglicerídeos",
};

export const questionsAndResponsesForm203 = {
  diagnósticoHemoglobina: [
    "Dentro do intervalo normal",
    "Fora do intervalo normal",
  ],
  diagnósticoHematócrito: [
    "Dentro do intervalo normal",
    "Fora do intervalo normal",
  ],
  diagnósticoVCM: ["Dentro do intervalo normal", "Fora do intervalo normal"],
  diagnósticoHCM: ["Dentro do intervalo normal", "Fora do intervalo normal"],
  diagnósticoCHCM: ["Dentro do intervalo normal", "Fora do intervalo normal"],
  diagnósticoEritrócitos: [
    "Dentro do intervalo normal",
    "Fora do intervalo normal",
  ],
  diagnósticoContagem: [
    "Dentro do intervalo normal",
    "Fora do intervalo normal",
  ],
  diagnósticoAmplitude: [
    "Dentro do intervalo normal",
    "Fora do intervalo normal",
  ],
  diagnósticoNeutrófilos: [
    "Dentro do intervalo normal",
    "Fora do intervalo normal",
  ],
  diagnósticoLinfócitos: [
    "Dentro do intervalo normal",
    "Fora do intervalo normal",
  ],
  diagnósticoMonócitos: [
    "Dentro do intervalo normal",
    "Fora do intervalo normal",
  ],
  diagnósticoEosinófilos: [
    "Dentro do intervalo normal",
    "Fora do intervalo normal",
  ],
  diagnósticoBasófilos: [
    "Dentro do intervalo normal",
    "Fora do intervalo normal",
  ],
  diagnósticoPlaquetas: [
    "Dentro do intervalo normal",
    "Fora do intervalo normal",
  ],
};

export const questionsAndResponsesForm203Titles = {
  diagnósticoHCM: "Hemoglobina",
  diagnósticoHemoglobina: "Hemoglobina",
  diagnósticoCHCM:
    "Concentração de hemoglobina celular (ou corpuscular) média (CHCM)",
  diagnósticoEritrócitos: "Contagem de glóbulos vermelhos (eritrócitos)",
  diagnósticoVCM: "Volume celular (ou corpuscular) médico (VCM)",
  diagnósticoHematócrito: "Hematócrito",
  diagnósticoContagem: "Contagem de glóbulos vermelhos (eritrócitos)",
  diagnósticoAmplitude: "Amplitude de distribuição de eritrócitos",
  diagnósticoNeutrófilos: "Neutrófilos",
  diagnósticoLinfócitos: "Linfócitos",
  diagnósticoMonócitos: "Monócitos",
  diagnósticoEosinófilos: "Eosinófilos",
  diagnósticoBasófilos: "Basófilos",
  diagnósticoPlaquetas: "Contagem de plaquetas",
};

export const questionsAndResponsesForm100001 = {
  burnoutClassification: [
    "grau mínimo de ansiedade",
    "ansiedade leve",
    "ansiedade moderada",
    "ansiedade severa",
    "Classificação não disponível",
  ],
};

export const questionsAndResponsesForm100001Titles = {
  burnoutClassification: "Classificação Ansiedade - Beck",
};

export const questionsAndResponsesForm100002 = {
  burnoutClassification: [
    "o indivíduo não está deprimido",
    "depressão leve a moderada",
    "depressão moderada a severa ",
    "depressão severa",
    "severidade dos sintomas depressivos",
  ],
};

export const questionsAndResponsesForm100002Titles = {
  burnoutClassification: "Classificação Depressão - Beck",
};

export const questionsAndResponsesForm100003 = {
  burnoutClassification: [
    "o indivíduo não está com sofrimento mental",
    "está comprovado sofrimento mental",
  ],
};

export const questionsAndResponsesForm100003Titles = {
  burnoutClassification: "Classificação Sofrimento Mental",
};
