import React from "react";
import { useNavigate } from "react-router-dom";

import image22 from "../../../Images/SiasNormal.png";
import image23 from "../../../Images/siasKids.png";
import {
  PageWrapper,
  WelcomeSection,
  WelcomeText,
  WelcomeImage,
  MainContent,
  CardContainer,
  Card,
  CardImage,
} from "./styles";
import sias3 from "../../../Images/sias3.png";

const images = [image22, image23];
const cardTitles = ["SIAS", "SIAS KIDS"];

const EscolherSias = () => {
  const navigate = useNavigate();

  const handleCardClick = (cardNumber) => {
    if (cardNumber === 1) {
      navigate(`/plataforma/`);
    } else if (cardNumber === 2) {
      navigate(`/plataforma/`);
    }
  };

  const renderCards = () => {
    return images.map((image, index) => (
      <Card key={index} onClick={() => handleCardClick(index + 1)}>
        <CardImage src={image} alt={`Card ${index + 1}`} />
        <h4>{cardTitles[index]}</h4>
      </Card>
    ));
  };

  return (
    <PageWrapper>
      <WelcomeSection>
        <WelcomeImage src={sias3} alt="sias3" />
        <WelcomeText>
          <h1>Bem-vindo</h1>
        </WelcomeText>
      </WelcomeSection>
      <MainContent>
        <CardContainer>{renderCards()}</CardContainer>
      </MainContent>
    </PageWrapper>
  );
};

export default EscolherSias;
