import React from "react";
import styled from "styled-components";

interface FormGroupProps {
  children: React.ReactNode;
}

const FormGroupStyles = styled.div`
  margin-bottom: 20px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    text-align: right;
    width: 90vw;
    margin-left: 2vw;
  }
`;

const FormGroup: React.FC<FormGroupProps> = ({ children }) => {
  return <FormGroupStyles>{children}</FormGroupStyles>;
};

export default FormGroup;
