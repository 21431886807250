import React from "react";
import { useNavigate } from "react-router-dom";

import image22 from "../../../Images/image22.png";
import image23 from "../../../Images/image23.jpg";
import {
  MainContent,
  CardContainer,
  Card,
  CardImage,
  CardText,
  TextAcess,
  ContentTextCard,
  WelcomeText,
} from "./styles";

import HeaderQuestionarios from "../../../Components/headerQuestionarios";

const images = [image22, image23];
const cardTitles = ["HÁBITOS DE VIDA", "AUTOMEDICAÇÃO"];

const Form4 = () => {
  const navigate = useNavigate();

  const handleCardClick = (cardNumber) => {
    if (cardNumber === 1) {
      navigate(`/plataforma/Habitos-de-vida`);
    } else if (cardNumber === 2) {
      navigate(`/plataforma/Automedicação`);
    }
  };

  const renderCards = () => {
    return images.map((image, index) => (
      <Card key={index} onClick={() => handleCardClick(index + 1)}>
        <ContentTextCard>
          <CardImage src={image} alt={`Card ${index + 1}`} />
          <CardText>{cardTitles[index]}</CardText>
          <TextAcess>Acesse aqui</TextAcess>
        </ContentTextCard>
      </Card>
    ));
  };

  return (
    <>
      <HeaderQuestionarios />
      <MainContent>
        <WelcomeText>Saúde e qualidade de vida</WelcomeText>
        <CardContainer>{renderCards()}</CardContainer>
      </MainContent>
    </>
  );
};

export default Form4;
