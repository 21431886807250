import React from "react";
import styled from "styled-components";

import sias3 from "../../Images/logo-IamLearning.png";

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: row;
  background-image: linear-gradient(to left, #2e19bd, #1f1087);
  height: 10vh;
  position: relative;
  max-width: 100vw;
  justify-content: space-between;
  align-items: center;
  color: white;

  @media (max-width: 768px) {
    height: 15vh;
  }
`;

const WelcomeImage = styled.img`
  width: 7vw;
  height: 6.92vh;
  margin-left: 50px;

  @media (max-width: 768px) {
    height: 8vh;
    margin-left: 10px;
    width: 25vw;
  }
`;

const HeaderGeral = () => {
  return (
    <HeaderContainer>
      <WelcomeImage src={sias3} alt="sias3" />
    </HeaderContainer>
  );
};

export default HeaderGeral;
