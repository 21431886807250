import React from "react";
import styled from "styled-components";
import { CaretLeft } from "@phosphor-icons/react";

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  background-color: #b8b8b8;
  color: black;
  border-radius: 5px;
  border: none;
  width: 9.12vw;
  height: 30px;
  font-size: 15px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  &:hover {
    transform: scale(1.09);
  }

  @media (max-width: 768px) {
    width: 15vw;
   
  }
`;

interface ButtonSendProps {
  onClick?: () => void;
}

const ButtonReturn: React.FC<ButtonSendProps> = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <CaretLeft size={14} />
      <>Voltar</>
    </Button>
  );
};

export default ButtonReturn;
