import React from "react";
import { PageWrapper } from "../../Components/styles-all";
import Header from "../../Components/header";

import ComponenteComPermissao from "../../Fuctions/Permissão";
import MenuFull from "../../Acessos/full";
import MenuAvaliador1 from "../../Acessos/avaliador1";
import MenuAvaliador2 from "../../Acessos/avaliador2";
import MenuAvaliador3 from "../../Acessos/avaliador3";
import MenuAvaliador4 from "../../Acessos/avaliador4";

const Home = () => {
  return (
    <PageWrapper>
      <Header />
      <ComponenteComPermissao permissaoNecessaria="full">
        <MenuFull />
      </ComponenteComPermissao>
      <ComponenteComPermissao permissaoNecessaria="avaliador1">
        <MenuAvaliador1 />
      </ComponenteComPermissao>
      <ComponenteComPermissao permissaoNecessaria="avaliador2">
        <MenuAvaliador2 />
      </ComponenteComPermissao>
      <ComponenteComPermissao permissaoNecessaria="avaliador3">
        <MenuAvaliador3 />
      </ComponenteComPermissao>
      <ComponenteComPermissao permissaoNecessaria="avaliador4">
        <MenuAvaliador4 />
      </ComponenteComPermissao>
    </PageWrapper>
  );
};

export default Home;