import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../../../Supabase";
import HeaderGeral from "../../../Components/headerGeral";

import {
  Container,
  FormContainer,
  FormLabel,
  FormHeader,
  FormInput,
  DivInputs,
  Table,
  TableTD,
  TableTH,
} from "../../../Components/styles-all";
import { ToastContainer, toast } from "react-toastify";

import ButtonReturn from "../../../Components/button-return";
import ButtonSend from "../../../Components/button-send";
import FormGroup from "../../../Components/form-group";

const FormBioimpedancia = () => {
  const [isValid, setIsValid] = useState(true);
  const [formData, setFormData] = useState({
    id: "",
    gorduracorporal: "",
    imc: "",
    gorduravisceral: "",
    massamuscular: "",
    massaossea: "",
    aguacorporal: "",
    pernadireitaadiposa: "",
    pernaesquerdaadiposa: "",
    bracodireitoadiposa: "",
    bracoesquerdaadiposa: "",
    troncoadiposa: "",
    mediacorporaltotaladiposa: "",
    pernadireitanaoadiposa: "",
    pernaesquerdanaoadiposa: "",
    bracodireitonaoadiposa: "",
    bracoesquerdanaoadiposa: "",
    tronconaoadiposa: "",
    mediacorporaltotalnaoadiposa: "",
    pernadireitamuscular: "",
    pernaesquerdamuscular: "",
    bracodireitomuscular: "",
    bracoesquerdomuscular: "",
    troncomuscular: "",
    mediacorporaltotalmuscular: "",
    pernadireitagordura: "",
    pernaesquerdagordura: "",
    bracodireitogordura: "",
    bracoesquerdogordura: "",
    troncogordura: "",
    mediacorporaltotalgordura: "",
  });

  const handleCPFChange = (e) => {
    const cpf = formatCPF(e.target.value);
    setFormData((prevData) => ({ ...prevData, id: cpf }));

    const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    setIsValid(regex.test(cpf));
  };

  const formatCPF = (cpf) => {
    let value = cpf.replace(/\D/g, "");
    if (value.length > 3) value = `${value.slice(0, 3)}.${value.slice(3)}`;
    if (value.length > 7) value = `${value.slice(0, 7)}.${value.slice(7)}`;
    if (value.length > 11)
      value = `${value.slice(0, 11)}-${value.slice(11, 13)}`;
    return value;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  console.log([formData]);

  const handleSubmit = async () => {
    try {
      const { error } = await supabase.from("bioimpedancia").insert([formData]);

      if (error) throw error;
      toast.success("Dados enviados com sucesso!");
    } catch (error) {
      toast.error("Erro ao enviar dados: " + error.message);
    }
  };

  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate("/plataforma/");
  };

  return (
    <>
      <HeaderGeral />
      <Container>
        <FormContainer>
          <FormHeader>
            <h2>BIOIPEDÂNCIA</h2>
          </FormHeader>

          <FormGroup>
            <FormLabel>Nº de CPF :</FormLabel>
            <FormInput
              type="text"
              value={formData.id}
              style={isValid ? {} : { borderColor: "red" }}
              customWidth="25vw"
              maxLength="14"
              onChange={handleCPFChange}
            />
            {!isValid && <p style={{ color: "red" }}>CPF inválido!</p>}
          </FormGroup>

          <DivInputs>
            <FormGroup>
              <FormLabel>Gordura Corporal</FormLabel>
              <FormInput
                name="gorduracorporal"
                value={formData.gorduracorporal}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>IMC</FormLabel>
              <FormInput
                name="imc"
                value={formData.imc}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Gordura Visceral</FormLabel>
              <FormInput
                name="gorduravisceral"
                value={formData.gorduravisceral}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Massa Muscular</FormLabel>
              <FormInput
                name="massamuscular"
                value={formData.massamuscular}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Massa Óssea</FormLabel>
              <FormInput
                name="massaossea"
                value={formData.massaossea}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Água Corporal</FormLabel>
              <FormInput
                name="aguacorporal"
                value={formData.aguacorporal}
                onChange={handleChange}
              />
            </FormGroup>
          </DivInputs>

          <FormHeader>Distribuição de Segmento</FormHeader>

          <Table>
            <tr>
              <TableTH></TableTH>
              <TableTH>Perna Direita</TableTH>
              <TableTH>Perna Esquerda</TableTH>
              <TableTH>Braço Direito</TableTH>
              <TableTH>Braço Esquerdo</TableTH>
              <TableTH>Tronco</TableTH>
              <TableTH>Média Corporal Total</TableTH>
            </tr>
            <tr>
              <TableTD>Massa Adiposa (kg)</TableTD>
              <TableTD>
                <FormInput
                  name="pernadireitaadiposa"
                  value={formData.pernadireitaadiposa}
                  onChange={handleChange}
                />
              </TableTD>
              <TableTD>
                <FormInput
                  name="pernaesquerdaadiposa"
                  value={formData.pernaesquerdaadiposa}
                  onChange={handleChange}
                />
              </TableTD>
              <TableTD>
                <FormInput
                  name="bracodireitoadiposa"
                  value={formData.bracodireitoadiposa}
                  onChange={handleChange}
                />
              </TableTD>
              <TableTD>
                <FormInput
                  name="bracoesquerdaadiposa"
                  value={formData.bracoesquerdaadiposa}
                  onChange={handleChange}
                />
              </TableTD>
              <TableTD>
                <FormInput
                  name="troncoadiposa"
                  value={formData.troncoadiposa}
                  onChange={handleChange}
                />
              </TableTD>
              <TableTD>
                <FormInput
                  name="mediacorporaltotaladiposa"
                  value={formData.mediacorporaltotaladiposa}
                  onChange={handleChange}
                />
              </TableTD>
            </tr>

            <tr>
              <TableTD>Massa não Adiposa (kg)</TableTD>
              <TableTD>
                <FormInput
                  name="pernadireitanaoadiposa"
                  value={formData.pernadireitanaoadiposa}
                  onChange={handleChange}
                />
              </TableTD>
              <TableTD>
                <FormInput
                  name="pernaesquerdanaoadiposa"
                  value={formData.pernaesquerdanaoadiposa}
                  onChange={handleChange}
                />
              </TableTD>
              <TableTD>
                <FormInput
                  name="bracodireitonaoadiposa"
                  value={formData.bracodireitonaoadiposa}
                  onChange={handleChange}
                />
              </TableTD>
              <TableTD>
                <FormInput
                  name="bracoesquerdanaoadiposa"
                  value={formData.bracoesquerdonaoadiposa}
                  onChange={handleChange}
                />
              </TableTD>
              <TableTD>
                <FormInput
                  name="tronconaoadiposa"
                  value={formData.tronconaoadiposa}
                  onChange={handleChange}
                />
              </TableTD>
              <TableTD>
                <FormInput
                  name="mediacorporaltotalnaoadiposa"
                  value={formData.mediacorporaltotalnaoadiposa}
                  onChange={handleChange}
                />
              </TableTD>
            </tr>
            <tr>
              <TableTD>Massa Muscular (kg)</TableTD>
              <TableTD>
                <FormInput
                  name="pernadireitamuscular"
                  value={formData.pernadireitamuscular}
                  onChange={handleChange}
                />
              </TableTD>
              <TableTD>
                <FormInput
                  name="pernaesquerdamuscular"
                  value={formData.pernaesquerdamuscular}
                  onChange={handleChange}
                />
              </TableTD>
              <TableTD>
                <FormInput
                  name="bracodireitomuscular"
                  value={formData.bracodireitomuscular}
                  onChange={handleChange}
                />
              </TableTD>
              <TableTD>
                <FormInput
                  name="bracoesquerdomuscular"
                  value={formData.bracoesquerdomuscular}
                  onChange={handleChange}
                />
              </TableTD>
              <TableTD>
                <FormInput
                  name="troncomuscular"
                  value={formData.troncomuscular}
                  onChange={handleChange}
                />
              </TableTD>
              <TableTD>
                <FormInput
                  name="mediacorporaltotalmuscular"
                  value={formData.mediacorporaltotalmuscular}
                  onChange={handleChange}
                />
              </TableTD>
            </tr>
            <tr>
              <TableTD>Nível de Gordura (%)</TableTD>
              <TableTD>
                <FormInput
                  name="pernadireitagordura"
                  value={formData.pernadireitagordura}
                  onChange={handleChange}
                />
              </TableTD>
              <TableTD>
                <FormInput
                  name="pernaesquerdagordura"
                  value={formData.pernaesquerdagordura}
                  onChange={handleChange}
                />
              </TableTD>
              <TableTD>
                <FormInput
                  name="bracodireitogordura"
                  value={formData.bracodireitogordura}
                  onChange={handleChange}
                />
              </TableTD>
              <TableTD>
                <FormInput
                  name="bracoesquerdogordura"
                  value={formData.bracoesquerdogordura}
                  onChange={handleChange}
                />
              </TableTD>
              <TableTD>
                <FormInput
                  name="troncogordura"
                  value={formData.troncogordura}
                  onChange={handleChange}
                />
              </TableTD>
              <TableTD>
                <FormInput
                  name="mediacorporaltotalgordura"
                  value={formData.mediacorporaltotalgordura}
                  onChange={handleChange}
                />
              </TableTD>
            </tr>
          </Table>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 10,
              marginTop: 20,
            }}
          >
            <ButtonReturn onClick={handleProfileClick} />

            <ButtonSend onClick={handleSubmit} />
          </div>
        </FormContainer>

        <div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      </Container>
    </>
  );
};

export default FormBioimpedancia;
