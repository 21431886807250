import React, { useState } from "react";
import supabase from "../../../Supabase";
import HeaderGeral from "../../../Components/headerGeral";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import { SuccessToast, ErrorToast } from "../../../Components/toastyfy";

import {
  Container,
  FormInput,
  FormContainer,
  FormHeader,
} from "../../../Components/styles-all";

import FormGroup from "../../../Components/form-group";
import FormLabel from "../../../Components/form-label";
import FormRadioGroup from "../../../Components/form-radio-group";
import FormRadio from "../../../Components/form-radio";
import ButtonReturn from "../../../Components/button-return";
import ButtonSend from "../../../Components/button-send";

const Form3 = () => {
  const [id, setId] = useState("");
  const [q1, setQ1] = useState("");
  const [q2, setQ2] = useState("");
  const [q3, setQ3] = useState("");
  const [q4, setQ4] = useState("");
  const [q5, setQ5] = useState("");
  const [q6, setQ6] = useState("");
  const [q7, setQ7] = useState("");
  const [q8, setQ8] = useState("");
  const [cidade, setCidade] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleCPFChange = (e) => {
    const cpf = formatCPF(e.target.value);
    setId(cpf);

    const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    setIsValid(regex.test(cpf));
  };

  const formatCPF = (cpf) => {
    let value = cpf.replace(/\D/g, "");
    if (value.length > 3) value = `${value.slice(0, 3)}.${value.slice(3)}`;
    if (value.length > 7) value = `${value.slice(0, 7)}.${value.slice(7)}`;
    if (value.length > 11)
      value = `${value.slice(0, 11)}-${value.slice(11, 13)}`;
    return value;
  };

  const handleCidadeChange = (e) => {
    setCidade(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      id,
      q1,
      q2,
      q3,
      q4,
      q5,
      q6,
      q7,
      q8,
      cidade,
    };

    const { error } = await supabase.from("caracHabVida").insert([formData]);

    if (error) {
      toast.error(
        <ErrorToast>
          <strong>Erro!</strong> Erro ao inserir os dados.
        </ErrorToast>
      );
    } else {
      toast.success(
        <SuccessToast>
          <strong>Sucesso!</strong> Dados inseridos com êxito.
        </SuccessToast>
      );
    }
  };

  const navigate = useNavigate();

  const handleProfileClick = () => {
    navigate("/plataforma/");
  };

  return (
    <>
      <HeaderGeral />
      <Container>
        <FormContainer>
          <FormHeader>
            <h2>CARACTERIZAÇÃO DOS HÁBITOS DE VIDA</h2>
          </FormHeader>

          <form onSubmit={handleSubmit}>
            <FormGroup>
              <FormLabel>Nº de CPF :</FormLabel>
              <FormInput
                type="text"
                value={id}
                maxLength="14"
                onChange={handleCPFChange}
                style={isValid ? {} : { borderColor: "red" }}
                customWidth="20.87vw"
              />
              {!isValid && <p style={{ color: "red" }}>CPF inválido!</p>}
            </FormGroup>

            <FormGroup>
              <FormLabel>Fumante ou ex-fumante</FormLabel>
              <FormRadioGroup>
                <FormRadio
                  name="q1"
                  value="Sim (fumante)"
                  checked={q1 === "Sim (fumante)"}
                  onChange={(e) => setQ1(e.target.value)}
                  label="Sim (fumante)"
                />

                <FormRadio
                  name="q1"
                  value="Sim (ex-fumante)"
                  checked={q1 === "Sim (ex-fumante)"}
                  onChange={(e) => setQ1(e.target.value)}
                  label="Sim (ex-fumante)"
                />

                <FormRadio
                  name="q1"
                  value="Não Fuma"
                  checked={q1 === "Não Fuma"}
                  onChange={(e) => setQ1(e.target.value)}
                  label="Não Fuma"
                />
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Consumo de álcool</FormLabel>
              <FormRadioGroup>
                <FormRadio
                  name="q2"
                  value="De uma a duas vezes por semana"
                  checked={q2 === "De uma a duas vezes por semana"}
                  onChange={(e) => setQ2(e.target.value)}
                  label="De uma a duas vezes por semana"
                />
                <FormRadio
                  name="q2"
                  value="De três a quatro vezes por semana"
                  checked={q2 === "De três a quatro vezes por semana"}
                  onChange={(e) => setQ2(e.target.value)}
                  label="De três a quatro vezes por semana"
                />
                <FormRadio
                  name="q2"
                  value="Mais de 4 vezes por semana"
                  checked={q2 === "Mais de 4 vezes por semana"}
                  onChange={(e) => setQ2(e.target.value)}
                  label="Mais de 4 vezes por semana"
                />
                <FormRadio
                  name="q2"
                  value="Não consumo bebida alcoólica"
                  checked={q2 === "Não consumo bebida alcoólica"}
                  onChange={(e) => setQ2(e.target.value)}
                  label="Não consumo bebida alcoólica"
                />
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Alimentação saudável</FormLabel>
              <FormRadioGroup>
                <FormRadio
                  name="q3"
                  value="Sim"
                  checked={q3 === "Sim"}
                  onChange={(e) => setQ3(e.target.value)}
                  label="Sim"
                />
                <FormRadio
                  name="q3"
                  value="Não"
                  checked={q3 === "Não"}
                  onChange={(e) => setQ3(e.target.value)}
                  label="Não"
                />
                <FormRadio
                  name="q3"
                  value="Não sei opinar sobre isso"
                  checked={q3 === "Não sei opinar sobre isso"}
                  onChange={(e) => setQ3(e.target.value)}
                  label="Não sei opinar sobre isso"
                />
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Dificuldades para dormir</FormLabel>
              <FormRadioGroup>
                <FormRadio
                  name="q4"
                  value="Sim"
                  checked={q4 === "Sim"}
                  onChange={(e) => setQ4(e.target.value)}
                  label="Sim"
                />
                <FormRadio
                  name="q4"
                  value="Não"
                  checked={q4 === "Não"}
                  onChange={(e) => setQ4(e.target.value)}
                  label="Não"
                />
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                Meio de transporte utilizado para ir ao trabalho
              </FormLabel>
              <FormRadioGroup>
                <FormRadio
                  name="q5"
                  value="Carro"
                  checked={q5 === "Carro"}
                  onChange={(e) => setQ5(e.target.value)}
                  label="Carro"
                />
                <FormRadio
                  name="q5"
                  value="Moto"
                  checked={q5 === "Moto"}
                  onChange={(e) => setQ5(e.target.value)}
                  label="Moto"
                />
                <FormRadio
                  name="q5"
                  value="Ônibus"
                  checked={q5 === "Ônibus"}
                  onChange={(e) => setQ5(e.target.value)}
                  label="Ônibus"
                />
                <FormRadio
                  name="q5"
                  value="Outros"
                  checked={q5 === "Outros"}
                  onChange={(e) => setQ5(e.target.value)}
                  label="Outros"
                />
              </FormRadioGroup>
            </FormGroup>
            <FormGroup>
              <FormLabel>Possui moradia própria</FormLabel>
              <FormRadioGroup>
                <FormRadio
                  name="q6"
                  value="Sim"
                  checked={q6 === "Sim"}
                  onChange={(e) => setQ6(e.target.value)}
                  label="Sim"
                />
                <FormRadio
                  name="q6"
                  value="Não"
                  checked={q6 === "Não"}
                  onChange={(e) => setQ6(e.target.value)}
                  label="Não"
                />
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Reside em Teresina?</FormLabel>
              <FormRadioGroup>
                <FormRadio
                  name="q8"
                  value="Sim"
                  checked={q8 === "Sim"}
                  onChange={(e) => setQ8(e.target.value)}
                  label="Sim"
                />
                <FormRadio
                  name="q8"
                  value="Não"
                  checked={q8 === "Não"}
                  onChange={(e) => setQ8(e.target.value)}
                  label="Não"
                />
                <p>Se não, informe a cidade</p>
                <FormInput
                  type="text"
                  name="cidade"
                  value={cidade}
                  onChange={handleCidadeChange}
                  customWidth="15vw"
                />
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>Sua residência fica em qual zona da cidade</FormLabel>
              <FormRadioGroup>
                <FormRadio
                  name="q7"
                  value="Centro"
                  checked={q7 === "Centro"}
                  onChange={(e) => setQ7(e.target.value)}
                  label="Centro"
                />
                <FormRadio
                  name="q7"
                  value="Leste"
                  checked={q7 === "Leste"}
                  onChange={(e) => setQ7(e.target.value)}
                  label="Leste"
                />
                <FormRadio
                  name="q7"
                  value="Sudeste"
                  checked={q7 === "Sudeste"}
                  onChange={(e) => setQ7(e.target.value)}
                  label="Sudeste"
                />
                <FormRadio
                  name="q7"
                  value="Norte"
                  checked={q7 === "Norte"}
                  onChange={(e) => setQ7(e.target.value)}
                  label="Norte"
                />
                <FormRadio
                  name="q7"
                  value="Sul"
                  checked={q7 === "Sul"}
                  onChange={(e) => setQ7(e.target.value)}
                  label="Sul"
                />
                <FormRadio
                  name="q7"
                  value="Outra"
                  checked={q7 === "Outra"}
                  onChange={(e) => setQ7(e.target.value)}
                  label="Outra"
                />
              </FormRadioGroup>
            </FormGroup>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 10,
                marginRight: 20,
                marginBottom: 30,
                marginTop: 30,
              }}
            >
              <ButtonReturn onClick={handleProfileClick} />

              <ButtonSend onClick={handleSubmit} />
            </div>
          </form>
        </FormContainer>
        <div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      </Container>
    </>
  );
};

export default Form3;
