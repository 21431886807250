import { Route, Routes } from "react-router-dom";
import Login from "../Pages/Login";
import Register from "../Pages/registro/index.js";

export const AuthRouter = () => {
  return (
    <Routes>
      <Route exact path="" element={<Login />} />
      <Route exact path="/registro" element={<Register />} />
    </Routes>
  );
};
