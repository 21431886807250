import { createClient } from '@supabase/supabase-js';

const supabaseUrl: string = process.env.REACT_APP_SUPABASE_URL as string;
const supabaseKey: string = process.env.REACT_APP_SUPABASE_KEY as string;

if (!supabaseUrl || !supabaseKey) {
  throw new Error('Supabase URL and Key must be defined in the environment variables.');
}

const supabase = createClient(supabaseUrl, supabaseKey);

export default supabase;
