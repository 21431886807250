import React, { useState, useEffect } from "react";
import supabase from "../../../Supabase";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { SuccessToast, ErrorToast } from "../../../Components/toastyfy";

import HeaderGeral from "../../../Components/headerGeral";
import {
  Container,
  FormInput,
  FormContainer,
  FormHeader,
  Table,
  TableTD,
  TableTH,
} from "../../../Components/styles-all";

import { FormRadio } from "./styles";

import ButtonReturn from "../../../Components/button-return";
import ButtonSend from "../../../Components/button-send";
import FormGroup from "../../../Components/form-group";
import FormLabel from "../../../Components/form-label";

const Form13 = () => {
  const [q1a, setQ1a] = useState("0");
  const [q2a, setQ2a] = useState("0");
  const [q3a, setQ3a] = useState("0");
  const [q4a, setQ4a] = useState("0");
  const [q5a, setQ5a] = useState("0");
  const [q6a, setQ6a] = useState("0");
  const [q7a, setQ7a] = useState("0");
  const [q8a, setQ8a] = useState("0");
  const [q9a, setQ9a] = useState("0");
  const [q10a, setQ10a] = useState("0");
  const [q11a, setQ11a] = useState("0");
  const [q12a, setQ12a] = useState("0");
  const [q13a, setQ13a] = useState("0");
  const [q14a, setQ14a] = useState("0");
  const [q15a, setQ15a] = useState("0");
  const [q16a, setQ16a] = useState("0");
  const [q17a, setQ17a] = useState("0");
  const [q18a, setQ18a] = useState("0");
  const [q19a, setQ19a] = useState("0");
  const [q20a, setQ20a] = useState("0");
  const [q21a, setQ21a] = useState("0");
  const [q22a, setQ22a] = useState("0");
  const [burnoutTotal, setBurnoutTotal] = useState(null);
  const [burnoutClassification, setBurnoutClassification] = useState(" ");
  const [calculated, setCalculated] = useState(false);
  const [id, setId] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleCPFChange = (e) => {
    const cpf = formatCPF(e.target.value);
    setId(cpf);

    const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    setIsValid(regex.test(cpf));
  };

  const formatCPF = (cpf) => {
    let value = cpf.replace(/\D/g, ""); // Mantém apenas números
    if (value.length > 3) value = `${value.slice(0, 3)}.${value.slice(3)}`;
    if (value.length > 7) value = `${value.slice(0, 7)}.${value.slice(7)}`;
    if (value.length > 11)
      value = `${value.slice(0, 11)}-${value.slice(11, 13)}`;
    return value;
  };

  useEffect(() => {
    const answersTo22Questions = [
      q1a,
      q2a,
      q3a,
      q4a,
      q5a,
      q6a,
      q7a,
      q8a,
      q9a,
      q10a,
      q11a,
      q12a,
      q13a,
      q14a,
      q15a,
      q16a,
      q17a,
      q18a,
      q19a,
      q20a,
      q21a,
      q22a,
    ];
    const total = calcularBurnoutTotal(answersTo22Questions);
    const classification = classifyBurnoutLevel(total);
    setBurnoutTotal(total);
    setBurnoutClassification(classification);
    setCalculated(true);
  }, [
    q1a,
    q2a,
    q3a,
    q4a,
    q5a,
    q6a,
    q7a,
    q8a,
    q9a,
    q10a,
    q11a,
    q12a,
    q13a,
    q14a,
    q15a,
    q16a,
    q17a,
    q18a,
    q19a,
    q20a,
    q21a,
    q22a,
  ]);

  const calcularBurnoutTotal = (answers) => {
    const valoresNumericos = answers.map((answer) => parseFloat(answer));

    if (valoresNumericos.some(isNaN)) {
      return null;
    }

    const total = valoresNumericos.reduce((sum, value) => sum + value, 0);
    return total;
  };

  const classifyBurnoutLevel = (total) => {
    if (total >= 0 && total <= 40) {
      return "Baixa probabilidade de desenvolvimento";
    } else if (total >= 41 && total <= 60) {
      return "Fase inicial de desenvolvimento";
    } else if (total >= 61 && total <= 80) {
      return "Fase intermediária de desenvolvimento";
    } else if (total >= 81 && total <= 300) {
      return "Fase avançada de desenvolvimento";
    } else {
      return "Classificação não disponível";
    }
  };

  const handleBurnoutCalculation = () => {
    const answersTo22Questions = [
      q1a,
      q2a,
      q3a,
      q4a,
      q5a,
      q6a,
      q7a,
      q8a,
      q9a,
      q10a,
      q11a,
      q12a,
      q13a,
      q14a,
      q15a,
      q16a,
      q17a,
      q18a,
      q19a,
      q20a,
      q21a,
      q22a,
    ];
    const total = calcularBurnoutTotal(answersTo22Questions);

    const classification = classifyBurnoutLevel(total);

    setBurnoutTotal(total);
    setBurnoutClassification(classification);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      e.preventDefault();

      const formData = {
        id,
        burnoutClassification,
      };
      const { data, error } = await supabase
        .from("avaliEsgoPro")
        .insert([formData]);

      if (error) {
        toast.error(
          <ErrorToast>
            <strong>Erro!</strong> Erro ao inserir os dados.
          </ErrorToast>
        );
      } else {
        toast.success(
          <SuccessToast>
            <strong>Sucesso!</strong> Dados inseridos com êxito.
          </SuccessToast>
        );
      }
    } catch (e) {
      console.error("Erro inesperado:", e);
    }
  };

  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate("/plataforma/");
  };

  return (
    <>
      <HeaderGeral />
      <Container>
        <FormContainer>
          <FormHeader>
            <h2>INVENTÁRIO DE AVALIAÇÃO DO ESGOTAMENTO PROFISSIONAL </h2>
          </FormHeader>

          <form onSubmit={handleSubmit}>
            <FormGroup>
              <FormLabel>
                Por favor, leia atentamente cada um dos itens a seguir e
                responda se já experimentou o que é relatado, em relação a seu
                trabalho. Não existem respostas certas ou erradas e não haverá
                nenhum dado que possa lhe identificar. Neste primeiro momento
                pedimos que você leia com atenção as questões abaixo e responda
                conforme a legenda, com qual frequência estes eventos ocorrem.
                Pontue de 0-6 os itens a seguir conforme seus sentimentos e
                sintomas.
              </FormLabel>
            </FormGroup>

            <FormGroup>
              <FormLabel>Nº de CPF :</FormLabel>
              <FormInput
                type="text"
                value={id}
                maxLength="14"
                onChange={handleCPFChange}
                style={isValid ? {} : { borderColor: "red" }}
                customWidth="21.87vw"
              />
              {!isValid && <p style={{ color: "red" }}>CPF inválido!</p>}
            </FormGroup>

            <Table>
              <tr>
                <TableTH></TableTH>
                <TableTH>Nunca</TableTH>
                <TableTH>Uma vez ao ano ou menos</TableTH>
                <TableTH>Uma vez ao mês ou menos</TableTH>
                <TableTH>Algumas vezes ao mês</TableTH>
                <TableTH>Uma vez por semana</TableTH>
                <TableTH>Algumas vezes por semana</TableTH>
                <TableTH>Todos os dias</TableTH>
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>
                    Sinto-me esgotado (a) ao final de um dia de trabalho
                  </FormLabel>
                </TableTD>
                {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q1a"
                      value={value.toString()}
                      onChange={(e) => setQ1a(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>
                    Sinto-me como se estivesse no meu limite
                  </FormLabel>
                </TableTD>
                {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q2a"
                      value={value.toString()}
                      onChange={(e) => setQ2a(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>
                    Sinto-me emocionalmente exausto/a com o meu trabalho
                  </FormLabel>
                </TableTD>
                {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q3a"
                      value={value.toString()}
                      onChange={(e) => setQ3a(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>Sinto-me frustrado/a com o meu trabalho</FormLabel>
                </TableTD>
                {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q4a"
                      value={value.toString()}
                      onChange={(e) => setQ4a(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>Sinto-me esgotado/a com o meu trabalho</FormLabel>
                </TableTD>
                {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q5a"
                      value={value.toString()}
                      onChange={(e) => setQ5a(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>
                    Sinto que estou trabalhando demais neste emprego
                  </FormLabel>
                </TableTD>
                {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q6a"
                      value={value.toString()}
                      onChange={(e) => setQ6a(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>
                    Trabalhar diretamente com pessoas me deixa muito
                    estressado/a
                  </FormLabel>
                </TableTD>
                {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q7a"
                      value={value.toString()}
                      onChange={(e) => setQ7a(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>
                    Trabalhar com pessoas o dia todo me exige um grande esforço
                  </FormLabel>
                </TableTD>
                {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q8a"
                      value={value.toString()}
                      onChange={(e) => setQ8a(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>
                    Sinto-me cansado/a quando me levanto de manhã e tenho que
                    encarar outro dia de trabalho
                  </FormLabel>
                </TableTD>
                {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q9a"
                      value={value.toString()}
                      onChange={(e) => setQ9a(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>Sinto-me cheio/a de alegria</FormLabel>
                </TableTD>
                {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q10a"
                      value={value.toString()}
                      onChange={(e) => setQ10a(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>
                    Sinto-me estimulado/a depois de trabalhar em contato com a
                    comunidade escolar (Professores, Funcionários, Alunos)
                  </FormLabel>
                </TableTD>
                {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q11a"
                      value={value.toString()}
                      onChange={(e) => setQ11a(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>
                    Sinto que posso criar um ambiente tranquilo para a
                    comunidade escolar (Professores, Funcionários, Alunos)
                  </FormLabel>
                </TableTD>
                {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q12a"
                      value={value.toString()}
                      onChange={(e) => setQ12a(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>
                    Sinto que influencio positivamente a vida dos outros através
                    do meu trabalho
                  </FormLabel>
                </TableTD>
                {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q13a"
                      value={value.toString()}
                      onChange={(e) => setQ13a(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>
                    Lido de forma adequada com os problemas da comunidade escolar (Professores, Funcionários, Alunos)
                  </FormLabel>
                </TableTD>
                {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q14a"
                      value={value.toString()}
                      onChange={(e) => setQ14a(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>
                    Posso entender com facilidade o que sentem a comunidade escolar (Professores, Funcionários, Alunos)
                  </FormLabel>
                </TableTD>
                {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q15a"
                      value={value.toString()}
                      onChange={(e) => setQ15a(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>
                    Sinto que sei tratar de forma tranquila os problemas
                    emocionais no meu trabalho
                  </FormLabel>
                </TableTD>
                {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q16a"
                      value={value.toString()}
                      onChange={(e) => setQ16a(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>
                    Tenho conseguido muitas realizações em minha profissão
                  </FormLabel>
                </TableTD>
                {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q17a"
                      value={value.toString()}
                      onChange={(e) => setQ17a(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>
                    Sinto que a comunidade escolar (Professores, Funcionários, Alunos) culpam-me por alguns dos seus
                    problemas
                  </FormLabel>
                </TableTD>
                {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q18a"
                      value={value.toString()}
                      onChange={(e) => setQ18a(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>
                    Sinto que trato alguns da comunidade escolar (Professores, Funcionários, Alunos) como se fossem objetos
                  </FormLabel>
                </TableTD>
                {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q19a"
                      value={value.toString()}
                      onChange={(e) => setQ19a(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>
                    Tenho me tornado mais insensível com as pessoas desde que
                    exerço este trabalho
                  </FormLabel>
                </TableTD>
                {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q20a"
                      value={value.toString()}
                      onChange={(e) => setQ20a(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>
                    Me preocupo realmente com o que ocorre com alguns da
                    meus comunidade escolar (Professores, Funcionários, Alunos)
                  </FormLabel>
                </TableTD>
                {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q21a"
                      value={value.toString()}
                      onChange={(e) => setQ21a(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>

              <tr>
                <TableTD>
                  <FormLabel>
                    Preocupa-me o fato de que este trabalho esteja me
                    endurecendo emocionalmente
                  </FormLabel>
                </TableTD>
                {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                  <TableTD key={value}>
                    <FormRadio
                      type="radio"
                      name="q22a"
                      value={value.toString()}
                      onChange={(e) => setQ22a(e.target.value)}
                    />
                  </TableTD>
                ))}
              </tr>
            </Table>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 10,
                marginRight: 20,
                marginBottom: 30,
                marginTop: 30,
              }}
            >
              <ButtonReturn onClick={handleProfileClick} />
              <ButtonSend onClick={handleSubmit} />
            </div>
          </form>
        </FormContainer>
        <div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      </Container>
    </>
  );
};

export default Form13;
