import React from "react";
import {
  MainContent,
  PageWrapper,
  CardContainer,
  Card,
  CardImage,
  CardText,
  ContentTextCard,
  TextAcess,
} from "../../Components/styles-all";
import { useNavigate } from "react-router-dom";

import image1 from "../../Images/image1.png";
import image2 from "../../Images/image22.png";
import image13 from "../../Images/image13.png";

const images = [image1, image2, image13];

const cardTitles = [
  "QUESTIONÁRIO SOCIODEMOGRÁFICO",
  "HÁBITOS DE VIDA",
  "AVALIAÇÃO DO ESGOTAMENTO PROFISSIONAL",
];

const MenuAvaliador1 = () => {
  const navigate = useNavigate();

  const handleCardClick = (cardNumber) => {
    if (cardNumber === 1) {
      navigate(`/plataforma/Sociodemografico-Escolar`);
    } else if (cardNumber === 2) {
      navigate(`/plataforma/Habitos-de-vida`);
    } else if (cardNumber === 3) {
      navigate(`/plataforma/Avaliação-do-esgotamento-profissional`);
    }
  };

  const renderCards = () => {
    return images.map((image, index) => (
      <Card key={index} onClick={() => handleCardClick(index + 1)}>
        <ContentTextCard>
          <CardImage src={image} alt={`Card ${index + 1}`} />
          <CardText>{cardTitles[index]}</CardText>
          <TextAcess>Acesse aqui</TextAcess>
        </ContentTextCard>
      </Card>
    ));
  };

  return (
    <PageWrapper>
      <MainContent>
        <CardContainer>{renderCards()}</CardContainer>
      </MainContent>
    </PageWrapper>
  );
};

export default MenuAvaliador1;
