import React from "react";
import styled from "styled-components";

interface FormRadioGroupProps {
  children: React.ReactNode;
}

const FormRadioGroupStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FormRadioGroup: React.FC<FormRadioGroupProps> = ({ children }) => {
  return <FormRadioGroupStyles>{children}</FormRadioGroupStyles>;
};

export default FormRadioGroup;
