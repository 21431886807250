import React, { useState } from "react";
import supabase from "../../../Supabase";
import { useNavigate } from "react-router-dom";
import {
  Container,
  FormContainer,
  FormInput,
  FormCheckboxGroup,
  FormHeader,
  FormCheckboxLabel,
  FormCheckbox,
  DivInputs,
} from "../../../Components/styles-all";
import { ToastContainer, toast } from "react-toastify";
import { SuccessToast, ErrorToast } from "../../../Components/toastyfy";
import HeaderGeral from "../../../Components/headerGeral";

import ButtonSend from "../../../Components/button-send";
import ButtonReturn from "../../../Components/button-return";
import FormGroup from "../../../Components/form-group";
import FormLabel from "../../../Components/form-label";
import FormSelect from "../../../Components/form-select";

function Form1() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [gender, setGender] = useState("");
  const [ageRange, setAgeRange] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [numberOfChildren, setNumberOfChildren] = useState("");
  const [educationLevel, setEducationLevel] = useState("");
  const [rendaFamiliar, setRendaFamiliar] = useState("");
  const [race, setRace] = useState("");
  const [workHours, setWorkHours] = useState("");
  const [workDays, setWorkDays] = useState("");
  const [otherPaidActivity, setOtherPaidActivity] = useState("");
  const [numberOfOtherPaidActivities, setNumberOfOtherPaidActivities] =
    useState("");
  const [absencesLastYear, setAbsencesLastYear] = useState("");
  const [, setSubmitted] = useState(false);
  const [afastamentoCausas, setAfastamentoCausas] = useState([]);
  const [showAdditionalForm, setShowAdditionalForm] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [email, setEmail] = useState("");
  const [isEmailValid, setEmailValid] = useState(true);

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setEmailValid(isValidEmail(newEmail));
  };

  const handleCPFChange = (e) => {
    const cpf = formatCPF(e.target.value);
    setId(cpf);

    const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    setIsValid(regex.test(cpf));
  };

  const formatCPF = (cpf) => {
    let value = cpf.replace(/\D/g, "");
    if (value.length > 3) value = `${value.slice(0, 3)}.${value.slice(3)}`;
    if (value.length > 7) value = `${value.slice(0, 7)}.${value.slice(7)}`;
    if (value.length > 11)
      value = `${value.slice(0, 11)}-${value.slice(11, 13)}`;
    return value;
  };

  const handleAfastamentoCausasChange = (event) => {
    const selectedCausas = [...afastamentoCausas];
    const causa = event.target.value;

    if (event.target.checked) {
      selectedCausas.push(causa);
    } else {
      const index = selectedCausas.indexOf(causa);
      if (index !== -1) {
        selectedCausas.splice(index, 1);
      }
    }

    setAfastamentoCausas(selectedCausas);
  };

  const validateFormData = () => {
    if (!name.trim()) {
      return "Por favor, insira um nome válido.";
    }
    if (!id.trim()) {
      return "Por favor, insira um CPF válido.";
    }
    if (!gender) {
      return "Por favor, selecione um sexo.";
    }
    if (!ageRange) {
      return "Por favor, selecione uma faixa etária.";
    }
    if (!race) {
      return "Por favor, selecione uma raça/cor.";
    }
    if (!maritalStatus) {
      return "Por favor, selecione um estado civil.";
    }
    if (!numberOfChildren) {
      return "Por favor, selecione o número de filhos.";
    }

    if (!educationLevel) {
      return "Por favor, selecione a escolaridade.";
    }

    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationError = validateFormData();
    if (validationError) {
      alert(validationError);
      return;
    }

    const formData = {
      name,
      id,
      gender,
      ageRange,
      race,
      maritalStatus,
      numberOfChildren,
      educationLevel,
      rendaFamiliar,
      workDays,
      otherPaidActivity,
      numberOfOtherPaidActivities,
      absencesLastYear,
      email,
      afastamentoCausas,
    };

    const { data, error } = await supabase
      .from("sociodemograficoCivil")
      .insert([formData]);

    if (error) {
      if (error.code === "23505") {
        toast.error(
          <ErrorToast>
            <strong>Erro!</strong> A pesquisa já foi realizada com esse CPF
          </ErrorToast>
        );
      } else {
        toast.error(
          <ErrorToast>
            <strong>Erro!</strong> Erro ao inserir os dados.
          </ErrorToast>
        );
      }
    } else {
      setSubmitted(true);
      toast.success(
        <SuccessToast>
          <strong>Sucesso!</strong> Dados inseridos com êxito.
        </SuccessToast>
      );
    }
  };

  const handleAbsencesChange = (e) => {
    const selectedValue = e.target.value;
    setAbsencesLastYear(selectedValue);

    if (selectedValue !== "Nenhuma vez") {
      setShowAdditionalForm(true);
    } else {
      setShowAdditionalForm(false);
    }
  };

  let formToShow = null;

  const handleProfileClick = () => {
    navigate("/plataforma/Sociodemografico");
  };

  return (
    <>
      <HeaderGeral />
      <Container>
        <FormContainer>
          <FormHeader>
            <h2>QUESTIONÁRIO SOCIODEMOGRÁFICO &#8594; CIVIL</h2>
          </FormHeader>

          <form onSubmit={handleSubmit}>
            <DivInputs>
              <FormGroup>
                <FormLabel>Nome:</FormLabel>
                <FormInput
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  customWidth="21.87vw"
                />
              </FormGroup>

              <FormGroup>
                <FormLabel>Nº de CPF :</FormLabel>
                <FormInput
                  type="text"
                  value={id}
                  maxLength="14"
                  onChange={handleCPFChange}
                  style={isValid ? {} : { borderColor: "red" }}
                />
                {!isValid && <p style={{ color: "red" }}>CPF inválido!</p>}
              </FormGroup>

              <FormGroup>
                <FormLabel>Seu Email :</FormLabel>
                <FormInput
                  type="text"
                  value={email}
                  onChange={handleEmailChange}
                  customWidth="21.87vw"
                />
                {!isEmailValid && (
                  <div style={{ color: "red" }}>
                    Por favor, insira um email válido.
                  </div>
                )}
              </FormGroup>

              <FormGroup>
                <FormLabel>Sexo:</FormLabel>
                <FormSelect
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  customWidth="10.87vw"
                  options={["Masculino", "Feminino"]}
                />
              </FormGroup>

              <FormGroup>
                <FormLabel>Raça/Cor:</FormLabel>
                <FormSelect
                  value={race}
                  onChange={(e) => setRace(e.target.value)}
                  options={["Preto", "Pardo", "Branco", "Indígina", "Amarelo"]}
                />
              </FormGroup>
            </DivInputs>

            <DivInputs>
              <FormGroup>
                <FormLabel>Faixa etária:</FormLabel>
                <FormSelect
                  value={ageRange}
                  onChange={(e) => setAgeRange(e.target.value)}
                  options={["20-30", "31-40", "41-50", "acima-50"]}
                />
              </FormGroup>

              <FormGroup>
                <FormLabel>Estado Civil:</FormLabel>
                <FormSelect
                  value={maritalStatus}
                  onChange={(e) => setMaritalStatus(e.target.value)}
                  options={[
                    "Solteiro",
                    "Casado",
                    "União Estável",
                    "Divorciado",
                    "Viúvo",
                  ]}
                />
              </FormGroup>

              <FormGroup>
                <FormLabel>Número de filhos:</FormLabel>
                <FormSelect
                  value={numberOfChildren}
                  onChange={(e) => setNumberOfChildren(e.target.value)}
                  options={[
                    "Nenhum",
                    "01 Filho",
                    "De 2 a 3 filhos",
                    "De 3 a 4 filhos",
                    "Mais de 4 filhos",
                  ]}
                />
              </FormGroup>
            </DivInputs>

            <DivInputs>
              <FormGroup>
                <FormLabel>Escolaridade:</FormLabel>
                <FormSelect
                  value={educationLevel}
                  onChange={(e) => setEducationLevel(e.target.value)}
                  options={[
                    "Ensino Médio",
                    "Ensino Superior Incompleto",
                    "Ensino Superior Completo",
                    "Pós-Graduação",
                    "Mestrado",
                    "Doutorado",
                  ]}
                />
              </FormGroup>

              <FormGroup>
                <FormLabel>Renda Familiar :</FormLabel>
                <FormSelect
                  value={rendaFamiliar}
                  onChange={(e) => setRendaFamiliar(e.target.value)}
                  options={[
                    "1 Salário Mínimo",
                    "1 a 3 salários",
                    "3 a 5 salários",
                    "5 a 10 salários",
                    "Mais de 10 salários",
                  ]}
                />
              </FormGroup>

              <FormGroup>
                <FormLabel>
                  Carga Horária de Trabalho Semanal (em horas):
                </FormLabel>
                <FormSelect
                  value={workHours}
                  onChange={(e) => setWorkHours(e.target.value)}
                  options={[
                    "32",
                    "36",
                    "40",
                    "44",
                    "48",
                    "50",
                    "54",
                    "acima-54",
                  ]}
                />
              </FormGroup>

              <FormGroup>
                <FormLabel>Período de trabalho por semana (em dias):</FormLabel>
                <FormSelect
                  value={workDays}
                  onChange={(e) => setWorkDays(e.target.value)}
                  options={["1-3", "4-5", "acima-5"]}
                />
              </FormGroup>
            </DivInputs>

            <DivInputs>
              <FormGroup>
                <FormLabel>Realiza mais de uma atividade remunerada?</FormLabel>
                <FormSelect
                  value={otherPaidActivity}
                  onChange={(e) => setOtherPaidActivity(e.target.value)}
                  options={["Sim", "Não"]}
                />
              </FormGroup>

              <FormGroup>
                <FormLabel>Quantidade de atividades remuneradas?</FormLabel>
                <FormSelect
                  value={numberOfOtherPaidActivities}
                  onChange={(e) =>
                    setNumberOfOtherPaidActivities(e.target.value)
                  }
                  options={["0", "1", "2", "3-mais"]}
                />
              </FormGroup>

              <FormGroup>
                <FormLabel>
                  Quantas vezes se afastou do trabalho (último ano):
                </FormLabel>
                <FormSelect
                  value={absencesLastYear}
                  onChange={handleAbsencesChange}
                  options={[
                    "Nenhuma vez",
                    "De 1 a 2 vezes",
                    "De 3 a 4 vezes",
                    "De 4 a 5 vezes",
                    "Mais de 5 vezes",
                  ]}
                />
              </FormGroup>
            </DivInputs>

            {showAdditionalForm && (
              <FormGroup>
                <FormCheckboxGroup>
                  <FormLabel>
                    Se houve afastamento do trabalho no último ano, qual a
                    causa? (marque mais de uma opção se necessário)
                  </FormLabel>
                  <FormCheckboxLabel>
                    <FormCheckbox
                      type="checkbox"
                      value="Doenças Infecciosas, parasitárias e virais"
                      onChange={handleAfastamentoCausasChange}
                      checked={afastamentoCausas.includes(
                        "Doenças Infecciosas, parasitárias e virais"
                      )}
                    />
                    Doenças Infecciosas, parasitárias e virais
                  </FormCheckboxLabel>

                  <FormCheckboxLabel>
                    <FormCheckbox
                      type="checkbox"
                      value="Neoplasia,doenças no sangue e endócrinas"
                      onChange={handleAfastamentoCausasChange}
                      checked={afastamentoCausas.includes(
                        "Neoplasia,doenças no sangue e endócrinas"
                      )}
                    />
                    Neoplasia (câncer), doenças no sangue e endócrinas
                    (hormônios)
                  </FormCheckboxLabel>

                  <FormCheckboxLabel>
                    <FormCheckbox
                      type="checkbox"
                      value="Transtornos mentais, psicológicos e comportamentais"
                      onChange={handleAfastamentoCausasChange}
                      checked={afastamentoCausas.includes(
                        "Transtornos mentais, psicológicos e comportamentais"
                      )}
                    />
                    Transtornos mentais, psicológicos e comportamentais, Ex:
                    depressão, ansiedade ou outras.
                  </FormCheckboxLabel>
                  <FormCheckboxLabel>
                    <FormCheckbox
                      type="checkbox"
                      value="Doenças do sistema Nervoso"
                      onChange={handleAfastamentoCausasChange}
                      checked={afastamentoCausas.includes(
                        "Doenças do sistema Nervoso"
                      )}
                    />
                    Doenças do sistema Nervoso
                  </FormCheckboxLabel>
                  <FormCheckboxLabel>
                    <FormCheckbox
                      type="checkbox"
                      value="Doenças nos olhos e/ou ouvido"
                      onChange={handleAfastamentoCausasChange}
                      checked={afastamentoCausas.includes(
                        "Doenças nos olhos e/ou ouvido"
                      )}
                    />
                    Doenças nos olhos e/ou ouvido, Ex: dores no ouvido,
                    conjuntivite ou outras.
                  </FormCheckboxLabel>
                  <FormCheckboxLabel>
                    <FormCheckbox
                      type="checkbox"
                      value="Doenças do aparelho circulatório"
                      onChange={handleAfastamentoCausasChange}
                      checked={afastamentoCausas.includes(
                        "Doenças do aparelho circulatório"
                      )}
                    />
                    Doenças do aparelho circulatório, Ex: Hipertensão Arterial
                    ou outras.
                  </FormCheckboxLabel>

                  <FormCheckboxLabel>
                    <FormCheckbox
                      type="checkbox"
                      value="Doenças do aparelho respiratório"
                      onChange={handleAfastamentoCausasChange}
                      checked={afastamentoCausas.includes(
                        "Doenças do aparelho respiratório"
                      )}
                    />
                    Doenças do aparelho respiratório, Ex: Asma ou outras.
                  </FormCheckboxLabel>
                  <FormCheckboxLabel>
                    <FormCheckbox
                      type="checkbox"
                      value="Doenças da cavidade oral"
                      onChange={handleAfastamentoCausasChange}
                      checked={afastamentoCausas.includes(
                        "Doenças da cavidade oral"
                      )}
                    />
                    Doenças da cavidade oral (boca), Ex: dor de dente ou outras.
                  </FormCheckboxLabel>
                  <FormCheckboxLabel>
                    <FormCheckbox
                      type="checkbox"
                      value="Doenças do aparelho digestório"
                      onChange={handleAfastamentoCausasChange}
                      checked={afastamentoCausas.includes(
                        "Doenças do aparelho digestório"
                      )}
                    />
                    Doenças do aparelho digestório, Ex: dores no estômago ou
                    outras.
                  </FormCheckboxLabel>
                  <FormCheckboxLabel>
                    <FormCheckbox
                      type="checkbox"
                      value="Doenças de pele"
                      onChange={handleAfastamentoCausasChange}
                      checked={afastamentoCausas.includes("Doenças de pele")}
                    />
                    Doenças de pele, Ex: micoses ou outras.
                  </FormCheckboxLabel>
                  <FormCheckboxLabel>
                    <FormCheckbox
                      type="checkbox"
                      value="Doenças do aparelho osteomioarticular"
                      onChange={handleAfastamentoCausasChange}
                      checked={afastamentoCausas.includes(
                        "Doenças do aparelho osteomioarticular"
                      )}
                    />
                    Doenças do aparelho osteomioarticular (ossos, músculos,
                    articulações), Ex: lesões musculares, torções, fraturas ou
                    outras.
                  </FormCheckboxLabel>

                  <FormCheckboxLabel>
                    <FormCheckbox
                      type="checkbox"
                      value="Doenças do aparelho genital e urinário"
                      onChange={handleAfastamentoCausasChange}
                      checked={afastamentoCausas.includes(
                        "Doenças do aparelho genital e urinário"
                      )}
                    />
                    Doenças do aparelho genital e urinário, Ex: infecção
                    urinária ou outras.
                  </FormCheckboxLabel>
                  <FormCheckboxLabel>
                    <FormCheckbox
                      type="checkbox"
                      value="Gravidez, parto ou puerpério "
                      onChange={handleAfastamentoCausasChange}
                      checked={afastamentoCausas.includes(
                        "Gravidez, parto ou puerpério "
                      )}
                    />
                    Gravidez, parto ou puerpério
                  </FormCheckboxLabel>
                  <FormCheckboxLabel>
                    <FormCheckbox
                      type="checkbox"
                      value="Lesões, envenenamento e causas externas"
                      onChange={handleAfastamentoCausasChange}
                      checked={afastamentoCausas.includes(
                        "Lesões, envenenamento e causas externas"
                      )}
                    />
                    Lesões, envenenamento e causas externas (Trauma, queimadura)
                  </FormCheckboxLabel>
                  <FormCheckboxLabel>
                    <FormCheckbox
                      type="checkbox"
                      value="Consultas ou exames médicos"
                      onChange={handleAfastamentoCausasChange}
                      checked={afastamentoCausas.includes(
                        "Consultas ou exames médicos"
                      )}
                    />
                    Consultas ou exames médicos
                  </FormCheckboxLabel>
                  <FormCheckboxLabel>
                    <FormCheckbox
                      type="checkbox"
                      value="Acidentes em serviço"
                      onChange={handleAfastamentoCausasChange}
                      checked={afastamentoCausas.includes(
                        "Acidentes em serviço"
                      )}
                    />
                    Acidentes em serviço
                  </FormCheckboxLabel>

                  <FormCheckboxLabel>
                    <FormCheckbox
                      type="checkbox"
                      value="Outros"
                      onChange={handleAfastamentoCausasChange}
                      checked={afastamentoCausas.includes("Outros")}
                    />
                    Outros: <input type="text" />
                  </FormCheckboxLabel>
                </FormCheckboxGroup>
              </FormGroup>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 10,
                marginRight: 20,
              }}
            >
              <ButtonReturn onClick={handleProfileClick} />

              <ButtonSend onClick={handleSubmit} />
            </div>
          </form>
        </FormContainer>

        <div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      </Container>
    </>
  );
}

export default Form1;
