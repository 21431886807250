import React from "react";
import styled from "styled-components";

interface FormSelectProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: (string | { value: string | number; label: string })[];
  customWidth?: string;
}

const FormSelectStyled = styled.select<{ customWidth?: string }>`
  width: ${(props) => props.customWidth || "100%"};
  height: 3.5vh;
  box-sizing: border-box;
  border: 1px solid #b8b8b8;
  border-radius: 5px;
  padding: 5px;

  @media (max-width: 768px) {
 
    height: 5vh;
  }
`;

const FormSelect: React.FC<FormSelectProps> = ({
  value,
  onChange,
  options = [],
  customWidth,
}) => {
  return (
    <FormSelectStyled
      value={value}
      onChange={onChange}
      customWidth={customWidth}
    >
      <option value="">Selecione uma opção</option>
      {options.length > 0 ? (
        options.map((option, index) => {
          if (typeof option === "string") {
            return (
              <option key={index} value={option}>
                {option}
              </option>
            );
          } else {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            );
          }
        })
      ) : (
        <option value="">Sem opções disponíveis</option>
      )}
    </FormSelectStyled>
  );
};

export default FormSelect;
