import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 100vw;
`;

export const FormContainer = styled.div`
  width: 95vw;
`;

export const DivInputs = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;

  @media (max-width: 768px) {
    width: 90vw;
   
  }
`;

export const FormGroup = styled.div`
  display: flex;
  margin-bottom: 20px;
  gap: 10px;
  margin: 5px;
`;

export const FormLabel = styled.label`
  font-size: 1rem;
  margin-bottom: 10px;
  display: block;
  text-align: justify;
`;

export const FormInput = styled.input`
  width: ${(props) => props.customWidth || "100%"};
  height: 2.97vh;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  border: 1px solid #b8b8b8;

  @media (max-width: 768px) {
    height: 5vh;
  }
`;

export const FormSelect = styled.select`
  width: ${(props) => props.customWidth || "100%"};
  height: 2.97vh;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  border: 1px solid #b8b8b8;
`;

export const FormCheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 20px;
  text-align: justify;
`;

export const FormHeader = styled.div`
  margin-top: 40px;
  text-align: start;
  margin-bottom: 20px;
  color: #1f1087;

  @media (max-width: 768px) {
    margin-left: 10px;
  }
`;

export const FormCheckboxLabel = styled.label`
  font-size: 0.7rem;
  margin-bottom: 8px;
`;

export const FormCheckbox = styled.input`
  margin-right: 8px;
`;

export const FormLabelOptions = styled.label`
  font-size: 1rem;
  margin-bottom: 10px;
  display: block;
  text-align: justify;
  color: blue;
`;

export const FormRadio = styled.input`
  margin-right: 8px;
  font-size: 1.2rem;
`;

export const FormRadioGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
`;
export const FormInputCPF = styled.input`
  width: ${(props) => props.customWidth || "100%"};
  height: 2.97vh;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  border: 1px solid #b8b8b8;
  margin-top: 10px;
`;

export const FormInputCPF1 = styled.input`
  width: ${(props) => props.customWidth || "100%"};
  height: 2.97vh;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  border: 1px solid #b8b8b8;
`;

export const FormErrorMessage = styled.p`
  color: red;
`;

export const Table = styled.table`
  min-width: 70vw;
  border-collapse: collapse;
  font-size: 1rem;
`;

export const TableTH = styled.th`
  background-color: #f2f2f2;
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #000;
  font-size: 1rem;
  text-align: center;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1000;
`;

export const TableTD = styled.td`
  padding: 10px;
  border-bottom: 1px solid #000;
  font-size: 1rem;
  justify-content: center;
  flex-direction: row;
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  width: 100vw;

  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
  }
`;

export const MainContent = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 90vh;
  padding-left: 50px;

    
  @media (max-width: 768px) {
    padding-left: 0px;
  }
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  width: 60.91vw;

  
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Card = styled.div`
  background-color: #f4f4f5;
  border-radius: 5px;
  border: 1px solid #d4d4d8;
  cursor: pointer;
  width: 16vw;
  height: 17vh;
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  gap: 10px;

  &:hover {
    background-color: #ffffff;
    border: 1px solid #1f1087;
    transform: scale(1.05);
    color: #1f1087;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const ContentTextCard = styled.div`
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const CardText = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #263238;
`;

export const TextAcess = styled.p`
  font-size: 14px;
  font-weight: 400;
  border-bottom: 1px solid #a1a1aa;
  width: 90px;
`;

export const Avatar = styled.div`
  padding: 1px;
  background-color: #d9d9d9;
  width: 51px;
  height: 51px;
  border-radius: 50px;
`;

export const WelcomeText = styled.p`
  font-weight: 700;
  font-size: 42px;
  color: #2614a0;
  margin-bottom: 40px;
`;

export const CardImage = styled.img`
  height: auto;
  border-radius: 50px;
  width: 51px;
  height: 51px;
  background-color: white;
`;

export const SummaryInput = styled.textarea`
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "auto"};
  text-align: justify;
  resize: vertical;
`;
