import React, { useState, useEffect, useRef } from "react";
import supabase from "../../../Supabase";
import { useSession } from "../../../Routers/AppRouter";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import { CaretLeft, User, NoteBlank } from "@phosphor-icons/react";
import {
  Container,
  FormButton,
  FormLabel,
  ProfileContainer,
  ProfileForm,
  UserInfo,
  FormInput,
  ButtonContainer,
  CartaoButton,
  SairButton,
  WelcomeText,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { SuccessToast, ErrorToast } from "../../../Components/toastyfy";
import Header from "../../../Components/header";

export default function FormProfile() {
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState(null);
  const [email, setEmail] = useState(null);
  const [full_name, setFull_name] = useState(null);
  const [telefone, setTelefone] = useState(null);
  const [funcao, setFuncao] = useState(null);
  const [escolaridade, setEscolaridade] = useState(null);
  const session = useSession();

  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate("/plataforma/");
  };

  const cartaoRef = useRef();

  useEffect(() => {
    const emailUser = session?.user?.email;
    setEmail(emailUser);
  }, [session]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        if (session && session.user) {
          const { data, error } = await supabase
            .from("profiles")
            .select(`username, full_name, telefone, funcao, escolaridade`)
            .eq("id", session.user.id);

          if (error) {
            return;
          }

          const userUsername = data.length > 0 ? data[0].username : null;
          setUsername(userUsername);

          const userFull_name = data.length > 0 ? data[0].full_name : null;
          setFull_name(userFull_name);

          const userTefelone = data.length > 0 ? data[0].telefone : null;
          setTelefone(userTefelone);

          const userFuncao = data.length > 0 ? data[0].funcao : null;
          setFuncao(userFuncao);

          const userEscolaridade =
            data.length > 0 ? data[0].escolaridade : null;
          setEscolaridade(userEscolaridade);
        }
      } catch (error) {
        console.error("Error fetching profile:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [session]);

  async function updateProfile(event) {
    event.preventDefault();

    setLoading(true);

    const updates = {
      id: session.user.id,
      updated_at: new Date(),
      full_name,
      telefone,
      funcao,
      escolaridade,
    };

    try {
      const { error } = await supabase.from("profiles").upsert(updates);

      if (error) {
        alert(error.message);
      }
    } catch (error) {
      toast.error(
        <ErrorToast>
          <strong>Erro</strong>
        </ErrorToast>
      );
    } finally {
      toast.success(
        <SuccessToast>
          <strong>Perfil atualizado com suceso</strong>
        </SuccessToast>
      );
      setLoading(false);
    }
  }

  const gerarImagem = () => {
    html2canvas(cartaoRef.current).then((canvas) => {
      canvas.toBlob((blob) => {
        saveAs(blob, "perfil.png");
      });
    });
  };
  return (
    <>
      <Header />
      <Container>
        <WelcomeText>Meu perfil</WelcomeText>
        <ProfileForm onSubmit={updateProfile}>
          <ProfileContainer ref={cartaoRef}>
            <UserInfo>Email: {email}</UserInfo>
            <FormLabel htmlFor="full_name">Nome:</FormLabel>
            <FormInput
              type="text"
              id="full_name"
              value={full_name || ""}
              onChange={(e) => setFull_name(e.target.value)}
            />

            <FormLabel htmlFor="telefone">Telefone:</FormLabel>
            <FormInput
              type="text"
              id="telefone"
              value={telefone || ""}
              onChange={(e) => {
                const inputValue = e.target.value;

                const numericValue = inputValue.replace(/\D/g, "");

                if (numericValue !== "") {
                  const formattedValue = `(${numericValue.slice(
                    0,
                    2
                  )})${numericValue.slice(2, 7)}-${numericValue.slice(7, 11)}`;

                  setTelefone(formattedValue);
                } else {
                  setTelefone("");
                }
              }}
            />

            <FormLabel htmlFor="funcao">Funcão:</FormLabel>
            <FormInput
              type="text"
              id="funcao"
              value={funcao || ""}
              onChange={(e) => setFuncao(e.target.value)}
            />

            <FormLabel htmlFor="escolaridade">Escolaridade:</FormLabel>
            <FormInput
              type="text"
              id="escolaridade"
              value={escolaridade || ""}
              onChange={(e) => setEscolaridade(e.target.value)}
            />
          </ProfileContainer>

          <ButtonContainer>
            <SairButton onClick={handleProfileClick}>
              <CaretLeft size={15} />
              Voltar
            </SairButton>
            <FormButton type="submit" disabled={loading}>
              <User size={15} />
              {loading ? "Atualizando..." : "Atualizar Perfil"}
            </FormButton>
            <CartaoButton onClick={gerarImagem}>
              <NoteBlank size={15} />
              Gerar Cartão de Visita
            </CartaoButton>
          </ButtonContainer>
        </ProfileForm>
      </Container>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </>
  );
}
