import React from "react";
import styled from "styled-components";

interface FormLabelProps {
  children: React.ReactNode;
}

const FormLabelStyled = styled.label`
  font-size: 1rem;
  margin-bottom: 10px;
  display: block;
  text-align: justify;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

const FormLabel: React.FC<FormLabelProps> = ({ children }) => {
  return <FormLabelStyled>{children}</FormLabelStyled>;
};

export default FormLabel;
