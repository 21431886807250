import React from "react";
import { useNavigate } from "react-router-dom";
import {
  PageWrapper,
  MainContent,
  CardContainer,
  Card,
  CardImage,
  ContentTextCard,
  CardText,
  TextAcess,
  WelcomeText,
} from "./styles";
import image12 from "../../../Images/image12.png";
import image13 from "../../../Images/image13.png";
import image14 from "../../../Images/image14.jpg";
import HeaderQuestinarios from "../../../Components/headerQuestionarios";

const images = [image12, image13, image14];
const cardTitles = [
  "ESTRESSE NO TRABALHO",
  "AVALIAÇÃO DO ESGOTAMENTO PROFISSIONAL",
  "VARIAVEIS POR FATOR DE BURNOUT",
];

const Form5 = () => {
  const navigate = useNavigate();

  const handleCardClick = (cardNumber) => {
    if (cardNumber === 1) {
      navigate(`/plataforma/Estresse-trabalho`);
    } else if (cardNumber === 2) {
      navigate(`/plataforma/Avaliação-do-esgotamento-profissional`);
    } else if (cardNumber === 3) {
      navigate(`/plataforma/Variaveis-por-fator-burnout`);
    }
  };

  const renderCards = () => {
    return images.map((image, index) => (
      <Card key={index} onClick={() => handleCardClick(index + 1)}>
        <ContentTextCard>
          <CardImage src={image} alt={`Card ${index + 1}`} />
          <CardText>{cardTitles[index]}</CardText>
          <TextAcess>Acesse aqui</TextAcess>
        </ContentTextCard>
      </Card>
    ));
  };

  return (
    <PageWrapper>
      <HeaderQuestinarios />
      <MainContent>
        <WelcomeText>Estresse laboral</WelcomeText>
        <CardContainer>{renderCards()}</CardContainer>
      </MainContent>
    </PageWrapper>
  );
};

export default Form5;
