import React, { useState } from "react";
import HeaderGeral from "../../../Components/headerGeral";

import supabase from "../../../Supabase";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import { SuccessToast, ErrorToast } from "../../../Components/toastyfy";
import {
  Container,
  FormContainer,
  FormGroup,
  FormLabel,
  FormHeader,
  FormInput,
  FormRadioGroup,
  FormRadio,
  DivInputs,
} from "../../../Components/styles-all";

import ButtonReturn from "../../../Components/button-return";
import ButtonSend from "../../../Components/button-send";

const Form17 = () => {
  const [sexo, setSexo] = useState("");
  const [circunferenciaCintura, setCircunferenciaCintura] = useState("");
  const [circunferenciaQuadril, setCircunferenciaQuadril] = useState("");
  const [riscoCircunferenciaCintura, setRiscoCircunferenciaCintura] =
    useState("");
  const [ircq, setIRCQ] = useState(null);
  const [id, setId] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleCPFChange = (e) => {
    const cpf = formatCPF(e.target.value);
    setId(cpf);

    const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    setIsValid(regex.test(cpf));
  };

  const formatCPF = (cpf) => {
    let value = cpf.replace(/\D/g, "");
    if (value.length > 3) value = `${value.slice(0, 3)}.${value.slice(3)}`;
    if (value.length > 7) value = `${value.slice(0, 7)}.${value.slice(7)}`;
    if (value.length > 11)
      value = `${value.slice(0, 11)}-${value.slice(11, 13)}`;
    return value;
  };

  const calcularRiscoCircunferencia = () => {
    const ccValue = parseFloat(circunferenciaCintura);
    let risco = "";

    if (sexo === "Homem") {
      if (ccValue < 91) {
        risco = "Risco Baixo";
      } else if (ccValue >= 94 && ccValue < 102) {
        risco = "Risco Moderado";
      } else if (ccValue >= 103 && ccValue < 110) {
        risco = "Risco Elevado";
      } else {
        risco = "Risco Muito Elevado";
      }
    } else {
      if (ccValue < 76) {
        risco = "Risco Baixo";
      } else if (ccValue >= 77 && ccValue < 83) {
        risco = "Risco Moderado";
      } else if (ccValue >= 84 && ccValue < 90) {
        risco = "Risco Elevado";
      } else {
        risco = "Risco Muito Elevado";
      }
    }

    setRiscoCircunferenciaCintura(risco);
  };

  const calcularIRCQ = () => {
    if (circunferenciaCintura && circunferenciaQuadril) {
      const ircqValue =
        parseFloat(circunferenciaCintura) / parseFloat(circunferenciaQuadril);
      setIRCQ(ircqValue.toFixed(2));
      calcularRiscoCircunferencia();
    } else {
      setIRCQ(null);
      setRiscoCircunferenciaCintura("");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      id,
      ircq,
      riscoCircunferenciaCintura,
    };

    const { error } = await supabase.from("irqc").insert([data]);
    if (error) {
      toast.error(
        <ErrorToast>
          <strong>Erro!</strong> Erro ao inserir os dados.
        </ErrorToast>
      );
    } else {
      toast.success(
        <SuccessToast>
          <strong>Sucesso!</strong> Dados inseridos com êxito.
        </SuccessToast>
      );
    }
  };

  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate("/plataforma/Avaliação-antropométrica");
  };

  return (
    <>
      <HeaderGeral />
      <Container>
        <FormContainer>
          <FormHeader>
            <h2>ÍNDICE RELAÇÃO CINTURA/QUADRIL - IRCQ</h2>
          </FormHeader>
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <FormLabel>Nº de CPF :</FormLabel>
              <FormInput
                type="text"
                value={id}
                maxLength="14"
                onChange={handleCPFChange}
                customWidth="15vw"
                style={isValid ? {} : { borderColor: "red" }}
              />
              {!isValid && <p style={{ color: "red" }}>CPF inválido!</p>}
            </FormGroup>

            <FormGroup>
              <FormLabel>Sexo:</FormLabel>
              <FormRadioGroup>
                <FormRadio
                  type="radio"
                  name="sexo"
                  value="Homem"
                  checked={sexo === "Homem"}
                  onChange={(e) => setSexo(e.target.value)}
                />
                <label htmlFor="sexo">Homem</label>
                <FormRadio
                  type="radio"
                  name="sexo"
                  value="Mulher"
                  checked={sexo === "Mulher"}
                  onChange={(e) => setSexo(e.target.value)}
                />
                <label htmlFor="sexo">Mulher</label>
              </FormRadioGroup>

              <DivInputs>
                <FormLabel>Circunferência da Cintura:</FormLabel>
                <FormInput
                  type="text"
                  customWidth="10vw"
                  value={circunferenciaCintura}
                  onChange={(e) => setCircunferenciaCintura(e.target.value)}
                />{" "}
                cm
                <FormLabel>Circunferência do Quadril:</FormLabel>
                <FormInput
                  type="text"
                  value={circunferenciaQuadril}
                  customWidth="10vw"
                  onChange={(e) => setCircunferenciaQuadril(e.target.value)}
                />{" "}
                cm
              </DivInputs>
            </FormGroup>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 10,
                marginRight: 20,
                marginTop: 30,
              }}
            >
              <ButtonReturn onClick={handleProfileClick} />

              <ButtonSend onClick={calcularIRCQ} />
            </div>
          </form>
        </FormContainer>
        <div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      </Container>
    </>
  );
};

export default Form17;
