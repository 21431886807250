import React, { useState } from "react";
import supabase from "../../../Supabase";
import { useNavigate } from "react-router-dom";
import HeaderGeral from "../../../Components/headerGeral";

import {
  Container,
  FormContainer,
  FormGroup,
  FormLabel,
  FormRadioGroup,
  FormRadio,
  FormInput,
  FormInputCPF,
  FormInputCPF1,
  FormHeader,
  FormLabelOptions,
} from "../../../Components/styles-all";
import { ToastContainer, toast } from "react-toastify";
import { SuccessToast, ErrorToast } from "../../../Components/toastyfy";

import ButtonReturn from "../../../Components/button-return";
import ButtonSend from "../../../Components/button-send";

const Form6 = () => {
  const [q1, setQ1] = useState("");
  const [q1a, setQ1a] = useState("");
  const [q1b, setQ1b] = useState("");
  const [q1cHours, setQ1cHours] = useState("");
  const [q1cMinutes, setQ1cMinutes] = useState("");
  const [q1d, setQ1d] = useState("");
  const [q1eHours, setQ1eHours] = useState("");
  const [q1eMinutes, setQ1eMinutes] = useState("");
  const [q1fHours, setQ1fHours] = useState("");
  const [q1f, setQ1f] = useState("");
  const [q1gHours, setQ1gHours] = useState("");
  const [q1gMinutes, setQ1gMinutes] = useState("");
  const [q2a, setQ2a] = useState("");
  const [q2bHours, setQ2bHours] = useState("");
  const [q2bMinutes, setQ2bMinutes] = useState("");
  const [q2c, setQ2c] = useState("");
  const [q2dHours, setQ2dHours] = useState("");
  const [q2dMinutes, setQ2dMinutes] = useState("");
  const [q2e, setQ2e] = useState("");
  const [q2fHours, setQ2fHours] = useState("");
  const [q2fMinutes, setQ2fMinutes] = useState("");
  const [q3a, setQ3a] = useState("");
  const [q3bHours, setQ3bHours] = useState("");
  const [q3bMinutes, setQ3bMinutes] = useState("");
  const [q3c, setQ3c] = useState("");
  const [q3dHours, setQ3dHours] = useState("");
  const [q3dMinutes, setQ3dMinutes] = useState("");
  const [q3e, setQ3e] = useState("");
  const [q3fHours, setQ3fHours] = useState("");
  const [q3fMinutes, setQ3fMinutes] = useState("");
  const [q4a, setQ4a] = useState("");
  const [q4c, setQ4c] = useState("");
  const [q4bHours, setQ4bHours] = useState("");
  const [q4bMinutes, setQ4bMinutes] = useState("");
  const [q4dMinutes, setQ4dMinutes] = useState("");
  const [q4e, setQ4e] = useState("");
  const [q4fHours, setQ4fHours] = useState("");
  const [q4fMinutes, setQ4fMinutes] = useState("");
  const [q5aHours, setQ5aHours] = useState("");
  const [q5aMinutes, setQ5aMinutes] = useState("");
  const [q5bHours, setQ5bHours] = useState("");
  const [q5bMinutes, setQ5bMinutes] = useState("");
  const [kcalResult, setKcalResult] = useState({});
  const [id, setId] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleCPFChange = (e) => {
    const cpf = formatCPF(e.target.value);
    setId(cpf);

    const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    setIsValid(regex.test(cpf));
  };

  const formatCPF = (cpf) => {
    let value = cpf.replace(/\D/g, "");
    if (value.length > 3) value = `${value.slice(0, 3)}.${value.slice(3)}`;
    if (value.length > 7) value = `${value.slice(0, 7)}.${value.slice(7)}`;
    if (value.length > 11)
      value = `${value.slice(0, 11)}-${value.slice(11, 13)}`;
    return value;
  };

  const sendDataToDatabase = async (formData) => {
    try {
      const { error } = await supabase
        .from("nivelAtiviFisica")
        .insert([formData]);

      if (error) throw error;

      toast.success(
        <SuccessToast>
          <strong>Sucesso!</strong> Dados inseridos com êxito.
        </SuccessToast>
      );
    } catch (error) {
      toast.error(
        <ErrorToast>
          <strong>Erro!</strong> Erro ao inserir os dados.
        </ErrorToast>
      );
    }
  };

  function normalizeFormData(formData) {
    const normalizedData = { ...formData };
    for (const key in normalizedData) {
      if (normalizedData[key] === "") {
        normalizedData[key] = null;
      }
    }
    return normalizedData;
  }

  const calculateKcal = (weight, activities) => {
    const metValues = {
      q1b: 8,
      q1c: 4,
      q1d: 2.5,
      q2a: 1,
      q2b: 6,
      q2c: 3.3,
      q3a: 5.5,
      q3b: 4,
      q3c: 3,
      q4a: 3.3,
      q4b: 8,
      q4c: 4,
      q5a: 4,
      q5b: 4,
    };

    const calculateCalories = (met, hours, minutes) => {
      const totalHours = parseFloat(hours) + parseFloat(minutes) / 60;
      return met * totalHours;
    };

    let totalCalories = 0;

    for (const activity of activities) {
      const { question, hours, minutes } = activity;
      const met = metValues[question];

      const calories = calculateCalories(met, hours, minutes);
      totalCalories += calories;
    }

    const kcalPerDay = ((totalCalories * weight * 3.5) / 200) * 8.8;

    return kcalPerDay;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const activities = [
      { question: "q1b", hours: 0, minutes: 0 },
      {
        question: "q1c",
        hours: parseFloat(q1cHours),
        minutes: parseFloat(q1cMinutes) || 0,
      },
      { question: "q1d", hours: 0, minutes: 0 },
      { question: "q2a", hours: 0, minutes: 0 },
      {
        question: "q2b",
        hours: parseFloat(q2bHours),
        minutes: parseFloat(q2bMinutes) || 0,
      },
      { question: "q2c", hours: 0, minutes: 0 },
      { question: "q3a", hours: 0, minutes: 0 },
      {
        question: "q4b",
        hours: parseFloat(q4bHours),
        minutes: parseFloat(q4bMinutes) || 0,
      },
      {
        question: "q3b",
        hours: parseFloat(q3bHours),
        minutes: parseFloat(q3bMinutes) || 0,
      },
      { question: "q3c", hours: 0, minutes: 0 },
      { question: "q4a", hours: 0, minutes: 0 },
      {
        question: "q4b",
        hours: parseFloat(q4bHours),
        minutes: parseFloat(q4bMinutes) || 0,
      },
      { question: "q4c", hours: 0, minutes: 0 },
      {
        question: "q5a",
        hours: parseFloat(q5aHours),
        minutes: parseFloat(q5aMinutes) || 0,
      },
      {
        question: "q5b",
        hours: parseFloat(q5bHours),
        minutes: parseFloat(q5bMinutes) || 0,
      },
    ];

    const weight = 70;
    const result = calculateKcal(weight, activities);

    let classification = "";

    if (result < 600) {
      classification = `INATIVO `;
    } else if (result >= 600 && result < 1500) {
      classification = `INSUFICIENTEMENTE ATIVO `;
    } else if (result >= 1500 && result < 3000) {
      classification = `ATIVO `;
    } else {
      classification = `MUITO ATIVO `;
    }

    setKcalResult({ kcalPerDay: result, classification });

    const formData = {
      id,
      classification,
    };

    const normalizedFormData = normalizeFormData(formData);
    await sendDataToDatabase(normalizedFormData);
  };

  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate("/plataforma/");
  };

  return (
    <>
      <HeaderGeral />
      <Container>
        <FormContainer>
          <FormHeader>
            <h2> NÍVEL DE ATIVIDADE FÍSICA</h2>
          </FormHeader>

          <form onSubmit={handleSubmit}>
            <FormGroup>
              <FormLabel>
                Nós estamos interessados em saber que tipos de atividade física
                as pessoas fazem como parte do seu dia a dia. As perguntas estão
                relacionadas ao tempo que você gasta fazendo atividade física em
                uma semana típica, <strong>na ultima semana. </strong>
                As perguntas incluem as atividades que você faz no trabalho,
                para ir de um lugar a outro, por lazer, por esporte, por
                exercício ou como parte das suas atividades em casa ou no
                jardim. Suas respostas são MUITO importantes. Por favor,
                responda cada questão mesmo que considere que não seja ativo.
                Obrigado pela sua participação!
                <br />
                <br />
                Para responder as questões lembre que:
                <br />
                <br />
                Atividades físicas <strong>VIGOROSAS </strong> são aquelas que
                precisam de um grande esforço físico e que fazem respirar MUITO
                mais forte que o normal.
                <br />
                <br />
                Atividades físicas <strong>ODERADAS </strong> são aquelas que
                precisam de algum esforço físico e que fazem respirar UM POUCO
                mais forte que o normal.
              </FormLabel>
            </FormGroup>

            <FormGroup>
              <FormLabel>Nº de CPF :</FormLabel>
              <FormInputCPF
                type="text"
                value={id}
                maxLength="14"
                onChange={handleCPFChange}
                style={isValid ? {} : { borderColor: "red" }}
                customWidth="20.87vw"
              />
              {!isValid && <p style={{ color: "red" }}>CPF inválido!</p>}
            </FormGroup>

            <FormGroup>
              <FormLabel>De forma geral sua saúde está:</FormLabel>
              <FormRadioGroup>
                {["Excelente", "Muito boa", "Boa", "Regular", "Ruim"].map(
                  (value) => (
                    <div key={value}>
                      <FormRadio
                        type="radio"
                        name="q1"
                        value={value}
                        onChange={(e) => setQ1(e.target.value)}
                      />
                      <span>{value}</span>
                    </div>
                  )
                )}
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                {" "}
                <strong> SEÇÃO 1- ATIVIDADE FÍSICA NO TRABALHO </strong>
              </FormLabel>
              <FormLabelOptions>
                Esta seção inclui as atividades que você faz no seu serviço, que
                incluem trabalho remunerado ou voluntário, as atividades na
                escola ou faculdade e outro tipo de trabalho não remunerado fora
                da sua casa.
                <br />
                <strong>NÃO </strong>incluir trabalho não remunerado que você
                faz na sua casa como tarefas domésticas, cuidar do jardim e da
                casa ou tomar conta da sua família.
                <br /> Estas serão incluídas na seção 3.
              </FormLabelOptions>
              <FormGroup>
                <FormLabel>
                  <strong> 1a. </strong> Atualmente você trabalha, estuda ou faz
                  trabalho voluntário fora de sua casa?
                </FormLabel>
                <FormRadioGroup>
                  {["Sim", "Não"].map((value) => (
                    <div key={value}>
                      <FormRadio
                        type="radio"
                        name="q1a"
                        value={value}
                        onChange={(e) => setQ1a(e.target.value)}
                      />
                      <span>{value}</span>
                    </div>
                  ))}
                </FormRadioGroup>
                <FormLabelOptions>
                  As próximas questões são em relação a toda a atividade física
                  que você fez na ultima semana como parte do seu trabalho
                  remunerado ou não remunerado e/ou do seu estudo.
                  <strong>NÃO</strong> inclua o transporte para o trabalho.
                  Pense unicamente nas atividades que você faz por{" "}
                  <strong>pelo menos 10 minutos contínuos</strong>:
                </FormLabelOptions>
              </FormGroup>

              <FormGroup>
                <FormLabel>
                  <strong>1b.</strong> Em quantos dias de uma semana normal você{" "}
                  <strong>anda</strong>, durante{" "}
                  <strong>
                    pelo menos 10 minutos contínuos, como parte do seu
                    trabalho/estudo?
                  </strong>
                  <br />
                  Por favor, <strong>NÃO</strong> inclua o andar como forma de
                  transporte para ir ou voltar do trabalho.
                </FormLabel>
                <FormRadioGroup>
                  {["Nenhum", "1", "2", "3", "4", "5", "6", "7"].map(
                    (value) => (
                      <div key={value}>
                        <FormRadio
                          type="radio"
                          name="q1b"
                          value={value}
                          checked={q1b === value}
                          onChange={() => setQ1b(value)}
                        />
                        <span>{value}</span>
                      </div>
                    )
                  )}
                </FormRadioGroup>

                <FormLabelOptions>
                  <strong> Nenhum - Vá para a seção 2 - Transporte. </strong>
                </FormLabelOptions>
              </FormGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>1c. </strong> Quanto tempo no total você usualmente
                gasta POR DIA caminhando como parte do seu trabalho/estudo?
              </FormLabel>

              <FormInput
                type="number"
                placeholder="Horas"
                value={q1cHours}
                customWidth=" 8vw"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue >= 0) {
                    setQ1cHours(inputValue);
                  } else {
                    setQ1cHours(0);
                  }
                }}
              />

              <FormInput
                type="number"
                placeholder="Minutos"
                value={q1cMinutes}
                customWidth=" 8vw"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue >= 0) {
                    setQ1cMinutes(inputValue);
                  } else {
                    setQ1cMinutes(0);
                  }
                }}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>1d. </strong> Em quantos dias de uma semana normal você
                faz atividades moderadas, por pelo menos 10 minutos contínuos,
                como carregar pesos leves como parte do seu trabalho/estudo?
              </FormLabel>
              <FormRadioGroup>
                {["Nenhum", "1", "2", "3", "4", "5", "6", "7"].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q1d"
                      value={value}
                      checked={q1d === value}
                      onChange={(e) => setQ1d(e.target.value)}
                    />
                    <span>{value}</span>
                  </div>
                ))}
              </FormRadioGroup>

              <FormLabelOptions>
                <strong> Nenhum - Vá para a questão 1f. </strong>
              </FormLabelOptions>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>1e. </strong> Quanto tempo no total você usualmente
                gasta <strong>POR DIA </strong>fazendo atividades{" "}
                <strong>moderadas como parte do seu trabalho/estudo? </strong>
              </FormLabel>

              <FormInput
                type="number"
                placeholder="Horas"
                customWidth=" 8vw"
                value={q1eHours}
                onChange={(e) => setQ1eHours(e.target.value)}
              />

              <FormInput
                type="number"
                placeholder="Minutos"
                customWidth=" 8vw"
                value={q1eMinutes}
                onChange={(e) => setQ1eMinutes(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>1f. </strong> Em quantos dias de uma semana normal você
                gasta fazendo atividades vigorosas, por pelo menos 10 minutos
                contínuos, como trabalho de construção pesada, carregar grandes
                pesos, trabalhar com enxada, escavar ou subir escadas como parte
                do seu trabalho/estudo:
              </FormLabel>
              <FormRadioGroup>
                {["Nenhum", "1", "2", "3", "4", "5", "6", "7"].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q1b"
                      value={value}
                      checked={q1f === value}
                      onChange={() => setQ1f(value)}
                    />
                    <span>{value}</span>
                  </div>
                ))}
              </FormRadioGroup>

              <FormLabelOptions>
                <strong> Nenhum - Vá para a questão 2a. </strong>
              </FormLabelOptions>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>1g. </strong> Quanto tempo no total você usualmente
                gasta POR DIA fazendo atividades físicas vigorosas como parte do
                seu trabalho?
              </FormLabel>

              <FormInput
                type="number"
                placeholder="Horas"
                customWidth=" 8vw"
                value={q1gHours}
                onChange={(e) => setQ1gHours(e.target.value)}
              />

              <FormInput
                type="number"
                placeholder="Minutos"
                customWidth=" 8vw"
                value={q1gMinutes}
                onChange={(e) => setQ1gMinutes(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>
                  SEÇÃO 2 - ATIVIDADE FÍSICA COMO MEIO DE TRANSPORTE
                </strong>
              </FormLabel>

              <FormLabelOptions>
                Estas questões se referem à forma típica como você se desloca de
                um lugar para outro, incluindo seu trabalho, escola, cinema,
                lojas e outros por pelo menos 10 minutos contínuos.
              </FormLabelOptions>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>2a. </strong> Quantos dias você andou na última semana
                de carro, ônibus, metrô ou trem?
              </FormLabel>
              <FormRadioGroup>
                {["Nenhum", "1", "2", "3", "4", "5", "6", "7"].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q2a"
                      value={value}
                      checked={q2a === value}
                      onChange={(e) => setQ2a(e.target.value)}
                    />
                    <span>{value}</span>
                  </div>
                ))}
              </FormRadioGroup>

              <FormLabelOptions>
                <strong> Nenhum - Vá para questão 2c. </strong>
              </FormLabelOptions>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>2b.</strong>Quanto tempo no total você usualmente gasta
                POR DIA andando de carro, ônibus, metrô ou trem?
              </FormLabel>

              <FormInput
                type="number"
                placeholder="Horas"
                value={q2bHours}
                onChange={(e) => setQ2bHours(e.target.value)}
                customWidth=" 8vw"
              />

              <FormInput
                type="number"
                placeholder="Minutos"
                value={q2bMinutes}
                onChange={(e) => setQ2bMinutes(e.target.value)}
                customWidth=" 8vw"
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>
                Agora pense somente em relação a caminhar ou pedalar para ir de
                um lugar a outro na ultima semana.
              </FormLabel>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>2c. </strong> Em quantos dias da ultima semana você
                andou de bicicleta, patins, skate ou correu por pelo menos 10
                minutos contínuos para ir de um lugar para outro? (NÃO inclua o
                pedalar por lazer ou exercício).
              </FormLabel>
              <FormRadioGroup>
                {["Nenhum", "1", "2", "3", "4", "5", "6", "7"].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q2c"
                      checked={q2c === value}
                      value={value}
                      onChange={(e) => setQ2c(e.target.value)}
                    />
                    <span>{value}</span>
                  </div>
                ))}
              </FormRadioGroup>

              <FormLabelOptions>
                <strong> Nenhum - Vá para a questão 2e. </strong>
              </FormLabelOptions>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>2d.</strong>Nos dias que você pedala, anda de patins ou
                skate ou corre quanto tempo no total você gasta POR DIA para ir
                de um lugar para outro?
              </FormLabel>
              <FormRadioGroup>
                <FormInput
                  type="number"
                  placeholder="Horas"
                  value={q2dHours}
                  onChange={(e) => setQ2dHours(e.target.value)}
                  customWidth=" 8vw"
                />

                <FormInput
                  type="number"
                  placeholder="Minutos"
                  value={q2dMinutes}
                  onChange={(e) => setQ2dMinutes(e.target.value)}
                  customWidth=" 8vw"
                />
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>2e.</strong>Em quantos dias da ultima semana você
                caminhou por pelo menos 10 minutos contínuos para ir de um lugar
                para outro? (NÃO inclua as caminhadas por lazer ou exercício).
              </FormLabel>
              <FormRadioGroup>
                {["Nenhum", "1", "2", "3", "4", "5", "6", "7"].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q2e"
                      value={value}
                      onChange={(e) => setQ2e(e.target.value)}
                    />
                    <span>{value}</span>
                  </div>
                ))}
              </FormRadioGroup>

              <FormLabelOptions>
                <strong> Nenhum - Vá para a Seção 3. </strong>
              </FormLabelOptions>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>2f.</strong>Quando você caminha para ir de um lugar para
                outro quanto tempo POR DIA você gasta? (NÃO inclua as caminhadas
                por lazer ou exercício).
              </FormLabel>
              <FormRadioGroup>
                <FormInput
                  type="number"
                  placeholder="Horas"
                  value={q2fHours}
                  onChange={(e) => setQ2fHours(e.target.value)}
                  customWidth=" 8vw"
                />

                <FormInput
                  type="number"
                  placeholder="Minutos"
                  value={q2fMinutes}
                  onChange={(e) => setQ2fMinutes(e.target.value)}
                  customWidth=" 8vw"
                />
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>
                  SEÇÃO 3 – ATIVIDADE FÍSICA EM CASA: TRABALHO, TAREFAS
                  DOMÉSTICAS E CUIDAR DA FAMÍLIA.{" "}
                </strong>
              </FormLabel>

              <FormLabel>
                Esta parte inclui as atividades físicas que você fez na ultima
                semana na sua casa e ao redor da sua casa, por exemplo, trabalho
                em casa, cuidar do jardim, cuidar do quintal, trabalho de
                manutenção da casa ou para cuidar da sua família. Novamente
                pense somente naquelas atividades físicas que você faz por pelo
                menos 10 minutos contínuos.
              </FormLabel>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>3a.</strong>Em quantos dias da ultima semana você fez
                atividades moderadas por pelo menos 10 minutos contínuos como
                carregar pesos leves, limpar vidros, varrer, rastelar no jardim
                ou quintal.
              </FormLabel>
              <FormRadioGroup>
                {["Nenhum", "1", "2", "3", "4", "5", "6", "7"].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q3a"
                      checked={q3a === value}
                      value={value}
                      onChange={(e) => setQ3a(e.target.value)}
                    />
                    <span>{value}</span>
                  </div>
                ))}
              </FormRadioGroup>

              <FormLabelOptions>
                <strong> Nenhum - Vá para questão 3c. </strong>
              </FormLabelOptions>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>3b.</strong>Nos dias que você faz este tipo de
                atividades quanto tempo no total você gasta POR DIA fazendo
                essas atividades moderadas no jardim ou no quintal?
              </FormLabel>
              <FormRadioGroup>
                <FormInput
                  type="number"
                  placeholder="Horas"
                  value={q3bHours}
                  onChange={(e) => setQ3bHours(e.target.value)}
                  customWidth=" 8vw"
                />

                <FormInput
                  type="number"
                  placeholder="Minutos"
                  value={q3bMinutes}
                  onChange={(e) => setQ3bMinutes(e.target.value)}
                  customWidth=" 8vw"
                />
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>3c.</strong>Em quantos dias da ultima semana você fez
                atividades moderadas por pelo menos 10 minutos contínuos como
                carregar pesos leves, limpar vidros, varrer ou limpar o chão
                dentro da sua casa.
              </FormLabel>
              <FormRadioGroup>
                {["Nenhum", "1", "2", "3", "4", "5", "6", "7"].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q3c"
                      checked={q3c === value}
                      value={value}
                      onChange={(e) => setQ3c(e.target.value)}
                    />
                    <span>{value}</span>
                  </div>
                ))}
              </FormRadioGroup>

              <FormLabelOptions>
                <strong> Nenhum - Vá para questão 3e. </strong>
              </FormLabelOptions>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>3d.</strong>Nos dias que você faz este tipo de
                atividades moderadas dentro da sua casa quanto tempo no total
                você gasta POR DIA?
              </FormLabel>
              <FormRadioGroup>
                <FormInput
                  type="number"
                  placeholder="Horas"
                  value={q3dHours}
                  onChange={(e) => setQ3dHours(e.target.value)}
                  customWidth=" 8vw"
                />

                <FormInput
                  type="number"
                  placeholder="Minutos"
                  value={q3dMinutes}
                  onChange={(e) => setQ3dMinutes(e.target.value)}
                  customWidth=" 8vw"
                />
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>3e.</strong>Em quantos dias da ultima semana você fez
                atividades físicas vigorosas no jardim ou quintal por pelo menos
                10 minutos contínuos como carpir, esfregar o chão:
              </FormLabel>
              <FormRadioGroup>
                {["Nenhum", "1", "2", "3", "4", "5", "6", "7"].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q3e"
                      checked={q3e === value}
                      value={value}
                      onChange={(e) => setQ3e(e.target.value)}
                    />
                    <span>{value}</span>
                  </div>
                ))}
              </FormRadioGroup>

              <FormLabelOptions>
                <strong> Nenhum - Vá para a seção 4. </strong>
              </FormLabelOptions>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>3f.</strong>Nos dias que você faz este tipo de
                atividades vigorosas no quintal ou jardim quanto tempo no total
                você gasta POR DIA?
              </FormLabel>
              <FormRadioGroup>
                <FormInput
                  type="number"
                  placeholder="Horas"
                  value={q3fHours}
                  onChange={(e) => setQ3fHours(e.target.value)}
                  customWidth=" 8vw"
                />

                <FormInput
                  type="number"
                  placeholder="Minutos"
                  value={q3fMinutes}
                  onChange={(e) => setQ3fMinutes(e.target.value)}
                  customWidth=" 8vw"
                />
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>
                  SEÇÃO 4- ATIVIDADES FÍSICAS DE RECREAÇÃO, ESPORTE, EXERCÍCIO E
                  DE LAZER.{" "}
                </strong>
              </FormLabel>

              <FormLabel>
                Esta seção se refere às atividades físicas que você fez na
                ultima semana unicamente por recreação, esporte, exercício ou
                lazer. Novamente pense somente nas atividades físicas que faz
                por pelo menos 10 minutos contínuos. Por favor, NÃO inclua
                atividades que você já tenha citado.
              </FormLabel>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>4a.</strong>Sem contar qualquer caminhada que você tenha
                citado anteriormente, em quantos dias da ultima semana você
                caminhou por pelo menos 10 minutos contínuos no seu tempo livre?
              </FormLabel>
              <FormRadioGroup>
                {["Nenhum", "1", "2", "3", "4", "5", "6", "7"].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q4a"
                      checked={q4a === value}
                      value={value}
                      onChange={(e) => setQ4a(e.target.value)}
                    />
                    <span>{value}</span>
                  </div>
                ))}
              </FormRadioGroup>

              <FormLabelOptions>
                <strong> Nenhum - Vá para questão 4c.</strong>
              </FormLabelOptions>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>4b.</strong>Nos dias em que você caminha no seu tempo
                livre, quanto tempo no total você gasta POR DIA?
              </FormLabel>
              <FormRadioGroup>
                <FormInput
                  type="number"
                  placeholder="Horas"
                  value={q4bHours}
                  onChange={(e) => setQ4bHours(e.target.value)}
                  customWidth=" 8vw"
                />

                <FormInput
                  type="number"
                  placeholder="Minutos"
                  value={q4bMinutes}
                  onChange={(e) => setQ4bMinutes(e.target.value)}
                  customWidth=" 8vw"
                />
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>4c.</strong> Em quantos dias da ultima semana você fez
                atividades moderadas no seu tempo livre por pelo menos 10
                minutos contínuos, como pedalar ou nadar a velocidade regular,
                jogar bola, vôlei , basquete, tênis de forma recreativa ou
                praticar musculação de forma moderada :
              </FormLabel>
              <FormRadioGroup>
                {["Nenhum", "1", "2", "3", "4", "5", "6", "7"].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q4c"
                      checked={q4c === value}
                      value={value}
                      onChange={(e) => setQ4c(e.target.value)}
                    />
                    <span>{value}</span>
                  </div>
                ))}
              </FormRadioGroup>
              <FormLabelOptions>
                <strong> Nenhum - Vá para questão 4e.</strong>
              </FormLabelOptions>

              <p>
                Caso responda que realizou as atividades descritas, por favor,
                diga qual(s) é (são):
              </p>

              <FormInputCPF1 customWidth="20.87vw" />
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>4d.</strong> Nos dias em que você faz estas atividades
                moderadas no seu tempo livre quanto tempo no total você gasta
                POR DIA?
              </FormLabel>
              <FormRadioGroup>
                <FormInput
                  type="number"
                  placeholder="Horas"
                  value={q1cHours}
                  onChange={(e) => setQ1cHours(e.target.value)}
                  customWidth=" 8vw"
                />

                <FormInput
                  type="number"
                  placeholder="Minutos"
                  value={q1cMinutes}
                  onChange={(e) => setQ1cMinutes(e.target.value)}
                  customWidth=" 8vw"
                />
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>4e.</strong> Em quantos dias da ultima semana você fez
                atividades vigorosas no seu tempo livre por pelo menos 10
                minutos, como correr, fazer aeróbicos, nadar rápido, pedalar
                rápido ou praticar musculação intensa:
              </FormLabel>
              <FormRadioGroup>
                {["Nenhum", "1", "2", "3", "4", "5", "6", "7"].map((value) => (
                  <div key={value}>
                    <FormRadio
                      type="radio"
                      name="q4e"
                      checked={q4e === value}
                      value={value}
                      onChange={(e) => setQ4e(e.target.value)}
                    />
                    <span>{value}</span>
                  </div>
                ))}
              </FormRadioGroup>

              <FormLabelOptions>
                <strong> Nenhum - Vá para seção 5.</strong>
              </FormLabelOptions>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>4f.</strong> Nos dias em que você faz estas atividades
                vigorosas no seu tempo livre quanto tempo no total você gasta
                POR DIA?
              </FormLabel>
              <FormRadioGroup>
                <FormInput
                  type="number"
                  placeholder="Horas"
                  value={q4fHours}
                  onChange={(e) => setQ4fHours(e.target.value)}
                  customWidth=" 8vw"
                />

                <FormInput
                  type="number"
                  placeholder="Minutos"
                  value={q4fMinutes}
                  onChange={(e) => setQ4fMinutes(e.target.value)}
                  customWidth=" 8vw"
                />
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>SEÇÃO 5 - TEMPO GASTO SENTADO </strong>
              </FormLabel>

              <FormLabelOptions>
                Estas últimas questões são sobre o tempo que você permanece
                sentado todo dia, no trabalho, na escola ou faculdade, em casa e
                durante seu tempo livre. Isto inclui o tempo sentado estudando,
                sentado enquanto descansa, fazendo lição de casa visitando um
                amigo, lendo, sentado ou deitado assistindo TV. Não inclua o
                tempo gasto sentando durante o transporte em ônibus, trem, metrô
                ou carro.
              </FormLabelOptions>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>5a.</strong> Nos dias em que você faz estas atividades
                vigorosas no seu tempo livre quanto tempo no total você gasta
                POR DIA?
              </FormLabel>
              <FormRadioGroup>
                <FormInput
                  type="number"
                  placeholder="Horas"
                  value={q5aHours}
                  onChange={(e) => setQ5aHours(e.target.value)}
                  customWidth=" 8vw"
                />

                <FormInput
                  type="number"
                  placeholder="Minutos"
                  value={q5aMinutes}
                  onChange={(e) => setQ5aMinutes(e.target.value)}
                  customWidth=" 8vw"
                />
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <strong>5b.</strong> Nos dias em que você faz estas atividades
                vigorosas no seu tempo livre quanto tempo no total você gasta
                POR DIA?
              </FormLabel>
              <FormRadioGroup>
                <FormInput
                  type="number"
                  placeholder="Horas"
                  value={q5bHours}
                  onChange={(e) => setQ5bHours(e.target.value)}
                  customWidth=" 8vw"
                />

                <FormInput
                  type="number"
                  placeholder="Minutos"
                  value={q5bMinutes}
                  onChange={(e) => setQ5bMinutes(e.target.value)}
                  customWidth=" 8vw"
                />
              </FormRadioGroup>
            </FormGroup>

            <FormGroup>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: 10,
                  marginRight: 20,
                }}
              >
                <ButtonReturn onClick={handleProfileClick} />

                <ButtonSend onClick={handleSubmit} />
              </div>
            </FormGroup>
          </form>
        </FormContainer>
        <div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      </Container>
    </>
  );
};

export default Form6;
