import React from "react";
import { useNavigate } from "react-router-dom";
import {
  MainContent,
  CardContainer,
  Card,
  CardImage,
  ContentTextCard,
  CardText,
  TextAcess,
  WelcomeText,
} from "./styles";
import image15 from "../../../Images/image15.png";
import image16 from "../../../Images/image16.jpeg";
import image27 from "../../../Images/image27.jpg";
import image31 from "../../../Images/image31.png";

import HeaderQuestionarios from "../../../Components/headerQuestionarios";

const images = [image15, image16, image27, image31];
const cardTitles = ["CIVIL", "MILITAR", "ESCOLAR", "SEFAZ"];

const Form15 = () => {
  const navigate = useNavigate();

  const handleCardClick = (cardNumber) => {
    if (cardNumber === 1) {
      navigate(`/plataforma/Sociodemografico-Civil`);
    } else if (cardNumber === 2) {
      navigate(`/plataforma/Sociodemografico-Militar`);
    } else if (cardNumber === 3) {
      navigate(`/plataforma/Sociodemografico-Escolar`);
    } else if (cardNumber === 4) {
      navigate(`/plataforma/Sociodemografico-Sefaz`);
    }
  };

  const renderCards = () => {
    return images.map((image, index) => (
      <Card key={index} onClick={() => handleCardClick(index + 1)}>
        <ContentTextCard>
          <CardImage src={image} alt={`Card ${index + 1}`} />
          <CardText>{cardTitles[index]}</CardText>
          <TextAcess>Acesse aqui</TextAcess>
        </ContentTextCard>
      </Card>
    ));
  };

  return (
    <>
      <HeaderQuestionarios />
      <MainContent>
        <WelcomeText>Questionário Sociodemográfico</WelcomeText>
        <CardContainer>{renderCards()}</CardContainer>
      </MainContent>
    </>
  );
};

export default Form15;
