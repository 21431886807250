import React from "react";
import { useNavigate } from "react-router-dom";
import {
  PageWrapper,
  MainContent,
  CardContainer,
  Card,
  CardImage,
  ContentTextCard,
  CardText,
  TextAcess,
  WelcomeText,
} from "./styles";
import image31 from "../../../Images/image31.png";
import image38 from "../../../Images/image38.png";
import image39 from "../../../Images/image39.png";
import image37 from "../../../Images/image37.png";

import HeaderQuestionarios from "../../../Components/headerQuestionarios";

const images = [image37, image38, image39, image31];
const cardTitles = ["CIVIL", "MILITAR", "ESCOLAR", "SEFAZ"];

const Form9 = () => {
  const navigate = useNavigate();

  const handleCardClick = (cardNumber) => {
    if (cardNumber === 1) {
      navigate(`/plataforma/Resultado-Civil`);
    } else if (cardNumber === 2) {
      navigate(`/plataforma/Escolher-Resultado-Militar`);
    } else if (cardNumber === 3) {
      navigate(`/plataforma/Resultado-Escolar`);
    } else if (cardNumber === 4) {
      navigate(`/plataforma/Resultado-Sefaz`);
    }
  };

  const renderCards = () => {
    return images.map((image, index) => (
      <Card key={index} onClick={() => handleCardClick(index + 1)}>
        <ContentTextCard>
          <CardImage src={image} alt={`Card ${index + 1}`} />
          <CardText>{cardTitles[index]}</CardText>
          <TextAcess>Acesse aqui</TextAcess>
        </ContentTextCard>
      </Card>
    ));
  };

  return (
    <PageWrapper>
      <HeaderQuestionarios />
      <MainContent>
        <WelcomeText>Resultados</WelcomeText>
        <CardContainer>{renderCards()}</CardContainer>
      </MainContent>
    </PageWrapper>
  );
};

export default Form9;
