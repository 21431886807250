import React from "react";
import styled from "styled-components";

interface FormRadioProps {
  name: string;
  value: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
}

const FormRadioInput = styled.input`
  margin-right: 5px;
`;

const FormRadioLabel = styled.label`
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const FormRadio: React.FC<FormRadioProps> = ({
  name,
  value,
  checked,
  onChange,
  label,
}) => {
  return (
    <FormRadioLabel>
      <FormRadioInput
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      {label}
    </FormRadioLabel>
  );
};

export default FormRadio;
